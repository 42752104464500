import { useQuery, QueryFunction } from 'react-query';
import { Dump, getAllDumps } from '../../services/apiSLTP/materials/dumps';

type UseDumpsQueryOptions = {};

const getDumps: QueryFunction<Dump[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseDumpsQueryOptions];
    const {} = options;

    const response = await getAllDumps();
    return response.data;
};

export const useDumpsQuery = (options: UseDumpsQueryOptions) => {
    return useQuery(['dumps', options], getDumps);
};
