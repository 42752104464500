import { useQuery, QueryFunction } from 'react-query';
import { getDailyTrucksRidesByDate, DailyTruckRide } from '../../services/apiSLTP/dailyReports/dailyTrucksRides';

type UseDailyTrucksRidesQueryOptions = {
    date: Date;
};

const getDailyTrucksRides: QueryFunction<DailyTruckRide[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseDailyTrucksRidesQueryOptions];
    const { date } = options;

    const response = await getDailyTrucksRidesByDate(date);
    return response.data;
};

export const useDailyTrucksRidesQuery = (options: UseDailyTrucksRidesQueryOptions) => {
    return useQuery(['dailyTrucksRides', options], getDailyTrucksRides);
};
