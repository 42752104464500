export const storeItemToLocalStorage = (key: string, date: string) => {
    localStorage.setItem(key, date);
};

export const getItemFromLocalStorage = (key: string) => {
    return localStorage.getItem(key);
};

export const removeItemFromLocalStorage = (key: string) => {
    return localStorage.removeItem(key);
};
