import React from 'react';
import { Button } from '@mui/material';

interface CustomButtonProps {
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    color?: string;
    textColor?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, disabled = false, onClick = () => {}, color = 'white', textColor = 'black', type = 'button', className = '' }) => {
    return (
        <Button
            type={type}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            className={"pad-5px " + className}
            sx={{
                backgroundColor: color,
                '&:hover': {
                    backgroundColor: color,
                },
                color: textColor,
            }}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
