import React from 'react';

const OnLoadPage: React.FC = () => {
    return (
        <div className="bg-black h-100 w-100 fx-c pos-fi z-idx-1111 fx-justify-c fx-items-c pas-0-imp top-0 left-0">
            <div>
                <img className="logo" width={400} src={require('../assets/images/my-sltp-transp.png')} />
            </div>
        </div>
    );
};

export default OnLoadPage;
