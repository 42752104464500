import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateTruckTypeBody,
    TruckTypeResponse,
    updateTruckType,
    CreateTruckTypeBody,
    createTruckType,
} from '../../services/apiSLTP/materials/trucksTypes';

type UseUpdateTruckTypeMutationOptions = MutationOptions<TruckTypeResponse, Error, { truckTypeId: string; data: UpdateTruckTypeBody }>;

type UseCreateTruckTypeMutationOptions = MutationOptions<TruckTypeResponse, Error, CreateTruckTypeBody>;

export const useUpdateTruckTypeMutation = (options?: UseUpdateTruckTypeMutationOptions) => {
    const truckTypeMutation: MutationFunction<TruckTypeResponse, { truckTypeId: string; data: UpdateTruckTypeBody }> = async ({
        truckTypeId,
        data,
    }) => {
        try {
            const response: TruckTypeResponse = await updateTruckType(truckTypeId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truckType');
        }
    };

    return useMutation(truckTypeMutation, options);
};

export const useCreateTruckTypeMutation = (options?: UseCreateTruckTypeMutationOptions) => {
    const truckTypeMutation: MutationFunction<TruckTypeResponse, CreateTruckTypeBody> = async (data) => {
        try {
            const response: TruckTypeResponse = await createTruckType(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truckType');
        }
    };

    return useMutation(truckTypeMutation, options);
};
