import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLoginMutation } from '../hooks/mutations/useLoginMutation';
import { LoginResponse } from '../services/apiSLTP/auth';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/capitalize';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '../components/Icon';
import CustomButton from '../components/CustomButton';
import { useLocation } from 'react-router-dom';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { login, isAuthenticated, authIsLoading } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const from = window.location || '/';

    const { mutate, isSuccess, isError, isLoading, error, data } = useLoginMutation({
        onSuccess: (data: LoginResponse) => {
            login(username);
            window.location.replace('/');
        },
        onError: (error: Error) => {
            console.error('Login error', error.message);
        },
    });

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
        console.log(from);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        mutate({ username, password });
        setUsername('');
        setPassword('');
    };

    return (
        <div className="fx-c w-100vh bg-black h-100vh fx-justify-c fx-items-c left-0 top-0" style={{ position: 'absolute' }}>
            <div className="fx-c fx-items-c border-radius-5px" style={{ width: '80%' }}>
                <img style={{ maxWidth: 300, width: '100%' }} src={require('../assets/images/my-sltp-transp.png')} />
                <form onSubmit={handleSubmit} style={{ paddingTop: 50 }} className="w-90 max-w-400px">
                    <div>
                        <TextField
                            required
                            fullWidth
                            id="username"
                            label={capitalizeFirstLetter(t('userlogin'))}
                            name="username"
                            autoFocus
                            value={username}
                            onChange={handleUsernameChange}
                            autoComplete="off"
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '15px',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },

                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                        borderTopLeftRadius: '15px',
                                        borderTopRightRadius: '15px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                        borderWidth: '2px',
                                    },
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                    <div className="fx-r fx-items-c fx-justify-sb" style={{ height: 5 }}>
                        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', height: 5, width: '100%' }} className="fx-r fx-justify-fe">
                            <div style={{ backgroundColor: 'black', borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: '90%' }} />
                        </div>
                        <div className="bg-black pad-5px" style={{ borderRadius: 40, zIndex: 10, marginRight: -30 }}>
                            <div
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    borderRadius: 30,
                                    padding: 15,
                                }}
                            >
                                <Icon type="submit" name="login" dark />
                            </div>
                        </div>
                    </div>
                    <div className=" ">
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label={capitalizeFirstLetter(t('password'))}
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ paddingRight: 30 }}>
                                        {/* <Icon name={showPassword ? 'visibility' : 'visibilityOff'} dark onClick={handleClickShowPassword} />*/}
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                    borderColor: 'rgba(255, 255, 255, 0.1)',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    borderColor: 'rgba(255, 255, 255, 0.1)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent', // Définir une bordure initiale
                                        borderBottomLeftRadius: '15px',
                                        borderBottomRightRadius: '15px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white', // Bordure blanche au survol
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white', // Bordure blanche au focus
                                        borderWidth: '2px', // Épaissir la bordure au focus
                                    },
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                    {/*<div className="w-100 fx-r fx-justify-c" style={{ paddingTop: 50 }}>
                        <CustomButton type="submit">{t('login')}</CustomButton>
                    </div>*/}
                </form>
            </div>
        </div>
    );
};

export default Login;
