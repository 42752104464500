import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateTruckCompanyBody,
    TruckCompanyResponse,
    updateTruckCompany,
    CreateTruckCompanyBody,
    createTruckCompany,
} from '../../services/apiSLTP/materials/trucksCompanies';

type UseUpdateTruckCompanyMutationOptions = MutationOptions<TruckCompanyResponse, Error, { truckCompanyId: string; data: UpdateTruckCompanyBody }>;

type UseCreateTruckCompanyMutationOptions = MutationOptions<TruckCompanyResponse, Error, CreateTruckCompanyBody>;

export const useUpdateTruckCompanyMutation = (options?: UseUpdateTruckCompanyMutationOptions) => {
    const truckCompanyMutation: MutationFunction<TruckCompanyResponse, { truckCompanyId: string; data: UpdateTruckCompanyBody }> = async ({
        truckCompanyId,
        data,
    }) => {
        try {
            const response: TruckCompanyResponse = await updateTruckCompany(truckCompanyId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truckCompany');
        }
    };

    return useMutation(truckCompanyMutation, options);
};

export const useCreateTruckCompanyMutation = (options?: UseCreateTruckCompanyMutationOptions) => {
    const truckCompanyMutation: MutationFunction<TruckCompanyResponse, CreateTruckCompanyBody> = async (data) => {
        try {
            const response: TruckCompanyResponse = await createTruckCompany(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truckCompany');
        }
    };

    return useMutation(truckCompanyMutation, options);
};
