import { useQuery, QueryFunction } from 'react-query';
import { getAllTrucksCompanies, TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';

type UseTrucksCompaniesQueryOptions = {};

const getTrucksCompanies: QueryFunction<TruckCompany[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTrucksCompaniesQueryOptions];
    const {} = options;

    const response = await getAllTrucksCompanies();
    return response.data;
};

export const useTrucksCompaniesQuery = (options: UseTrucksCompaniesQueryOptions) => {
    return useQuery(['trucksCompanies', options], getTrucksCompanies);
};
