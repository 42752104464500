import { useQuery, QueryFunction } from 'react-query';
import { getAllJobs, Job } from '../../services/apiSLTP/jobs';

type UseJobsQueryOptions = {};

const getJobs: QueryFunction<Job[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseJobsQueryOptions];
    const {} = options;

    const response = await getAllJobs();
    return response.data;
};

export const useJobsQuery = (options: UseJobsQueryOptions) => {
    return useQuery(['jobs', options], getJobs);
};
