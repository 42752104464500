import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface ToolType {
    designation: string;
    _id: string;
}

interface ToolsTypesResponse {
    message: string;
    data: ToolType[];
}

export const getAllToolsTypes = async (): Promise<ToolsTypesResponse> => {
    try {
        const response: AxiosResponse<ToolsTypesResponse> = await apiClient.get('/tool-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
