import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyMachine, DailyTool } from '../../services/apiSLTP/planning';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';
import { getMaterialStateColor } from '../../utils/colors';
import StateCell from '../cells/StateCell';

interface ToolsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const ToolsTable: React.FC<ToolsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByTool = () => {
        return activities
            .map((activity) => activity.dailyTools)
            .flat(1)
            .sort((a, b) => {
                if (a.toolId.designation > b.toolId.designation) return 1;
                if (a.toolId.designation < b.toolId.designation) return -1;
                if (a.state < b.state) return 1;
                if (a.state > b.state) return -1;
                return 0;
            });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('state'))}</div>
                            </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByTool().map((dailyTool) => (
                        <tr key={dailyTool._id} className="row">
                            <td className="">{dailyTool.toolId.designation}</td>
                            <td className="row-cell-codeproc">{dailyTool.toolId.codeProC}</td>
                            <td className="row-cell-state">
                                <StateCell
                                    stateColor={getMaterialStateColor(dailyTool.state)}
                                    text={capitalizeFirstLetter(t(getMaterialStateInfos(dailyTool.state)))}
                                />
                            </td>
                            <td className="">
                                {activities.find((activity) => activity.dailyTools.map((tool) => tool._id).includes(dailyTool._id))?.siteId.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ToolsTable;
