import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateDumpBody,
    DumpResponse,
    updateDump,
    CreateDumpBody,
    createDump,
} from '../../services/apiSLTP/materials/dumps';

type UseUpdateDumpMutationOptions = MutationOptions<DumpResponse, Error, { dumpId: string; data: UpdateDumpBody }>;
type UseCreateDumpMutationOptions = MutationOptions<DumpResponse, Error, CreateDumpBody>;

export const useUpdateDumpMutation = (options?: UseUpdateDumpMutationOptions) => {
    const dumpMutation: MutationFunction<DumpResponse, { dumpId: string; data: UpdateDumpBody }> = async ({
        dumpId,
        data,
    }) => {
        try {
            const response: DumpResponse = await updateDump(dumpId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during dump');
        }
    };

    return useMutation(dumpMutation, options);
};

export const useCreateDumpMutation = (options?: UseCreateDumpMutationOptions) => {
    const dumpMutation: MutationFunction<DumpResponse, CreateDumpBody> = async (data) => {
        try {
            const response: DumpResponse = await createDump(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during dump');
        }
    };

    return useMutation(dumpMutation, options);
};
