import { useQuery, QueryFunction } from 'react-query';
import { getAllTrucks, Truck } from '../../services/apiSLTP/materials/trucks';

type UseTrucksQueryOptions = {};

const getTrucks: QueryFunction<Truck[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTrucksQueryOptions];
    const {} = options;

    const response = await getAllTrucks();
    return response.data;
};

export const useTrucksQuery = (options: UseTrucksQueryOptions) => {
    return useQuery(['trucks', options], getTrucks);
};
