import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateTruckTypeMutation, useUpdateTruckTypeMutation } from '../../hooks/mutations/useTruckTypeMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    truckType: TruckType;
    trucksTypes: TruckType[];
    isEditMode?: boolean;
    setTrucksTypes: React.Dispatch<React.SetStateAction<TruckType[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTruckTypeModal: React.FC<ModalProps> = ({
    truckType,
    isEditMode = true,
    setTrucksTypes,
    showEditModal,
    setShowEditModal,
    trucksTypes,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTruckType, setTmpTruckType] = useState(truckType);

    const { user } = useAuth();

    const {
        mutate: mutateTruckType,
        isSuccess: isSuccessTruckType,
        isError: isErrorTruckType,
        isLoading: isLoadingTruckType,
        error: errorTruckType,
        data: dataTruckType,
    } = useUpdateTruckTypeMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckType:', error.message);
        },
    });

    const {
        mutate: addTruckType,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTruckTypeMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckType:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTruckType((prevTruckType) => {
            const newTruckType = { ...prevTruckType };
            newTruckType.designation = text;
            return newTruckType;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTruckType((prevTruckType) => {
                const newTruckType = { ...prevTruckType };
                newTruckType.crudStatus = crudStatus.OK;
                return newTruckType;
            });
        } else {
            setTmpTruckType((prevTruckType) => {
                const newTruckType = { ...prevTruckType };
                newTruckType.crudStatus = crudStatus.INACTIVE;
                return newTruckType;
            });
        }
    };

    return (
        <ModalBody
            title={t('truckType')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpTruckType.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateTruckType({
                          truckTypeId: truckType._id,
                          data: {
                              designation: tmpTruckType.designation,
                              crudStatus: tmpTruckType.crudStatus,
                          },
                      })
                    : addTruckType({ designation: tmpTruckType.designation, crudStatus: tmpTruckType.crudStatus });
            }}
        >
            <CustomTextInput value={tmpTruckType.designation} label={t('name')} onChange={handleDesignationChange} errorText={t('error')} required />
            <ToggleLine checked={tmpTruckType.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateTruckTypeModal;
