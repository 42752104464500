import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { TempWorker } from '../../services/apiSLTP/materials/tempWorkers';
import { crudStatus } from '../../utils/enums';
import { Job } from '../../services/apiSLTP/jobs';
import { Profile } from '../../services/apiSLTP/profiles';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateTempWorkerMutation, useUpdateTempWorkerMutation } from '../../hooks/mutations/useTempWorkerMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    tempWorker: TempWorker;
    tempWorkers: TempWorker[];
    isEditMode?: boolean;
    setTempWorkers: React.Dispatch<React.SetStateAction<TempWorker[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTempWorkerModal: React.FC<ModalProps> = ({
    tempWorker,
    isEditMode = true,
    setTempWorkers,
    showEditModal,
    setShowEditModal,
    tempWorkers,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTempWorker, setTmpTempWorker] = useState(tempWorker);

    const { user } = useAuth();

    const {
        mutate: mutateTempWorker,
        isSuccess: isSuccessTempWorker,
        isError: isErrorTempWorker,
        isLoading: isLoadingTempWorker,
        error: errorTempWorker,
        data: dataTempWorker,
    } = useUpdateTempWorkerMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tempWorker:', error.message);
        },
    });

    const {
        mutate: addTempWorker,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTempWorkerMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tempWorker:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTempWorker((prevTempWorker) => {
            const newTempWorker = { ...prevTempWorker };
            newTempWorker.designation = text;
            return newTempWorker;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTempWorker((prevTempWorker) => {
                const newTempWorker = { ...prevTempWorker };
                newTempWorker.crudStatus = crudStatus.OK;
                return newTempWorker;
            });
        } else {
            setTmpTempWorker((prevTempWorker) => {
                const newTempWorker = { ...prevTempWorker };
                newTempWorker.crudStatus = crudStatus.INACTIVE;
                return newTempWorker;
            });
        }
    };

    return (
        <ModalBody
            title={t('tempWorker')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpTempWorker.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateTempWorker({
                          tempWorkerId: tempWorker._id,
                          data: {
                              designation: tmpTempWorker.designation,
                              crudStatus: tmpTempWorker.crudStatus,
                          },
                      })
                    : addTempWorker({ designation: tmpTempWorker.designation, crudStatus: tmpTempWorker.crudStatus });
            }}
        >
            <CustomTextInput
                value={tmpTempWorker.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
                required
            />
            <ToggleLine checked={tmpTempWorker.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateTempWorkerModal;
