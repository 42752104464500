import React from 'react';
import { Tabs, Tab } from '@mui/material';

type CustomTabsProps = {
    selectedTab: number;
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
    tabLabels: string[];
};

const CustomTabs: React.FC<CustomTabsProps> = ({ selectedTab, handleTabChange, tabLabels }) => {
    return (
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="planning page tabs"
            sx={{
                minHeight: '25px',
                height: '25px',
                marginLeft: '15px',
                marginTop: '5px',
                '.MuiTab-root': {
                    height: '25px',
                    minHeight: '25px',
                    transition: 'background-color 1s ease, color 1s ease',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                },
                '.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white !important',
                    transition: 'background-color 1s ease, color 1s ease',
                },
                '.MuiTabs-indicator': {
                    display: 'none',
                },
            }}
        >
            {tabLabels.map((tab, index) => (
                <Tab label={tab} key={index} />
            ))}
        </Tabs>
    );
};

export default CustomTabs;
