import { useQuery, QueryFunction } from 'react-query';
import { getAllProfiles, Profile } from '../../services/apiSLTP/profiles';

type UseProfilesQueryOptions = {};

const getProfiles: QueryFunction<Profile[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseProfilesQueryOptions];
    const {} = options;

    const response = await getAllProfiles();
    return response.data;
};

export const useProfilesQuery = (options: UseProfilesQueryOptions) => {
    return useQuery(['profiles', options], getProfiles);
};
