import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateToolBody, ToolResponse, updateTool, CreateToolBody, createTool } from '../../services/apiSLTP/materials/tools';

type UseUpdateToolMutationOptions = MutationOptions<ToolResponse, Error, { toolId: string; data: UpdateToolBody }>;

type UseCreateToolMutationOptions = MutationOptions<ToolResponse, Error, CreateToolBody>;

export const useUpdateToolMutation = (options?: UseUpdateToolMutationOptions) => {
    const toolMutation: MutationFunction<ToolResponse, { toolId: string; data: UpdateToolBody }> = async ({ toolId, data }) => {
        try {
            const response: ToolResponse = await updateTool(toolId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during tool');
        }
    };

    return useMutation(toolMutation, options);
};

export const useCreateToolMutation = (options?: UseCreateToolMutationOptions) => {
    const toolMutation: MutationFunction<ToolResponse, CreateToolBody> = async (data) => {
        try {
            const response: ToolResponse = await createTool(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during tool');
        }
    };

    return useMutation(toolMutation, options);
};
