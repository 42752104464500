import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface Entity {
    _id: string;
    designation: string;
    crudStatus: number;
}

interface EntitiesResponse {
    message: string;
    data: Entity[];
}

export const getAllEntities = async (): Promise<EntitiesResponse> => {
    try {
        const response: AxiosResponse<EntitiesResponse> = await apiClient.get('/entities');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateEntityBody {
    designation?: string;
    crudStatus?: number;
}

export interface EntityResponse {
    message: string;
    data: Entity;
}

export const updateEntity = async (loadNatureId: string, data: UpdateEntityBody): Promise<EntityResponse> => {
    try {
        const response: AxiosResponse<EntityResponse> = await apiClient.patch('/entities/' + loadNatureId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateEntityBody {
    designation: string;
    crudStatus?: number;
}

export const createEntity = async (data: CreateEntityBody): Promise<EntityResponse> => {
    try {
        const response: AxiosResponse<EntityResponse> = await apiClient.post('/entities', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

