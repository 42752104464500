import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiClient, endpoints } from './endpoints';
import { User } from './users';

export interface LoginResponse {
    message: string;
    data: {
        userId: string;
        username: string;
        lastName: string;
        firstName: string;
    };
}

export interface ProtectedInfos {
    message: string;
    data: User;
}

export const login = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response: AxiosResponse<LoginResponse> = await apiClient.post(endpoints.login, {
            username,
            password,
            clientType: 'web',
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Refresh connexion
export const refreshAccessToken = async (): Promise<void> => {
    try {
        const response: AxiosResponse<{
            newAccessToken: string;
            newRefreshToken: string;
        }> = await apiClient.post(endpoints.resfreshAccessToken, {
            clientType: 'web',
        });
    } catch (error) {
        console.log(error);
    }
};

apiClient.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if (error.response?.status === 403) {
            try {
                await refreshAccessToken();
                const config = error.config;
                if (config) {
                    return axios(config);
                }
            } catch (refreshError) {
                console.error('Failed to refresh access token:', refreshError);
                window.location.replace('/login');
            }
        } else if (error.response?.status === 401 && window.location.pathname !== '/login') {
            try {
                window.location.replace('/login');
            } catch (refreshError) {
                console.error('Failed to refresh access token:', refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const protectedInfos = async (): Promise<ProtectedInfos> => {
    try {
        const response: AxiosResponse<ProtectedInfos> = await apiClient.get(endpoints.protected);
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error('An unexpected error occurred');
    }
};

export const logout = async (): Promise<LoginResponse> => {
    try {
        const response: AxiosResponse<LoginResponse> = await apiClient.post(endpoints.logout, {
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};