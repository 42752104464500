import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyRental } from '../../services/apiSLTP/planning';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';
import { getMaterialStateColor } from '../../utils/colors';
import StateCell from '../cells/StateCell';

interface RentalsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const RentalsTable: React.FC<RentalsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByRental = () => {
        return activities
            .map((activity) => activity.dailyRentals)
            .flat(1)
            .sort((a, b) => {
                if (a.designation > b.designation) return 1;
                if (a.designation < b.designation) return -1;
                if (a.state < b.state) return 1;
                if (a.state > b.state) return -1;
                return 0;
            });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('state'))}</div>
                            </th>
                        <th scope="col" className="w-200px ">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByRental().map((dailyRental) => (
                        <tr key={dailyRental._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{dailyRental.designation}</td>
                            <td className="row-cell-codeproc">{dailyRental.rentalTypeId.designation}</td>

                            <td className="row-cell-state">
                            <StateCell stateColor={getMaterialStateColor(dailyRental.state)} text={capitalizeFirstLetter(t(getMaterialStateInfos(dailyRental.state)))} />
                            </td>
                            <td className="">
                                {
                                    activities.find((activity) => activity.dailyRentals.map((rental) => rental._id).includes(dailyRental._id))?.siteId
                                        .name
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RentalsTable;
