import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateDailyTransferBody, DailyTransferResponse, updateDailyTransfer, CreateDailyTransferBody, createDailyTransfer } from '../../services/apiSLTP/dailyReports/dailyTransfers';

type UseUpdateDailyTransferMutationOptions = MutationOptions<DailyTransferResponse, Error, { DailyTransferId: string; data: UpdateDailyTransferBody }>;

export const useUpdateDailyTransferMutation = (options?: UseUpdateDailyTransferMutationOptions) => {
    const DailyTransferMutation: MutationFunction<DailyTransferResponse, { DailyTransferId: string; data: UpdateDailyTransferBody }> = async ({ DailyTransferId, data }) => {
        try {
            const response: DailyTransferResponse = await updateDailyTransfer(DailyTransferId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTransfer');
        }
    };

    return useMutation(DailyTransferMutation, options);
};

type UseCreateDailyTransferMutationOptions = MutationOptions<DailyTransferResponse, Error, CreateDailyTransferBody>;

export const useCreateDailyTransferMutation = (options?: UseCreateDailyTransferMutationOptions) => {
    const DailyTransferMutation: MutationFunction<DailyTransferResponse, CreateDailyTransferBody> = async (data) => {
        try {
            const response: DailyTransferResponse = await createDailyTransfer(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTransfer');
        }
    };

    return useMutation(DailyTransferMutation, options);
};
