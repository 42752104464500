import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import { updateActivity } from '../../services/apiSLTP/planning';

interface ObservationsCellProps {
    activity: DataItem;
    activityIndex: number;
    disabled?: boolean;
    refetch: () => void;
}

const ObservationsCell: React.FC<ObservationsCellProps> = React.memo(({ activity, activityIndex, disabled = false, refetch }) => {
    const [textContent, setTextContent] = useState(activity.observationsPlanning);

    const handleObervationsChange = async (text: string) => {
        const response = await updateActivity(activity._id, { observationsPlanning: text });
        refetch();
    };

    return (
        <textarea
            value={textContent}
            onChange={(event) => setTextContent(event.target.value)}
            style={{
                resize: 'none',
                width: '100%',
                border: 'none',
                backgroundColor: 'transparent',
                height: '100%',
                fontFamily: ['SF Pro Text', 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            }}
            onBlur={(event) => {
                handleObervationsChange(event.target.value);
            }}
            disabled={disabled}
        />
    );
});

export default ObservationsCell;
