import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateSubContractorBody,
    SubContractorResponse,
    updateSubContractor,
    CreateSubContractorBody,
    createSubContractor,
} from '../../services/apiSLTP/materials/subContractors';

type UseUpdateSubContractorMutationOptions = MutationOptions<
    SubContractorResponse,
    Error,
    { subContractorId: string; data: UpdateSubContractorBody }
>;
type UseCreateSubContractorMutationOptions = MutationOptions<SubContractorResponse, Error, CreateSubContractorBody>;

export const useUpdateSubContractorMutation = (options?: UseUpdateSubContractorMutationOptions) => {
    const subContractorMutation: MutationFunction<SubContractorResponse, { subContractorId: string; data: UpdateSubContractorBody }> = async ({
        subContractorId,
        data,
    }) => {
        try {
            const response: SubContractorResponse = await updateSubContractor(subContractorId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during subContractor');
        }
    };

    return useMutation(subContractorMutation, options);
};

export const useCreateSubContractorMutation = (options?: UseCreateSubContractorMutationOptions) => {
    const subContractorMutation: MutationFunction<SubContractorResponse, CreateSubContractorBody> = async (data) => {
        try {
            const response: SubContractorResponse = await createSubContractor(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during subContractor');
        }
    };

    return useMutation(subContractorMutation, options);
};
