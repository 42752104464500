import React, { useState } from 'react';
import DropDown from '../DropDown';
import { DataItem } from '../../services/apiSLTP/activities';
import { createReportIfEmpty, updateActivity, UserItem } from '../../services/apiSLTP/planning';
import { reportState } from '../../utils/enums';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface PersonCellProps {
    activity: DataItem;
    activityIndex: number;
    users: UserItem[];
    disabled?: boolean;
    activities: DataItem[];
    refetch: () => void;
}

const PersonCell: React.FC<PersonCellProps> = React.memo(({ activity, activityIndex, users, disabled = false, activities, refetch }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [usersFiltered, setUsersFiltered] = useState(true);

    const getReport = async (user: { label: string; value: string }) => {
        const response = await createReportIfEmpty(user.value, activity.startTime, reportState.PLANNING);
        return response.data;
    };

    const isDuplicatePerson = (userId: string): boolean => {
        let duplicateCount = 0;
        for (const activity of activities) {
            if (activity.reportId.userId._id === userId && !activity.reportId.userId.isSpecial) {
                duplicateCount++;
                if (duplicateCount > 1) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleUserChange = async (user: { label: string; value: string }) => {
        setIsLoading(true);
        const report = await getReport(user);
        const response = await updateActivity(activity._id, { reportId: report._id });
        refetch();
        setIsLoading(false);
    };

    const selectedUser = () => {
        return {
            value: activity.reportId.userId._id,
            label: capitalizeFirstLetter(activity.reportId.userId.firstName) + ' ' + activity.reportId.userId.lastName.toUpperCase(),
        };
    };

    const filterUsers = () => {
        return usersFiltered ? users.filter((user) => user.profileId.permsLevel === activity.reportId.userId.profileId.permsLevel) : users;
    };

    return (
        <div
            className="person-cell"
            title={selectedUser().label}
            onKeyDown={(event) => {
                if (event.ctrlKey) {
                    setUsersFiltered(!usersFiltered);
                }
            }}
        >
            <DropDown
                selectedItem={selectedUser()}
                items={filterUsers().map((user) => ({
                    label: capitalizeFirstLetter(user.firstName) + ' ' + user?.lastName.toUpperCase(),
                    value: user?._id,
                }))}
                inputLabel=""
                handleChange={(event, value) => handleUserChange({ value: value?.value || '', label: value?.label || '' })}
                disabled={disabled}
                selectClassName="h-25px"
                formClassName="pad-0-imp w-190px-imp"
                loading={isLoading}
                warning={isDuplicatePerson(activity.reportId.userId._id)}
                infos={activity.reportId.userId.isSpecial}
            />
        </div>
    );
});

export default PersonCell;
