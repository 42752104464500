import { useQuery, QueryFunction } from 'react-query';
import { getActivitiesByDateAndState, getActivitiesBetweenDateAndState, DataItem } from '../../services/apiSLTP/activities';

type UseActivitiesByDateAndStateQueryOptions = {
    date: string;
    minState: number;
    maxState: number;
    stepMode: number;
};

const getActivities: QueryFunction<DataItem[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActivitiesByDateAndStateQueryOptions];
    const { date, minState, maxState, stepMode } = options;

    const response = await getActivitiesByDateAndState(date, minState, maxState, stepMode);
    return response.data;
};

export const useActivitiesByDateQuery = (options: UseActivitiesByDateAndStateQueryOptions) => {
    return useQuery(['activitiesByDate', options], getActivities);
};

type UseActivitiesBetweenDateAndStateQueryOptions = {
    startDate: string;
    endDate: string;
    minState: number;
    maxState: number;
    stepMode: number;
    userId: string;
};

const getActivitiesBetweenDates: QueryFunction<DataItem[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActivitiesBetweenDateAndStateQueryOptions];
    const { startDate,  endDate, minState, maxState, stepMode, userId } = options;

    const response = await getActivitiesBetweenDateAndState(startDate, endDate, minState, maxState, stepMode, userId);
    return response.data;
};

export const useActivitiesByDatesQuery = (options: UseActivitiesBetweenDateAndStateQueryOptions) => {
    return useQuery(['activitiesByDates', options], getActivitiesBetweenDates, {enabled: !!options.userId.length});
};
