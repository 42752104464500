import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateUserBody, UserResponse, updateUser, CreateUserBody, createUser } from '../../services/apiSLTP/users';

type UseUpdateUserMutationOptions = MutationOptions<UserResponse, Error, { userId: string; data: UpdateUserBody }>;

type UseCreateUserMutationOptions = MutationOptions<UserResponse, Error, CreateUserBody>;

export const useUpdateUserMutation = (options?: UseUpdateUserMutationOptions) => {
    const userMutation: MutationFunction<UserResponse, { userId: string; data: UpdateUserBody }> = async ({ userId, data }) => {
        try {
            const response: UserResponse = await updateUser(userId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during user update');
        }
    };

    return useMutation(userMutation, options);
};

export const useCreateUserMutation = (options?: UseCreateUserMutationOptions) => {
    const userMutation: MutationFunction<UserResponse, CreateUserBody> = async (data) => {
        try {
            const response: UserResponse = await createUser(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during user creation');
        }
    };

    return useMutation(userMutation, options);
};
