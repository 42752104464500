import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateTruckBody, TruckResponse, updateTruck, CreateTruckBody, createTruck } from '../../services/apiSLTP/materials/trucks';

type UseUpdateTruckMutationOptions = MutationOptions<TruckResponse, Error, { truckId: string; data: UpdateTruckBody }>;
type UseCreateTruckMutationOptions = MutationOptions<TruckResponse, Error, CreateTruckBody>;

export const useUpdateTruckMutation = (options?: UseUpdateTruckMutationOptions) => {
    const truckMutation: MutationFunction<TruckResponse, { truckId: string; data: UpdateTruckBody }> = async ({ truckId, data }) => {
        try {
            const response: TruckResponse = await updateTruck(truckId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truck');
        }
    };

    return useMutation(truckMutation, options);
};

export const useCreateTruckMutation = (options?: UseCreateTruckMutationOptions) => {
    const truckMutation: MutationFunction<TruckResponse, CreateTruckBody> = async (data) => {
        try {
            const response: TruckResponse = await createTruck(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during truck');
        }
    };

    return useMutation(truckMutation, options);
};
