import { useQuery, QueryFunction } from 'react-query';
import { getAllToolsTypes, ToolType } from '../../services/apiSLTP/materials/toolsTypes';

type UseToolsTypesQueryOptions = {};

const getToolsTypes: QueryFunction<ToolType[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseToolsTypesQueryOptions];
    const {} = options;

    const response = await getAllToolsTypes();
    return response.data;
};

export const useToolsTypesQuery = (options: UseToolsTypesQueryOptions) => {
    return useQuery(['toolsTypes', options], getToolsTypes);
};
