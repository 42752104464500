import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../services/apiSLTP/users';
import { Job } from '../../services/apiSLTP/jobs';
import { Profile } from '../../services/apiSLTP/profiles';
import { getStatusColor, getTextColor } from '../../utils/colors';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import UpdateUserModal from '../modals/UpdateUserModal';
import DeleteModal from '../modals/DeleteModal';
import { useUpdateUserMutation } from '../../hooks/mutations/useUserMutation';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import ColorBar from '../ColorBar';
import StateBadge from '../StateBadge';
import Icon from '../Icon';
import { useAuth } from '../../hooks/useAuth';
import CustomToggle from '../CustomToggle';
import StateCell from '../cells/StateCell';

interface UsersSettingsRowProps {
    users: User[];
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    jobs: Job[];
    profiles: Profile[];
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    refetch: () => void;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const UsersSettingsRow: React.FC<UsersSettingsRowProps> = ({
    users,
    setUsers,
    jobs,
    isLoading = false,
    isError = false,
    isSuccess = false,
    profiles,
    refetch,
    universalFilter,
    setAddFtn,
}) => {
    const emptyUser: User = {
        _id: '',
        firstName: '',
        lastName: '',
        codeProC: '',
        address: '',
        username: '',
        crudStatus: crudStatus.OK,
        color: '#F5F5F5',
        jobId: { _id: '', designation: '', order: 0, crudStatus: crudStatus.OK },
        profileId: { _id: '', type: '', permsLevel: permissionLevels.TERRAIN },
        longitude: 0,
        latitude: 0,
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>(emptyUser);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user: currentUser } = useAuth();
    const {
        mutate: mutateUser,
        isSuccess: isSuccessUser,
        isError: isErrorUser,
        isLoading: isLoadingUser,
        error: errorUser,
        data: dataUser,
    } = useUpdateUserMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating user:', error.message);
        },
    });

    const sortedUsers = () => {
        return users.sort((a, b) => {
            const lastNameA = a?.lastName;
            const lastNameB = b?.lastName;
            if (lastNameA < lastNameB) return -1;
            if (lastNameA > lastNameB) return 1;
            const firstNameA = a?.firstName;
            const firstNameB = b?.firstName;
            if (firstNameA < firstNameB) return -1;
            if (firstNameA > firstNameB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedUser(emptyUser);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                {capitalizeFirstLetter(t(''))}
                            </th>
                            <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                            <th scope="col" className="w-300px">
                                {capitalizeFirstLetter(t('address'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('userlogin'))}
                            </th>
                            <th scope="col" className="w-200px">
                                {capitalizeFirstLetter(t('job'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('profile'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('special'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedUsers().map((user, index, array) => (
                            <tr key={user._id}>
                                <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                    <ColorBar backgroundColor={user?.color || 'white'} isPaddingTop={true} isPaddingBottom={true} title={''} />
                                </td>
                                <td scope="row" className="pos-sy left-30px z-idx-2">
                                    {user?.lastName} {user?.firstName}
                                </td>
                                <td>{user?.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedUser(user);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={user?.crudStatus === crudStatus.DELETED}
                                    />
                                    {currentUser && currentUser.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedUser(user);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={user?.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(user?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(user?.crudStatus)))}
                                    />
                                </td>
                                <td className="one-line" title={user?.address}>
                                    {user?.address}
                                </td>
                                <td className="one-line" title={user?.username}>
                                    {user?.username}
                                </td>
                                <td className="one-line" title={user?.jobId.designation}>
                                    {user?.jobId.designation}
                                </td>
                                <td className="one-line" title={user?.profileId.type}>
                                    {user?.profileId.type}
                                </td>
                                <td>
                                    <CustomToggle checked={user?.isSpecial || false} disabled />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateUserModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    user={selectedUser}
                    users={users}
                    setUsers={setUsers}
                    jobs={jobs}
                    profiles={profiles}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateUser({ userId: selectedUser?._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedUser?.codeProC}
                    title={t('user')}
                />
            )}
        </div>
    );
};

export default UsersSettingsRow;
