import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateTempWorkerBody,
    TempWorkerResponse,
    updateTempWorker,
    CreateTempWorkerBody,
    createTempWorker,
} from '../../services/apiSLTP/materials/tempWorkers';

type UseUpdateTempWorkerMutationOptions = MutationOptions<TempWorkerResponse, Error, { tempWorkerId: string; data: UpdateTempWorkerBody }>;
type UseCreateTempWorkerMutationOptions = MutationOptions<TempWorkerResponse, Error, CreateTempWorkerBody>;

export const useUpdateTempWorkerMutation = (options?: UseUpdateTempWorkerMutationOptions) => {
    const tempWorkerMutation: MutationFunction<TempWorkerResponse, { tempWorkerId: string; data: UpdateTempWorkerBody }> = async ({
        tempWorkerId,
        data,
    }) => {
        try {
            const response: TempWorkerResponse = await updateTempWorker(tempWorkerId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during tempWorker');
        }
    };

    return useMutation(tempWorkerMutation, options);
};

export const useCreateTempWorkerMutation = (options?: UseCreateTempWorkerMutationOptions) => {
    const tempWorkerMutation: MutationFunction<TempWorkerResponse, CreateTempWorkerBody> = async (data) => {
        try {
            const response: TempWorkerResponse = await createTempWorker(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during tempWorker');
        }
    };

    return useMutation(tempWorkerMutation, options);
};
