import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateTruckTypeMutation } from '../../hooks/mutations/useTruckTypeMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateTruckTypeModal from '../modals/UpdateTruckTypeModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import StateCell from '../cells/StateCell';

interface TrucksTypesSettingsRowProps {
    trucksTypes: TruckType[];
    setTrucksTypes: React.Dispatch<React.SetStateAction<TruckType[]>>;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    refetch: () => void;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const TrucksTypesSettingsRow: React.FC<TrucksTypesSettingsRowProps> = ({
    setTrucksTypes,
    trucksTypes,
    isLoading,
    isError,
    isSuccess,
    refetch,
    universalFilter,
    setAddFtn,
}) => {
    const emptyTruckType: TruckType = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTruckType, setSelectedTruckType] = useState<TruckType>(emptyTruckType);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        mutate: mutateTruckType,
        isSuccess: isSuccessTruckType,
        isError: isErrorTruckType,
        isLoading: isLoadingTruckType,
        error: errorTruckType,
        data: dataTruckType,
    } = useUpdateTruckTypeMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckType:', error.message);
        },
    });

    const filterTrucksTypes = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (truckType: TruckType) => {
            return searchTerm.length === 0 || matchesSearchTerm(truckType?.designation);
        };

        return trucksTypes.filter(matchesFilter);
    };

    const sortedTrucksTypes = () => {
        return trucksTypes.sort((a, b) => {
            const typeA = a?.designation;
            const typeB = b?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            const designationA = a.designation;
            const designationB = b.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedTruckType(emptyTruckType);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedTrucksTypes().map((truckType, index, array) => (
                            <tr key={truckType._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {truckType.designation}
                                </td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedTruckType(truckType);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={truckType.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedTruckType(truckType);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={truckType.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(truckType?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(truckType?.crudStatus)))}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateTruckTypeModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    truckType={selectedTruckType}
                    trucksTypes={trucksTypes}
                    setTrucksTypes={setTrucksTypes}
                    isEditMode={isEditMode}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateTruckType({ truckTypeId: selectedTruckType._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedTruckType?.designation}
                    title={t('truckType')}
                />
            )}
        </div>
    );
};

export default TrucksTypesSettingsRow;
