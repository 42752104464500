import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../locales/en.json';
import fr from '../locales/fr.json';

const resources = {
    en: { translation: en },
    fr: { translation: fr },
};

const initOptions: InitOptions = {
    resources,
    lng: navigator.language.split('-')[0],
    compatibilityJSON: 'v3',
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false,
    },
    keySeparator: false,
};

i18n.use(initReactI18next).init(initOptions);

export default i18n;
