import React from 'react';
import { useDrag } from 'react-dnd';
import { getMaterialStateColor } from '../utils/colors';
import Icon from './Icon';
import { materialState } from '../utils/enums';
import ErrorIcon from '@mui/icons-material/Error';

interface DraggableCellProps {
    dragIds: { lineId: string; itemId: string };
    index: number;
    onDragStart?: () => void;
    itemType: string;
    children: React.ReactNode;
    showDelete?: boolean;
    onDelete?: () => void;
    disabled?: boolean;
    state?: number;
    onDuplicate?: () => void;
    onDoubleClick?: () => void;
    warning?: boolean;
}

const DraggableCell: React.FC<DraggableCellProps> = ({
    children,
    dragIds,
    index,
    onDragStart = () => {},
    itemType,
    showDelete = true,
    onDelete = () => {},
    disabled = false,
    state = materialState.PRESENT,
    onDuplicate = () => {},
    onDoubleClick,
    warning = false,
}) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: itemType,
        item: { dragIds },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !disabled,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (disabled) return;
        if (event.ctrlKey && event.key === 'b') {
            onDuplicate();
        }
    };

    return (
        <div
            ref={dragRef}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            aria-disabled={disabled}
            className={`fx-r fx-justify-sb bg-lightgray border-radius-5px w-100 min-h-25px m-l-5 ${warning && 'outline-1px'} ${
                !disabled && 'hover-black focus-outline'
            }`}
            style={{
                marginTop: index === 0 ? 0 : 5,
                backgroundColor: getMaterialStateColor(state),
                opacity: isDragging ? 0.5 : 1,
                cursor: disabled ? 'not-allowed' : 'default',
                transform: 'translate3d(0, 0, 0)',
                outlineColor: warning ? 'darkorange' : undefined,
            }}
            onDragStart={
                disabled
                    ? undefined
                    : () => {
                          onDragStart();
                      }
            }
        >
            {warning && (
                <div
                    style={{
                        position: 'absolute',
                        width: 13,
                        height: 13,
                        top: -5,
                        left: -5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 10,
                        color: 'black',
                        fontSize: 10,
                        backgroundColor: 'darkorange',
                    }}
                >
                    !
                </div>
            )}
            <div className="draggable-children" onDoubleClick={disabled ? undefined : onDoubleClick}>
                {children}
            </div>
            <div className="min-w-10px">{showDelete && !disabled && <Icon name="close" onClick={onDelete} disabled={disabled} />}</div>
        </div>
    );
};

export default DraggableCell;
