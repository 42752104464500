import React from 'react';

interface ColorBarProps {
    backgroundColor: string;
    isPaddingTop: boolean;
    isPaddingBottom: boolean;
    title: string;
}

const ColorBar: React.FC<ColorBarProps> = React.memo(({ backgroundColor, isPaddingTop, isPaddingBottom, title }) => {
    const marginTop = isPaddingTop ? '5px' : '0';
    const marginBottom = isPaddingBottom ? '5px' : '0';

    const height = `calc(100% - ${parseInt(marginTop) + parseInt(marginBottom)}px)`;
    return (
        <div
            style={{
                marginTop,
                marginBottom,
                boxSizing: 'border-box',
            }}
            className="w-20px h-100 fx-justify-c fx-items-c m-l-3px m-r-3px"
        >
            <div
                style={{
                    backgroundColor,
                    height,
                    borderBottomLeftRadius: isPaddingBottom ? 5 : 0,
                    borderBottomRightRadius: isPaddingBottom ? 5 : 0,
                    borderTopLeftRadius: isPaddingTop ? 5 : 0,
                    borderTopRightRadius: isPaddingTop ? 5 : 0,
                }}
                className="w-20px"
                title={title}
            />
        </div>
    );
});

export default ColorBar;
