import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { DataItem } from '../../services/apiSLTP/activities';
import { permissionLevels, reportState, stepModes } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import { useActiveMachinesQuery, useMachinesQuery, useMachinesWithLastLocationsQuery } from '../../hooks/queries/useMachinesQuery';
import { useActiveToolsQuery, useToolsQuery, useToolsWithLastLocationsQuery } from '../../hooks/queries/useToolsQuery';
import { getMaterialStateColor } from '../../utils/colors';
import CreateDailyMachineModal from './ManageDailyMachineModal';
import { Machine, MachineWithLastLocation } from '../../services/apiSLTP/materials/machines';
import CreateDailyToolModal from './ManageDailyToolModal';
import { Tool, ToolWithLastLocation } from '../../services/apiSLTP/materials/tools';
import { useAuth } from '../../hooks/useAuth';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activities: DataItem[];
    initDate: string;
    refetch: () => void;
    currentDate: Date;
    siteId?: string;
}

const RemainingMaterialModal: React.FC<ModalProps> = ({ open, handleClose, activities, initDate, refetch, currentDate, siteId }) => {
    const [showCreateDailyMachineModal, setShowCreateDailyMachineModal] = useState(false);
    const [showCreateDailyToolModal, setShowCreateDailyToolModal] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
    const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
    const [selectedSite, setSelectedSite] = useState<{ name: string; id: string }>();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { data: dataMachines, isLoading: isMachinesLoading } = useActiveMachinesQuery({});
    const { data: dataTools, isLoading: isToolsLoading } = useActiveToolsQuery({});

    const { data: dataMachinesWithLocations } = useMachinesWithLastLocationsQuery({ date: currentDate });
    const { data: dataToolsWithLocations } = useToolsWithLastLocationsQuery({ date: currentDate });

    const handleRemainingMaterial = () => {
        handleClose();
    };

    const getRemainingMaterial = () => {
        const activitiesMachines = activities.map((activity) => activity.dailyMachines.map((machine) => machine.machineId._id)).flat();
        const activitiesTools = activities.map((activity) => activity.dailyTools.map((tool) => tool.toolId._id)).flat();
        const sortedMachines = dataMachines?.sort((a, b) => a.designation.localeCompare(b.designation));
        const sortedTools = dataTools?.sort((a, b) => a.designation.localeCompare(b.designation));
        return {
            machines: sortedMachines?.filter((machine) => !activitiesMachines.includes(machine._id)),
            tools: sortedTools?.filter((user) => !activitiesTools.includes(user._id)),
        };
    };

    return (
        <ModalBody
            closeText={t('cancel')}
            okText={t('ok')}
            title={t('remainingMaterial')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleRemainingMaterial();
            }}
            disabledOk={false}
            isOkVisible={false}
            width={400}
        >
            {!isMachinesLoading &&
                !isToolsLoading &&
                !getRemainingMaterial().machines?.filter(
                    (machine) => !siteId || (siteId && dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteId === siteId)
                ).length &&
                !getRemainingMaterial().tools?.filter(
                    (tool) => !siteId || (siteId && dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteId === siteId)
                ).length && <div>{capitalizeFirstLetter(t('noRemainingMaterial'))}</div>}
            <div className="fx-c max-h-500px" style={{ overflowY: 'scroll' }}>
                {isMachinesLoading ||
                    (!!getRemainingMaterial().machines?.filter(
                        (machine) => !siteId || (siteId && dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteId === siteId)
                    ).length && (
                        <>
                            <h3 className="m-0">{capitalizeFirstLetter(t('machines'))} :</h3>
                            <div className="loading-bar">{isMachinesLoading && <LinearProgress color="inherit" />}</div>
                        </>
                    ))}
                {!!getRemainingMaterial().machines?.filter(
                    (machine) => !siteId || (siteId && dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteId === siteId)
                ).length &&
                    getRemainingMaterial()
                        .machines?.filter(
                            (machine) =>
                                !siteId || (siteId && dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteId === siteId)
                        )
                        .map((machine) => (
                            <div key={machine._id} className="fx-c border border-lightgray border-radius-5px pad-3px m-t-5px">
                                <div className="fx-r fx-justify-sb fx-items-c">
                                    <div>{capitalizeFirstLetter(machine.designation)}</div>
                                    {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                        <div>
                                            <Icon
                                                name="addCircle"
                                                onClick={() => {
                                                    if (siteId)
                                                        setSelectedSite({
                                                            id: siteId,
                                                            name:
                                                                dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteName || '',
                                                        });
                                                    setSelectedMachine(machine);
                                                    setShowCreateDailyMachineModal(true);
                                                }}
                                                title={capitalizeFirstLetter(t('addMachine'))}
                                            />
                                        </div>
                                    )}
                                </div>
                                {dataMachinesWithLocations?.find((value) => value._id === machine._id) && (
                                    <div
                                        style={{
                                            backgroundColor: getMaterialStateColor(
                                                dataMachinesWithLocations?.find((value) => value._id === machine._id)?.latestState ?? -1
                                            ),
                                        }}
                                        className="pad-2px border-radius-5px"
                                    >
                                        {dataMachinesWithLocations?.find((value) => value._id === machine._id)?.siteName}
                                    </div>
                                )}
                            </div>
                        ))}
                {isToolsLoading ||
                    (!!getRemainingMaterial().tools?.filter(
                        (tool) => !siteId || (siteId && dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteId === siteId)
                    ).length && (
                        <>
                            <h3 className="m-0 m-t-10px">{capitalizeFirstLetter(t('tools'))} :</h3>
                            <div className="loading-bar">{isToolsLoading && <LinearProgress color="inherit" />}</div>
                        </>
                    ))}
                {!!getRemainingMaterial().tools?.filter(
                    (tool) => !siteId || (siteId && dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteId === siteId)
                ).length &&
                    getRemainingMaterial()
                        .tools?.filter(
                            (tool) => !siteId || (siteId && dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteId === siteId)
                        )
                        .map((tool) => (
                            <div key={tool._id} className="fx-c border border-lightgray border-radius-5px pad-3px m-t-5px">
                                <div className="fx-r fx-justify-sb fx-items-c">
                                    <div>{capitalizeFirstLetter(tool.designation)}</div>
                                    {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                        <div>
                                            <Icon
                                                name="addCircle"
                                                onClick={() => {
                                                    if (siteId)
                                                        setSelectedSite({
                                                            id: siteId,
                                                            name: dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteName || '',
                                                        });
                                                    setSelectedTool(tool);
                                                    setShowCreateDailyToolModal(true);
                                                }}
                                                title={capitalizeFirstLetter(t('addTool'))}
                                            />
                                        </div>
                                    )}
                                </div>
                                {dataToolsWithLocations?.find((value) => value._id === tool._id) && (
                                    <div
                                        style={{
                                            backgroundColor: getMaterialStateColor(
                                                dataToolsWithLocations?.find((value) => value._id === tool._id)?.latestState ?? -1
                                            ),
                                        }}
                                        className="pad-2px border-radius-5px"
                                    >
                                        {dataToolsWithLocations?.find((value) => value._id === tool._id)?.siteName}
                                    </div>
                                )}
                            </div>
                        ))}
            </div>
            {showCreateDailyMachineModal && (
                <CreateDailyMachineModal
                    open={showCreateDailyMachineModal}
                    activityId={''}
                    handleClose={() => setShowCreateDailyMachineModal(false)}
                    refetch={refetch}
                    dailyMachine={null}
                    activities={activities}
                    canChooseActivity
                    machine={selectedMachine}
                    site={selectedSite}
                />
            )}
            {showCreateDailyToolModal && (
                <CreateDailyToolModal
                    open={showCreateDailyToolModal}
                    activityId={''}
                    handleClose={() => setShowCreateDailyToolModal(false)}
                    refetch={refetch}
                    dailyTool={null}
                    activities={activities}
                    canChooseActivity
                    tool={selectedTool}
                    site={selectedSite}
                />
            )}
        </ModalBody>
    );
};

export default RemainingMaterialModal;
