import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateTruckCompanyMutation } from '../../hooks/mutations/useTruckCompanyMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateTruckCompanyModal from '../modals/UpdateTruckCompanyModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import StateCell from '../cells/StateCell';

interface TrucksCompaniesSettingsRowProps {
    trucksCompanies: TruckCompany[];
    setTrucksCompanies: React.Dispatch<React.SetStateAction<TruckCompany[]>>;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    refetch: () => void;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const TrucksCompaniesSettingsRow: React.FC<TrucksCompaniesSettingsRowProps> = ({
    setTrucksCompanies,
    trucksCompanies,
    isError,
    isLoading,
    isSuccess,
    refetch,
    universalFilter,
    setAddFtn,
}) => {
    const emptyTruckCompany: TruckCompany = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTruckCompany, setSelectedTruckCompany] = useState<TruckCompany>(emptyTruckCompany);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        mutate: mutateTruckCompany,
        isSuccess: isSuccessTruckCompany,
        isError: isErrorTruckCompany,
        isLoading: isLoadingTruckCompany,
        error: errorTruckCompany,
        data: dataTruckCompany,
    } = useUpdateTruckCompanyMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckCompany:', error.message);
        },
    });

    const filterTrucksCompanies = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (truckCompany: TruckCompany) => {
            return searchTerm.length === 0 || matchesSearchTerm(truckCompany?.designation);
        };

        return trucksCompanies.filter(matchesFilter);
    };

    const sortedTrucksCompanies = () => {
        return trucksCompanies.sort((a, b) => {
            const typeA = a?.designation;
            const typeB = b?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            const designationA = a.designation;
            const designationB = b.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedTruckCompany(emptyTruckCompany);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedTrucksCompanies().map((truckCompany, index, array) => (
                            <tr key={truckCompany._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {truckCompany.designation}
                                </td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedTruckCompany(truckCompany);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={truckCompany.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedTruckCompany(truckCompany);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={truckCompany.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(truckCompany?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(truckCompany?.crudStatus)))}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateTruckCompanyModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    truckCompany={selectedTruckCompany}
                    trucksCompanies={trucksCompanies}
                    setTrucksCompanies={setTrucksCompanies}
                    isEditMode={isEditMode}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateTruckCompany({ truckCompanyId: selectedTruckCompany._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedTruckCompany?.designation}
                    title={t('truckCompany')}
                />
            )}
        </div>
    );
};

export default TrucksCompaniesSettingsRow;
