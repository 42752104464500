import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiClient, endpoints } from '../endpoints';
import { User } from '../users';
import { ActivityTypeData, Site } from '../planning';

export interface ReportingReports {
    userId: string;
    user: User;
    nbReports: number;
    nbReportsSent: number;
    nbActivities: number;
    nbActivitiesSent: number;
    totalFuel: number;
    totalKm: number;
    totalAdBlue: number;
}

export interface ReportingsReportsResponse {
    message: string;
    data: ReportingReports[];
}

export interface ReportingQueryParams {
    startDate: string;
    endDate: string;
}

export const getReportingsReports = async (params: ReportingQueryParams): Promise<ReportingsReportsResponse> => {
    try {
        const response: AxiosResponse<ReportingsReportsResponse> = await apiClient.get(
            '/reporting/reports/' + params.startDate + '/' + params.endDate
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface ReportingReportsByUser {
    userId: string;
    date: string;
    fuel: number;
    km: number;
    adBlue: number;
    reportSent: boolean;
    nbActivities: number;
    nbActivitiesSent: number;
    nbTruckRides: number;
    activitiesTypes: ActivityTypeData[];
    sites: Site[];
}

export interface ReportingReportsByUserResponse {
    message: string;
    data: ReportingReportsByUser[];
}

export interface ReportingReportsByUserParams {
    startDate: string;
    endDate: string;
    userId: string;
}

export const getReportingsReportsByUser = async (params: ReportingReportsByUserParams): Promise<ReportingReportsByUserResponse> => {
    try {
        const response: AxiosResponse<ReportingReportsByUserResponse> = await apiClient.get(
            '/reporting/reports/user/' + params.userId + '/activities/' + params.startDate + '/' + params.endDate
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
