import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { LoadNature } from '../../services/apiSLTP/materials/loadNatures';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateLoadNatureMutation, useUpdateLoadNatureMutation } from '../../hooks/mutations/useLoadNatureMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    loadNature: LoadNature;
    loadNatures: LoadNature[];
    isEditMode?: boolean;
    setLoadNatures: React.Dispatch<React.SetStateAction<LoadNature[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateLoadNatureModal: React.FC<ModalProps> = ({
    loadNature,
    isEditMode = true,
    setLoadNatures,
    showEditModal,
    setShowEditModal,
    loadNatures,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpLoadNature, setTmpLoadNature] = useState(loadNature);

    const { user } = useAuth();

    const {
        mutate: mutateLoadNature,
        isSuccess: isSuccessLoadNature,
        isError: isErrorLoadNature,
        isLoading: isLoadingLoadNature,
        error: errorLoadNature,
        data: dataLoadNature,
    } = useUpdateLoadNatureMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating loadNature:', error.message);
        },
    });

    const {
        mutate: addLoadNature,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateLoadNatureMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating loadNature:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpLoadNature((prevLoadNature) => {
            const newLoadNature = { ...prevLoadNature };
            newLoadNature.designation = text;
            return newLoadNature;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpLoadNature((prevLoadNature) => {
                const newLoadNature = { ...prevLoadNature };
                newLoadNature.crudStatus = crudStatus.OK;
                return newLoadNature;
            });
        } else {
            setTmpLoadNature((prevLoadNature) => {
                const newLoadNature = { ...prevLoadNature };
                newLoadNature.crudStatus = crudStatus.INACTIVE;
                return newLoadNature;
            });
        }
    };

    return (
        <ModalBody
            title={t('loadNature')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpLoadNature.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateLoadNature({
                          loadNatureId: loadNature._id,
                          data: {
                              designation: tmpLoadNature.designation,
                              crudStatus: tmpLoadNature.crudStatus,
                          },
                      })
                    : addLoadNature({ designation: tmpLoadNature.designation, crudStatus: tmpLoadNature.crudStatus });
            }}
        >
            <CustomTextInput value={tmpLoadNature.designation} label={t('name')} onChange={handleDesignationChange} errorText={t('error')} required />
            <ToggleLine checked={tmpLoadNature.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateLoadNatureModal;
