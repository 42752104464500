import { useQuery, QueryFunction } from 'react-query';
import { getAllQuarries, Quarry } from '../../services/apiSLTP/sites/quarries';

type UseQuarriesQueryOptions = {};

const getQuarries: QueryFunction<Quarry[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseQuarriesQueryOptions];
    try {
        const response = await getAllQuarries();
        return response.data;
    } catch (error) {
        throw new Error('An unexpected error occurred while fetching quarries');
    }
};

export const useQuarriesQuery = (options: UseQuarriesQueryOptions) => {
    return useQuery(['quarries', options], getQuarries);
};

type UseActiveQuarriesQueryOptions = {};

const getActiveQuarries: QueryFunction<Quarry[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActiveQuarriesQueryOptions];
    try {
        const response = await getAllQuarries();
        return response.data;
    } catch (error) {
        throw new Error('An unexpected error occurred while fetching quarries');
    }
};

export const useActiveQuarriesQuery = (options: UseActiveQuarriesQueryOptions) => {
    return useQuery(['quarries', options], getActiveQuarries);
};
