// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="pos-fi bottom-0 w-100 z-idx-1111">
            <div className="fx-r fx-justify-c bg-black">
                <div className="sFont text-white  border-radius-5px" style={{ padding: 2 }}>
                    Copyright © 2024 SLTP. Tous droits réservés.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
