import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DataItem } from '../../services/apiSLTP/activities';
import datetimeDifference from 'datetime-difference';
import { formatDate } from '../../utils/dates';

interface WorkersTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const WorkersTable: React.FC<WorkersTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByPerson = () => {
        return activities.sort((a, b) => {
            const specialA = a.reportId.userId?.isSpecial || false;
            const specialB = b.reportId.userId?.isSpecial || false;
            if (specialA > specialB) return 1;
            if (specialA < specialB) return -1;
            if (a.reportId.userId.lastName > b.reportId.userId.lastName) return 1;
            if (a.reportId.userId.lastName < b.reportId.userId.lastName) return -1;
            if (a.reportId.userId.firstName > b.reportId.userId.firstName) return 1;
            if (a.reportId.userId.firstName < b.reportId.userId.firstName) return -1;
            return 0;
        });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-100px  pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('lastName'))}
                        </th>
                        <th scope="col" className="w-100px  pos-sy left-100 z-idx-4">
                            {capitalizeFirstLetter(t('firstName'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>

                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('start'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('end'))}
                        </th>
                        {/*<th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('calculatedTime'))}
                        </th>*/}
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByPerson().map((activity, index) => (
                        <tr key={activity._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{activity.reportId.userId.lastName}</td>
                            <td className="pos-sy left-100 z-idx-2">{capitalizeFirstLetter(activity.reportId.userId.firstName)}</td>
                            <td className="row-cell-codeproc">{activity.reportId.userId.codeProC}</td>
                            <td className="row-cell-starttime">
                                {new Date(activity.startTime).getHours().toString().padStart(2, '0')}:
                                {new Date(activity.startTime).getMinutes().toString().padStart(2, '0')}
                            </td>
                            <td className="row-cell-validationtime">{activity.validationTime && formatDate(new Date(activity.validationTime))}</td>
                            {/*<td className="row-cell-validationtime">
                                {datetimeDifference(new Date(activity.startTime), new Date(activity.validationTime)).days}
                                {t('days').charAt(0)} {datetimeDifference(new Date(activity.startTime), new Date(activity.validationTime)).hours}:
                                {datetimeDifference(new Date(activity.startTime), new Date(activity.validationTime)).minutes}
                            </td>*/}
                            <td className="">{activity.siteId.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WorkersTable;
