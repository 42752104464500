import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { useTranslation } from 'react-i18next';
import {
    cancelActivities,
    cancelActivity,
    DataItem,
    duplicateActivities,
    duplicateActivity,
    importActivitiesByDate,
} from '../services/apiSLTP/activities';
import {
    Site,
    UserItem,
    deleteActivity,
    getActivitiesTypes,
    getSites,
    getUsersBetweenPermsLevel,
    updateActivity,
} from '../services/apiSLTP/planning';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import ActivityCell from '../components/cells/ActivityCell';
import StartTimeCell from '../components/cells/StartTimeCell';
import ObservationsCell from '../components/cells/ObservationsCell';
import MachinesCell from '../components/cells/MachinesCell';
import RentalsCell from '../components/cells/RentalsCell';
import TempWorkersCell from '../components/cells/TempWorkersCell';
import SubContractorsCell from '../components/cells/SubContractorsCell';
import ToolsCell from '../components/cells/ToolsCell';
import CreateActivityModal from '../components/modals/CreateActivityModal';
import PersonCell from '../components/cells/PersonCell';
import CalendarModal from '../components/modals/CalendarModal';
import { formatDateToYYYYMMDD, getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isRollbackPossible } from '../utils/dates';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from '../hooks/useAuth';
import { getStateColor, getTextColor } from '../utils/colors';
import CustomToggle from '../components/CustomToggle';
import { updateReportState } from '../services/apiSLTP/dailyReports/reports';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import ColorBar from '../components/ColorBar';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import Header from '../components/Header';
import Icon from '../components/Icon';
import TrucksPlannedCell from '../components/cells/TrucksPlannedCell';
import RemainingPeopleModal from '../components/modals/RemainingPeopleModal';
import RemainingMaterialModal from '../components/modals/RemainingMaterialModal';
import PlanningTrucksPage from '../components/tables/PlanningTrucksPage';
import SiteCell from '../components/cells/SiteCell';
import PlanningMaterialPage from '../components/tables/PlanningMaterialTable';
import StateCell from '../components/cells/StateCell';
import CustomTabs from '../components/CustomTabs';

import AmplirollTableRows from '../components/tables/AmplirollsTableRows';
import { useMachinesWithLastLocationsQuery } from '../hooks/queries/useMachinesQuery';
import { useToolsWithLastLocationsQuery } from '../hooks/queries/useToolsQuery';

const PlanningPage: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [sites, setSites] = useState<Site[]>([]);
    const [usersByPermsLevel, setUsersByPermsLevel] = useState<UserItem[]>([]);
    const [activitiesTypes, setActivitiesTypes] = useState<{ label: string; value: string }[]>([]);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showPeopleModal, setShowPeopleModal] = useState(false);
    const [showMaterialModal, setShowMaterialModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedSiteId, setSelectedSiteId] = useState('');

    const { user } = useAuth();
    const { t } = useTranslation();

    const {
        data: activities,
        isLoading,
        isError,
        isSuccess,
        isRefetching,
        refetch,
    } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.NEEDS,
        maxState: reportState.REPORTFILLED,
        stepMode: stepModes.PLANNING,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});


    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.PLANNINGTAB, newValue.toString());
    };

    const fetchUsers = async () => {
        const response = await getUsersBetweenPermsLevel(permissionLevels.CHAUFFEUR, permissionLevels.ADMIN);
        setUsersByPermsLevel(response.data);
    };

    const fetchActivitiesTypes = async () => {
        const response = await getActivitiesTypes();
        setActivitiesTypes(
            response.data.map((type) => {
                return { label: type.designation, value: type?._id };
            })
        );
    };

    const fetchSites = async () => {
        const response = await getSites();
        setSites(response.data);
    };

    const handleDeleteActivity = async (activityId: string) => {
        const response = await deleteActivity(activityId);
        refetch();
    };

    const submitData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.PLANNING, reportState.REPORT);
        if (!activityId.length) {
            await duplicateActivities(currentDate.toISOString(), reportState.PLANNING, stepModes.PLANNING, stepModes.REPORT, reportState.REPORT);
        } else {
            await duplicateActivity(activityId, stepModes.REPORT, reportState.REPORT);
        }
        refetch();
    };

    const rollBackData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.REPORT, reportState.PLANNING);
        if (!activityId.length) {
            await cancelActivities(currentDate.toISOString(), reportState.REPORT, stepModes.REPORT, stepModes.PLANNING, reportState.PLANNING);
        } else {
            await cancelActivity(activityId, reportState.REPORT, stepModes.REPORT);
        }
        refetch();
    };

    const handleActivityTruckManager = async (id: string, isTruckManager: boolean, siteId: string) => {
        await updateActivity(id, { isTruckManager });
        const currentTruckManager = activities?.find((value) => value.siteId._id === siteId && value.isTruckManager);
        if (currentTruckManager) await updateActivity(currentTruckManager._id, { isTruckManager: false });
        refetch();
    };

    const checkEditableRow = (activity: DataItem) => {
        if (user && user?.profileId.permsLevel > permissionLevels.PLANNING) return false;
        if (activity.activityState <= reportState.PLANNINGSENT) return false;
        return true;
    };

    const handleExport = () => {
        const table = document.getElementById('csv-planning-obj') as HTMLTableElement;
        if (!table) return;

        const rows = Array.from(table.querySelectorAll('tr'));
        if (!rows) return;

        const csvData: string[] = [];

        rows.forEach((row) => {
            if (row.style.display === 'none') return;
            const cols = Array.from(row.querySelectorAll('th, td'))
                .map((col) => {
                    let textContent = col.textContent?.trim() || '';
                    let title = '';

                    const children = Array.from(col.querySelectorAll('*'));
                    children.forEach((child) => {
                        if (!textContent && child.textContent?.trim()) {
                            textContent = child.textContent.trim();
                        }
                        if (child.getAttribute('title')) {
                            title = child.getAttribute('title')?.trim() || '';
                        }
                    });

                    return (textContent !== '' ? textContent : title || '').replaceAll('\n', ' | ');
                })
                .join(';');
            csvData.push(cols);
        });

        const csvString = '\uFEFF' + csvData.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        const currentDate = new Date();
        a.download = `reports-${currentDate.toISOString().split('T')[0]}.csv`;
        a.click();

        URL.revokeObjectURL(url);
    };

    const setSpecificHour = () => {
        const date = new Date(currentDate);
        date.setHours(7, 0);
        return date.toISOString();
    };

    const sortedActivities = useMemo(() => {
        return (activities || []).sort((a, b) => {
            if (!(a.siteId?.conducId?._id && b.siteId?.conducId?._id)) {
                if (!a.siteId?.conducId?._id) return -1;
                if (!b.siteId?.conducId?._id) return 1;
            }
            if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
            if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;

            const siteA = a.siteId.name.toLowerCase();
            const siteB = b.siteId.name.toLowerCase();
            if (siteA < siteB) return -1;
            if (siteA > siteB) return 1;

            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
        });
    }, [activities]);

    useEffect(() => {
        fetchUsers();
        fetchActivitiesTypes();
        fetchSites();
    }, []);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    useEffect(() => {
        const tab = getItemFromLocalStorage(localStorageKeys.PLANNINGTAB);
        setSelectedTab(parseInt(tab || '0'));
    }, []);

    return (
        <div className="planning">
            <Header
                isSearchable
                title={t('planning')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    color="white"
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                <Icon
                    name="peopleAlt"
                    onClick={() => setShowPeopleModal(true)}
                    title={capitalizeFirstLetter(t('remainingUsers'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="precisionManufacturing"
                    onClick={() => setShowMaterialModal(true)}
                    title={capitalizeFirstLetter(t('remainingMaterial'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                    <>
                        <Icon
                            name="addCircle"
                            onClick={() => {
                                setSelectedSiteId('');
                                setShowActivityModal(true);
                            }}
                            title={capitalizeFirstLetter(t('add'))}
                            classes={{ iconButton: 'pad-10px-imp' }}
                            dark
                        />
                        <Icon
                            name="resetTv"
                            onClick={() => rollBackData('')}
                            title={capitalizeFirstLetter(t('takeBackReports'))}
                            classes={{ iconButton: 'pad-10px-imp' }}
                            dark
                        />
                        <Icon
                            name="verticalAlignTop"
                            onClick={() => submitData('')}
                            title={capitalizeFirstLetter(t('sendReports'))}
                            classes={{ iconButton: 'pad-10px-imp' }}
                            dark
                        />
                    </>
                )}
                <Icon name="output" onClick={handleExport} title={capitalizeFirstLetter(t('export'))} classes={{ iconButton: 'pad-10px-imp' }} dark />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <CustomTabs selectedTab={selectedTab} handleTabChange={handleTabChange} tabLabels={[t('all'), t('trucks'), t('material')]} />

                {selectedTab === 0 && (
                    <div className="table-container">
                        <table id="csv-planning-obj">
                            <thead>
                                <tr>
                                    <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                        {capitalizeFirstLetter(t(''))}
                                    </th>
                                    <th scope="col" className="w-200px  pos-sy left-30px z-idx-4">
                                        {capitalizeFirstLetter(t('site'))}
                                    </th>
                                    <th scope="col" className="w-60px ">
                                        {capitalizeFirstLetter(t('start'))}
                                    </th>
                                    <th scope="col" className="w-200px left-230px z-idx-4">
                                        {capitalizeFirstLetter(t('person'))}
                                    </th>
                                    <th scope="col" className="w-280px ">
                                        {capitalizeFirstLetter(t('machines'))}
                                    </th>
                                    <th scope="col" className="w-280px ">
                                        {capitalizeFirstLetter(t('tools'))}
                                    </th>
                                    <th scope="col" className="w-280px ">
                                        {capitalizeFirstLetter(t('rentals'))}
                                    </th>
                                    <th scope="col" className="w-280px">
                                        {capitalizeFirstLetter(t('8x4'))}
                                    </th>
                                    <th scope="col" className="w-280px">
                                        {capitalizeFirstLetter(t('Semie'))}
                                    </th>
                                    <th scope="col" className="w-280px ">
                                        {capitalizeFirstLetter(t('Ampliroll'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('observations'))}
                                    </th>
                                    <th scope="col" className="w-200px ">
                                        {capitalizeFirstLetter(t('tempWorkers'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('subContractors'))}
                                    </th>
                                    <th scope="col" className="w-100px">
                                        <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                    </th>
                                    <th scope="col" className="w-200px ">
                                        {capitalizeFirstLetter(t('activity'))}
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                                    </th>
                                    {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                        <th scope="col" className="w-100px ">
                                            {capitalizeFirstLetter(t('actions'))}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody id="fu-planning-obj">
                                {isSuccess && !activities?.length && (
                                    <tr className="row-empty">
                                        <td className="cell-empty" colSpan={19}>
                                            {capitalizeFirstLetter(t('noActivity'))}
                                        </td>
                                    </tr>
                                )}
                                {sortedActivities.map((activity, index, array) => (
                                    <React.Fragment key={`${activity._id}-${index}`}>
                                        {index > 0 && array[index - 1].siteId?._id !== activity.siteId?._id && (
                                            <tr id="fu-exclude">
                                                <td colSpan={20} style={{ backgroundColor: 'rgba(0,0,0,1)', height: 2, padding: 0 }} />
                                            </tr>
                                        )}
                                        <tr className="row" key={`row-${activity._id}`}>
                                            <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                                <ColorBar
                                                    backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                                    isPaddingTop={!(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)}
                                                    isPaddingBottom={
                                                        !(index < array.length - 1 && array[index + 1].siteId?._id === activity.siteId?._id)
                                                    }
                                                    title={
                                                        activity.siteId?.conducId?._id
                                                            ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                              ' ' +
                                                              activity.siteId?.conducId?.lastName.toUpperCase()
                                                            : ''
                                                    }
                                                />
                                            </td>
                                            <td className="pos-sy left-30px z-idx-2">
                                                <SiteCell
                                                    activities={array}
                                                    activity={activity}
                                                    activityIndex={index}
                                                    currentDate={currentDate}
                                                    refetch={refetch}
                                                    users={usersByPermsLevel}
                                                    activitiesTypes={activitiesTypes}
                                                    sites={sites}
                                                    disabled={!!user && user?.profileId.permsLevel > permissionLevels.PLANNING}
                                                    remainingMaterial
                                                />
                                            </td>

                                            <td>
                                                <StartTimeCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    refetch={refetch}
                                                    disabled={!checkEditableRow(activity)}
                                                />
                                            </td>
                                            <td className="pos-sy left-230px z-idx-2">
                                                <PersonCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    users={usersByPermsLevel}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => {
                                                        refetch();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <MachinesCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <ToolsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <RentalsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="8x4"
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="Semie"
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="Ampliroll"
                                                />
                                            </td>

                                            <td>
                                                <ObservationsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={refetch}
                                                />
                                            </td>
                                            <td>
                                                <TempWorkersCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <SubContractorsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <div className="fx-r fx-justify-c">
                                                    {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                        <CustomToggle
                                                            checked={activity.isTruckManager}
                                                            onChange={(checked) =>
                                                                handleActivityTruckManager(activity._id, checked, activity.siteId._id)
                                                            }
                                                            disabled={!checkEditableRow(activity)}
                                                            title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                        />
                                                    ) : (
                                                        <TruckIcon
                                                            type={
                                                                (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)
                                                                    ?.truckTypeId.designation || '') as
                                                                    | 'Semie'
                                                                    | 'Ampliroll'
                                                                    | '8x4'
                                                                    | 'Porte-char'
                                                                    | ''
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td className="row-cell-activity">
                                                <ActivityCell
                                                    activitiesTypes={activitiesTypes}
                                                    activity={activity}
                                                    refetch={refetch}
                                                    disabled={!checkEditableRow(activity)}
                                                />
                                            </td>
                                            <td>
                                                <StateCell
                                                    stateColor={getStateColor(activity.activityState)}
                                                    text={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                />
                                            </td>
                                            {!!user && user?.profileId.permsLevel <= permissionLevels.PLANNING && (
                                                <td>
                                                    {activity.activityState === reportState.PLANNINGSENT &&
                                                        activity.reportId.reportState !== reportState.REPORTFILLED && (
                                                            <Icon
                                                                name="resetTv"
                                                                onClick={() => rollBackData(activity._id)}
                                                                title={capitalizeFirstLetter(t('takeBackActivity'))}
                                                            />
                                                        )}
                                                    {activity.activityState === reportState.PLANNING && (
                                                        <Icon
                                                            name="verticalAlignTop"
                                                            onClick={() => submitData(activity._id)}
                                                            title={capitalizeFirstLetter(t('sendActivity'))}
                                                        />
                                                    )}
                                                    {activity.activityState === reportState.PLANNING && (
                                                        <Icon
                                                            name="deleteForever"
                                                            onClick={() => handleDeleteActivity(activity._id)}
                                                            title={capitalizeFirstLetter(t('deleteActivity'))}
                                                            color="crimson"
                                                        />
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    </React.Fragment>
                                ))}
                                <AmplirollTableRows
                                    activities={activities || []}
                                    activitiesTypes={activitiesTypes}
                                    currentDate={currentDate}
                                    refetch={refetch}
                                    selectedTab={selectedTab}
                                    sites={sites}
                                    trucks={trucks}
                                    universalFilter={universalFilter}
                                    usersByPermsLevel={usersByPermsLevel}
                                />
                            </tbody>
                        </table>
                        <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>
                    </div>
                )}

                {selectedTab === 1 && (
                    <PlanningTrucksPage
                        activities={activities || []}
                        activitiesTypes={activitiesTypes}
                        currentDate={currentDate}
                        refetch={refetch}
                        sites={sites}
                        trucks={trucks}
                        users={usersByPermsLevel}
                    />
                )}
                {selectedTab === 2 && (
                    <PlanningMaterialPage
                        activities={activities || []}
                        activitiesTypes={activitiesTypes}
                        currentDate={currentDate}
                        refetch={refetch}
                        sites={sites}
                        trucks={trucks}
                        users={usersByPermsLevel}
                    />
                )}
                {showActivityModal && (
                    <CreateActivityModal
                        activitiesTypes={activitiesTypes}
                        open={showActivityModal}
                        handleClose={() => setShowActivityModal(false)}
                        sites={sites.map((type) => {
                            return { label: type.name, value: type._id };
                        })}
                        users={usersByPermsLevel}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                        initSiteId={selectedSiteId}
                    />
                )}

                {showPeopleModal && (
                    <RemainingPeopleModal
                        activitiesTypes={activitiesTypes}
                        open={showPeopleModal}
                        handleClose={() => setShowPeopleModal(false)}
                        activities={activities || []}
                        sites={sites.map((type) => {
                            return { label: type.name, value: type._id };
                        })}
                        users={usersByPermsLevel}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                    />
                )}
                {showMaterialModal && (
                    <RemainingMaterialModal
                        open={showMaterialModal}
                        handleClose={() => setShowMaterialModal(false)}
                        activities={activities || []}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                        currentDate={currentDate}
                    />
                )}
                {showCalendarModal && (
                    <CalendarModal
                        currentDate={currentDate}
                        open={showCalendarModal}
                        handleClose={() => setShowCalendarModal(false)}
                        setCurrentDate={handleDateChange}
                    />
                )}
            </div>
        </div>
    );
};

export default PlanningPage;
