import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import { createActivity, UserItem } from '../../services/apiSLTP/planning';
import { permissionLevels, reportState, stepModes } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { SelectChangeEvent } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import CreateActivityModal from './CreateActivityModal';
import { useAuth } from '../../hooks/useAuth';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    sites: { label: string; value: string }[];
    users: UserItem[];
    activitiesTypes: { label: string; value: string }[];
    activities: DataItem[];
    initDate: string;
    refetch: () => void;
}

const RemainingPeopleModal: React.FC<ModalProps> = ({ open, handleClose, users, activitiesTypes, sites, activities, initDate, refetch }) => {
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedActivityTypeId, setSelectedActivityTypeId] = useState('');
    const [showActivityModal, setShowActivityModal] = useState(false);

    const { t } = useTranslation();
    const { user: connectedUser } = useAuth();

    const handleRemainingPeople = () => {
        handleClose();
    };

    const getRemainingUsers = () => {
        const activitiesUsers = activities.map((activity) => activity.reportId.userId._id);
        const sortedUsers = users.sort((a, b) => a.firstName.localeCompare(b.firstName));
        return {
            drivers: sortedUsers.filter(
                (user) => !activitiesUsers.includes(user._id) && user.profileId.permsLevel === permissionLevels.CHAUFFEUR && !user.isSpecial
            ),
            field: sortedUsers.filter(
                (user) => !activitiesUsers.includes(user._id) && user.profileId.permsLevel === permissionLevels.TERRAIN && !user.isSpecial
            ),
        };
    };

    return (
        <ModalBody
            closeText={t('cancel')}
            okText={t('validate')}
            title={t('remainingUsers')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleRemainingPeople();
            }}
            disabledOk={false}
            isOkVisible={false}
            width={400}
        >
            <div className="fx-c max-h-500px " style={{ overflowY: 'scroll' }}>
                <h3 className="m-0">{capitalizeFirstLetter(t('terrain'))} :</h3>
                {getRemainingUsers().field.map((user) => (
                    <div key={user._id} className="fx-r fx-justify-sb fx-items-c border border-lightgray border-radius-5px pad-3px m-t-5px ">
                        <div>
                            {capitalizeFirstLetter(user.firstName)} {user.lastName.toUpperCase()}
                        </div>
                        {!!connectedUser && connectedUser?.profileId.permsLevel <= permissionLevels.PLANNING && (
                            <div>
                                <Icon
                                    name="addCircle"
                                    onClick={() => {
                                        setSelectedUserId(user._id);
                                        setSelectedActivityTypeId('');
                                        setShowActivityModal(true);
                                    }}
                                    title={t('add')}
                                />
                            </div>
                        )}
                    </div>
                ))}
                <h3 className="m-0 m-t-10px">{capitalizeFirstLetter(t('drivers'))} :</h3>
                {getRemainingUsers().drivers.map((user) => (
                    <div key={user._id} className="fx-r fx-justify-sb fx-items-c border border-lightgray border-radius-5px pad-3px m-t-5px">
                        <div>
                            {capitalizeFirstLetter(user.firstName)} {user.lastName.toUpperCase()}
                        </div>
                        {!!connectedUser && connectedUser?.profileId.permsLevel <= permissionLevels.PLANNING && (
                            <div>
                                <Icon
                                    name="addCircle"
                                    onClick={() => {
                                        setSelectedUserId(user._id);
                                        setSelectedActivityTypeId('668e3980e29391daaf625cb5');
                                        setShowActivityModal(true);
                                    }}
                                    title={t('add')}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {showActivityModal && (
                <CreateActivityModal
                    activitiesTypes={activitiesTypes}
                    open={showActivityModal}
                    handleClose={() => setShowActivityModal(false)}
                    sites={sites}
                    users={users}
                    initDate={initDate}
                    refetch={() => refetch()}
                    initUserId={selectedUserId}
                    initActivityTypeId={selectedActivityTypeId}
                />
            )}
        </ModalBody>
    );
};

export default RemainingPeopleModal;
