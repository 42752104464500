import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, permissionLevels, truckMode, truckRideActivityTypeId } from '../../../utils/enums';
import { getMaterialStateColor } from '../../../utils/colors';
import { removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';
import DriversAmplirollRow from './DriversAmplirollRow';
import { useAuth } from '../../../hooks/useAuth';
import ReportsRow from './ReportsRow';
import { Truck } from '../../../services/apiSLTP/materials/trucks';

interface ReportsTableProps {
    activities: DataItem[];
    refetch: () => {};
    trucks: Truck[];
    dailyTrucksRides: DailyTruckRide[];
    currentDate: Date;
}

const ReportsTable: React.FC<ReportsTableProps> = ({ activities, trucks, refetch, dailyTrucksRides, currentDate }) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const sortedReports = () => {
        return removeDuplicates(
            activities.map((activity) => activity.reportId),
            ['_id']
        ).sort((a, b) => {
            const userA = a.userId.lastName + a.userId.firstName;
            const userB = b.userId.lastName + a.userId.firstName;

            if (userA < userB) return -1;
            if (userA > userB) return 1;

            return 0;
        });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr>
                        <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4"></th>
                        <th scope="col" className="w-200px left-30px z-idx-4">
                            {capitalizeFirstLetter(t('person'))}
                        </th>
                        <th scope="col" className="w-150px">
                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('status'))}</div>
                        </th>
                        <th scope="col" className="w-200px pos-sy left-230px z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-75px">
                            {capitalizeFirstLetter(t('start'))}
                        </th>
                        <th scope="col" className="w-75px">
                            {capitalizeFirstLetter(t('end'))}
                        </th>
                        <th scope="col" className="w-75px">
                            {capitalizeFirstLetter(t('validatedAt'))}
                        </th>
                        <th scope="col" className="w-100px">
                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('observationsPlanning'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('observationsWorker'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('machines'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('tools'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('rentals'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('tempWorkers'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('subContractors'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('activity'))}
                        </th>
                        {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortedReports().map((report) => (
                        <ReportsRow
                            activities={activities}
                            report={report}
                            refetch={refetch}
                            trucks={trucks}
                            dailyTrucksRides={dailyTrucksRides}
                            currentDate={currentDate}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportsTable;
