import React, { useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './context/AuthContext';
import i18n from './config/i18n';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routers from './navigations/Routers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './navigations/NavBar';
import CssBaseline from '@mui/material/CssBaseline';
import './SLTP.css';
import { customColors } from './utils/colors';
import Footer from './components/Footer';
import OnLoadPage from './pages/OnLoadPage';
import { frFR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import dayjs from 'dayjs';

dayjs.locale('fr');

const theme = createTheme({
    typography: {
        fontFamily: ['SF Pro Text', 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: 12,
    },
    palette: {
        primary: { main: '#000000' },
    },
    /*palette: {
        mode: 'dark',
    },
    components: {
        MuiSwitch: {
            styleOverrides: {
                colorPrimary: {
                    '&.Mui-checked': {
                        color: customColors.green,
                    },
                },
                track: {
                    '.Mui-checked.Mui-checked + &': {
                        opacity: 0.4,
                        backgroundColor: customColors.green,
                    },
                },
            },
        },
    },*/
});

const App: React.FC = () => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 0,
                        refetchOnWindowFocus: false,
                        refetchOnMount: true,
                    },
                },
            })
    );
    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient} >
                <I18nextProvider i18n={i18n}>
                    <AuthContextInitializer>
                        <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"
                                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                                >
                                    <Routers />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </DndProvider>
                    </AuthContextInitializer>
                </I18nextProvider>
            </QueryClientProvider>
        </AuthProvider>
    );
};

interface AuthContextInitializerProps {
    children: React.ReactNode;
}

const AuthContextInitializer: React.FC<AuthContextInitializerProps> = ({ children }) => {
    useEffect(() => {
        //setupAxiosInterceptors(goToLoginPage);
    }, []);

    return <>{children}</>;
};

export default App;
