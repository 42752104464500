import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTempWorkersQuery } from '../../hooks/queries/useTempWorkersQuery';
import { TempWorker } from '../../services/apiSLTP/materials/tempWorkers';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateTempWorkerMutation } from '../../hooks/mutations/useTempWorkerMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateTempWorkerModal from '../modals/UpdateTempWorkerModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import StateCell from '../cells/StateCell';

interface TempWorkersSettingsRowProps {
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const TempWorkersSettingsRow: React.FC<TempWorkersSettingsRowProps> = ({ universalFilter, setAddFtn }) => {
    const emptyTempWorker: TempWorker = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [tempWorkers, setTempWorkers] = useState<TempWorker[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTempWorker, setSelectedTempWorker] = useState<TempWorker>(emptyTempWorker);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useTempWorkersQuery({});
    const {
        mutate: mutateTempWorker,
        isSuccess: isSuccessTempWorker,
        isError: isErrorTempWorker,
        isLoading: isLoadingTempWorker,
        error: errorTempWorker,
        data: dataTempWorker,
    } = useUpdateTempWorkerMutation({
        onSuccess: (data, variables) => {
            {
                /*setTempWorkers((prevTempWorkers) => {
                const newTempWorkers = [...prevTempWorkers];
                const tmpIndex = newTempWorkers.findIndex((value) => value._id === variables.tempWorkerId);
                newTempWorkers[tmpIndex].crudStatus = crudStatus.DELETED;
                return newTempWorkers;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tempWorker:', error.message);
        },
    });

    const filterTempWorkers = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (tempWorker: TempWorker) => {
            return searchTerm.length === 0 || matchesSearchTerm(tempWorker?.designation);
        };
        return tempWorkers.filter(matchesFilter);
    };

    const sortedTempWorkers = () => {
        return tempWorkers.sort((a, b) => {
            const typeA = a?.designation;
            const typeB = b?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setTempWorkers(data);
        }
    }, [data]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedTempWorker(emptyTempWorker);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedTempWorkers().map((tempWorker, index, array) => (
                            <tr key={tempWorker._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {tempWorker.designation}
                                </td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedTempWorker(tempWorker);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={tempWorker.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedTempWorker(tempWorker);
                                                setIsEditMode(false);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={tempWorker.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(tempWorker?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(tempWorker?.crudStatus)))}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateTempWorkerModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    tempWorker={selectedTempWorker}
                    isEditMode={isEditMode}
                    tempWorkers={tempWorkers}
                    setTempWorkers={setTempWorkers}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateTempWorker({ tempWorkerId: selectedTempWorker._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedTempWorker.designation}
                    title={t('tempWorker')}
                />
            )}
        </div>
    );
};

export default TempWorkersSettingsRow;
