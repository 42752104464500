import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Dump } from '../../services/apiSLTP/materials/dumps';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateDumpMutation, useUpdateDumpMutation } from '../../hooks/mutations/useDumpMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    dump: Dump;
    isEditMode?: boolean;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateDumpModal: React.FC<ModalProps> = ({ dump, isEditMode = true, showEditModal, setShowEditModal, refetch }) => {
    const { t } = useTranslation();
    const [tmpDump, setTmpDump] = useState(dump);
    const [selectedDesignation, setSelectedDesignation] = useState(dump.designation ?? '');
    const [selectedCodeProC, setSelectedCodeProC] = useState(dump.codeProC ?? '');
    const [selectedVolume, setSelectedVolume] = useState(dump.volume ?? 10);
    const [selectedOrder, setSelectedOrder] = useState(dump.order ?? 0);
    const [selectedCrudStatus, setSelectedCrudStatus] = useState(dump.crudStatus ?? crudStatus.OK);

    const { user } = useAuth();

    const {
        mutate: mutateDump,
        isSuccess: isSuccessDump,
        isError: isErrorDump,
        isLoading: isLoadingDump,
        error: errorDump,
        data: dataDump,
    } = useUpdateDumpMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating dump:', error.message);
        },
    });

    const {
        mutate: addDump,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateDumpMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating dump:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpDump((prevDump) => {
            const newDump = { ...prevDump };
            newDump.designation = text;
            return newDump;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setSelectedCrudStatus(crudStatus.OK);
        } else {
            setSelectedCrudStatus(crudStatus.INACTIVE);
        }
    };

    return (
        <ModalBody
            title={t('dump')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!selectedDesignation.length || !selectedCodeProC.length || selectedOrder >= 0 || selectedVolume >= 0}
            handleOk={() => {
                isEditMode
                    ? mutateDump({
                          dumpId: dump._id,
                          data: {
                              designation: selectedDesignation,
                              crudStatus: selectedCrudStatus,
                              codeProC: selectedCodeProC,
                              volume: selectedVolume,
                              order: selectedOrder,
                          },
                      })
                    : addDump({ designation: selectedDesignation, codeProC: selectedCodeProC, volume: selectedVolume, order: selectedOrder });
            }}
        >
            <CustomTextInput
                value={selectedDesignation}
                label={t('name')}
                onChange={(text) => setSelectedDesignation(text)}
                errorText={t('error')}
                required
            />
            <CustomTextInput
                value={selectedCodeProC}
                label={t('codeProC')}
                onChange={(text) => setSelectedCodeProC(text)}
                errorText={t('error')}
                required
            />
            {isEditMode && <ToggleLine checked={selectedCrudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />}
            <CustomTextInput
                value={selectedVolume}
                label={t('volume')}
                onChange={(text) => setSelectedVolume(parseInt(text))}
                errorText={t('error')}
                type="number"
                required
            />
            <CustomTextInput
                value={selectedOrder}
                label={t('order')}
                onChange={(text) => setSelectedOrder(parseInt(text))}
                errorText={t('error')}
                type="number"
                required
            />
        </ModalBody>
    );
};

export default UpdateDumpModal;
