import React from 'react';
import CustomToggle from './CustomToggle';
import { capitalizeFirstLetter } from '../utils/capitalize';

interface ToggleLineProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    title: string;
}

const ToggleLine: React.FC<ToggleLineProps> = ({ checked, onChange, title }) => {
    return (
        <div className="fx-r fx-items-c fx-justify-sb fx-fg-1 m-5px h-30px border-radius-5px border border-lightgray pad-5px">
            <div className="sFont font-w-500 pad-l-5px">{capitalizeFirstLetter(title)}</div>
            <CustomToggle checked={checked} onChange={onChange} style={{ height: 20 }} />
        </div>
    );
};

export default ToggleLine;
