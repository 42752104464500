import { useQuery, QueryFunction } from 'react-query';
import { getAllMachinesTypes, MachineType } from '../../services/apiSLTP/materials/machinesTypes';

type UseMachinesTypesQueryOptions = {};

const getMachinesTypes: QueryFunction<MachineType[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseMachinesTypesQueryOptions];
    const {} = options;

    const response = await getAllMachinesTypes();
    return response.data;
};

export const useMachinesTypesQuery = (options: UseMachinesTypesQueryOptions) => {
    return useQuery(['machinesTypes', options], getMachinesTypes);
};
