import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailySubContractor } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface SubContractorsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const SubContractorsTable: React.FC<SubContractorsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesBySubContractor = () => {
        return activities
            .map((activity) => activity.dailySubContractors)
            .flat(1)
            .sort((a, b) => {
                if (a.subContractorTypeId.designation > b.subContractorTypeId.designation) return 1;
                if (a.subContractorTypeId.designation < b.subContractorTypeId.designation) return -1;
                return 0;
            });
    };


    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesBySubContractor().map((dailySubContractor) =>
                            <tr key={dailySubContractor._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{dailySubContractor.subContractorTypeId.designation}</td>
                                <td className="">{dailySubContractor.durationDay}</td>
                                <td className="">
                                    {
                                        activities.find((activity) =>
                                            activity.dailySubContractors.map((subContractor) => subContractor._id).includes(dailySubContractor._id)
                                        )?.siteId.name
                                    }
                                </td>
                            </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SubContractorsTable;
