import axios, { AxiosResponse } from 'axios';
import { apiClient } from './endpoints';
import { Site } from './planning';

interface SitesResponse {
    message: string;
    data: Site[];
}

export const getAllSites = async (): Promise<SitesResponse> => {
    try {
        const response: AxiosResponse<SitesResponse> = await apiClient.get('/sites');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getActiveSites = async (): Promise<SitesResponse> => {
    try {
        const response: AxiosResponse<SitesResponse> = await apiClient.get('/sites/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateSiteBody {
    name?: string;
    address?: string;
    codeProC?: string;
    conducId?: string;
    entityId?: string;
    startTime?: string;
    crudStatus?: number;
    isFavorite?: boolean;
}

export interface SiteResponse {
    message: string;
    data: Site;
}

export const updateSite = async (siteId: string, data: UpdateSiteBody): Promise<SiteResponse> => {
    try {
        const response: AxiosResponse<SiteResponse> = await apiClient.patch('/sites/' + siteId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateSiteBody {
    name: string;
    address: string;
    codeProC: string;
    conducId: string;
    entityId: string;
    startTime: string;
    isFavorite?: boolean;
    crudStatus?: number;
}

export const createSite = async (data: CreateSiteBody): Promise<SiteResponse> => {
    try {
        const response: AxiosResponse<SiteResponse> = await apiClient.post('/sites', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
