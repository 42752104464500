import { useQuery, QueryFunction } from 'react-query';
import { getAllSubContractors, SubContractor } from '../../services/apiSLTP/materials/subContractors';

type UseSubContractorsQueryOptions = {};

const getSubContractors: QueryFunction<SubContractor[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseSubContractorsQueryOptions];
    const {} = options;

    const response = await getAllSubContractors();
    return response.data;
};

export const useSubContractorsQuery = (options: UseSubContractorsQueryOptions) => {
    return useQuery(['subContractors', options], getSubContractors);
};
