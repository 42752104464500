import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import Icon from './Icon';

interface HeaderProps {
    title: string;
    children?: React.ReactNode;
    isSearchable?: boolean;
    searchValue?: string;
    isDateVisible?: boolean;
    isSecondDateVisible?: boolean;
    date?: Date;
    secondDate?: Date;
    onLeftArrowClick?: () => void;
    onRightArrowClick?: () => void;
    onSecondLeftArrowClick?: () => void;
    onSecondRightArrowClick?: () => void;
    onSearch?: (text: string) => void;
    shortDate?: boolean;
    shortSecondDate?: boolean;
}

const Header: React.FC<HeaderProps> = ({
    title,
    children,
    isSearchable = true,
    date = new Date(),
    isDateVisible = true,
    isSecondDateVisible = false,
    onLeftArrowClick = () => {},
    onRightArrowClick = () => {},
    secondDate = new Date(),
    onSecondLeftArrowClick = () => {},
    onSecondRightArrowClick = () => {},
    shortDate = false,
    shortSecondDate = false,
}) => {
    const { t } = useTranslation();
    const [universalFilter, setUniversalFilter] = useState('');
    const [tableVisible, setTableVisible] = useState(false);

    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    const getEmojiByDate = (tmpDate: Date) => {
        const currentMonth = tmpDate.getMonth() + 1;
        const currentDay = tmpDate.getDate();

        if (currentMonth === 1 && currentDay === 1) return '🎊';
        if (currentMonth === 1 && currentDay === 6) return '🍫🚨';
        if (currentMonth === 7 && currentDay === 14) return '🇫🇷';
        if (currentMonth === 4 && currentDay === 18) return '⭐️';
        if (currentMonth === 10 && currentDay === 31) return '🎃';
        if (currentMonth === 11 && currentDay === 8) return '🦁';
        if (currentMonth === 12 && currentDay === 8) return '🕯️';
        if (currentMonth === 12 && currentDay === 25) return '🎄';
        if (sameDay(tmpDate, new Date())) return '🔖';
    };

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const tableElement = document.getElementById('fu-planning-obj');
                if (tableElement) {
                    setTableVisible(true); // Table is visible
                } else {
                    setTableVisible(false); // Table is not visible
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!isSearchable || !tableVisible) return;

        const inputElement = document.getElementById('fu-classic') as HTMLInputElement;

        if (!inputElement) return;

        if (universalFilter !== '') {
            inputElement.style.borderColor = 'white';
        } else {
            inputElement.style.borderColor = 'gray';
        }

        const tbodyElement = document.getElementById('fu-planning-obj');
        if (tbodyElement) {
            const rows = tbodyElement.querySelectorAll('tr');
            rows.forEach((row) => {
                const rowElement = row as HTMLElement;
                if (rowElement.id === 'fu-exclude') return;
                let containsFilter = false;
                rowElement.querySelectorAll('*').forEach((child) => {
                    const childElement = child as HTMLElement;
                    const childText = childElement.textContent?.toLowerCase() || '';
                    const childValue = (childElement.getAttribute('value') || '').toLowerCase();
                    const childTitle = (childElement.getAttribute('title') || '').toLowerCase();

                    if (
                        childText.includes(universalFilter.toLowerCase()) ||
                        childValue.includes(universalFilter.toLowerCase()) ||
                        childTitle.includes(universalFilter.toLowerCase())
                    ) {
                        containsFilter = true;
                    }
                });

                rowElement.style.display = containsFilter ? '' : 'none';
            });
        } else {
            console.log('tbody not found');
        }
    }, [universalFilter, tableVisible]);

    return (
        <div className="fx-r fx-w fx-justify-sb fx-items-c w-100 m-t-10px">
            <div className="fx-r fx-items-c fx-justify-sb fx-w border-radius-5px pad-l-10px pad-r-10px modern-container w-100 pad-5px">
                <h3 className="xxlFont m-t-5px m-b-5px text-darkred">{title.toUpperCase()}</h3>
                <div className="fx-r fx-items-c fx-justify-sb fx-w ">
                    {isDateVisible && (
                        <>
                            <div className={'fx-r fx-items-c fx-justify-sb border-radius-5px ' + (shortDate ? ' min-w-200px' : ' min-w-400px')}>
                                <Icon
                                    name="arrowLeftSharp"
                                    onClick={onLeftArrowClick}
                                    onKeyDown={(event) => {
                                        if (event.ctrlKey && event.key === 'ArrowLeft') onLeftArrowClick();
                                    }}
                                    dark
                                    classes={{ icon: 'w-40px-imp h-40px-imp' }}
                                    title={capitalizeFirstLetter(t('previousDay'))}
                                />

                                <div
                                    className={'plan-date fx-r fx-nw fx-justify-c xxlFont '}
                                    style={{
                                        color: date.getDay() === 6 ? 'orange' : date.getDay() === 0 ? 'crimson' : 'white',
                                        backgroundColor: sameDay(date, new Date()) ? 'rgba(50,50,50,1)' : '',
                                        borderRadius: 15,
                                        boxShadow: sameDay(date, new Date()) ? '0 0 0 3px rgba(50,50,50,1)' : '',
                                        padding: '0px 5px',
                                    }}
                                >
                                    {capitalizeEveryFirstWordLetter(
                                        date.toLocaleDateString(t('localeDate'), {
                                            weekday: shortDate ? 'short' : 'long',
                                            year: 'numeric',
                                            month: shortDate ? 'short' : 'long',
                                            day: 'numeric',
                                        })
                                    )}
                                    <sup className="mFont">{getEmojiByDate(date)}</sup>
                                </div>
                                <Icon
                                    name="arrowRightSharp"
                                    onClick={onRightArrowClick}
                                    dark
                                    onKeyDown={(event) => {
                                        if (event.ctrlKey && event.key === 'ArrowRight') onRightArrowClick();
                                    }}
                                    classes={{ icon: 'w-40px-imp h-40px-imp' }}
                                    title={capitalizeFirstLetter(t('nextDay'))}
                                />
                            </div>
                            <div className="divider" />
                        </>
                    )}
                    {isSecondDateVisible && (
                        <>
                            <div className={'fx-r fx-items-c fx-justify-sb border-radius-5px ' + (shortSecondDate ? ' min-w-200px' : ' min-w-400px')}>
                                <Icon
                                    name="arrowLeftSharp"
                                    onClick={onSecondLeftArrowClick}
                                    onKeyDown={(event) => {
                                        if (event.ctrlKey && event.key === 'ArrowLeft') onSecondLeftArrowClick();
                                    }}
                                    dark
                                    classes={{ icon: 'w-40px-imp h-40px-imp' }}
                                    title={capitalizeFirstLetter(t('previousDay'))}
                                />

                                <div
                                    className={'plan-date fx-r fx-nw fx-justify-c xxlFont '}
                                    style={{
                                        color: secondDate.getDay() === 6 ? 'orange' : secondDate.getDay() === 0 ? 'crimson' : 'white',
                                        backgroundColor: sameDay(secondDate, new Date()) ? 'rgba(50,50,50,1)' : '',
                                        borderRadius: 15,
                                        boxShadow: sameDay(secondDate, new Date()) ? '0 0 0 3px rgba(50,50,50,1)' : '',
                                        padding: '0px 5px',
                                    }}
                                >
                                    {capitalizeEveryFirstWordLetter(
                                        secondDate.toLocaleDateString(t('localeDate'), {
                                            weekday: shortSecondDate ? 'short' : 'long',
                                            year: 'numeric',
                                            month: shortSecondDate ? 'short' : 'long',
                                            day: 'numeric',
                                        })
                                    )}
                                    
                                    <sup className="mFont">{getEmojiByDate(secondDate)}</sup>
                                </div>
                                <Icon
                                    name="arrowRightSharp"
                                    onClick={onSecondRightArrowClick}
                                    dark
                                    onKeyDown={(event) => {
                                        if (event.ctrlKey && event.key === 'ArrowRight') onSecondRightArrowClick();
                                    }}
                                    classes={{ icon: 'w-40px-imp h-40px-imp' }}
                                    title={capitalizeFirstLetter(t('nextDay'))}
                                />
                            </div>
                            <div className="divider" />
                        </>
                    )}
                    {children}
                    {isSearchable && (
                        <div className="fx-r fx-items-c pad-l-15px">
                            <input
                                type="text"
                                value={universalFilter}
                                onChange={(event) => setUniversalFilter(event.target.value)}
                                placeholder={capitalizeFirstLetter(t('search')) + '...'}
                                className="modern-input"
                                id="fu-classic"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
