import React from 'react';
import { TextField } from '@mui/material';
import { capitalizeFirstLetter } from '../utils/capitalize';

interface CustomTextInputProps {
    children?: React.ReactNode;
    disabled?: boolean;
    error?: boolean;
    errorText?: string;
    helperText?: string;
    onChange?: (text: string) => void;
    onBlur?: (text: string) => void;
    value: string | number;
    label?: string;
    type?: React.HTMLInputTypeAttribute;
    name?: string;
    id?: string;
    multiline?: boolean;
    required?: boolean;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
    children,
    disabled = false,
    error = false,
    errorText,
    helperText,
    onChange = () => {},
    onBlur = () => {},
    value,
    label = '',
    type = 'text',
    name,
    id,
    multiline = false,
    required = false
}) => {
    return (
        <div className="w-300px pad-5px">
            <TextField
                label={capitalizeFirstLetter(label)}
                type={type}
                multiline={multiline}
                variant="outlined"
                onChange={(event) => onChange(event.target.value)}
                onBlur={(event) => onBlur(event.target.value)}
                value={value}
                disabled={disabled}
                error={error}
                helperText={error ? errorText : helperText}
                autoComplete="new-password"
                name={name}
                id={id}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                    style: { color: '#000' },
                }}
                InputProps={{
                    classes: {
                        root: !multiline ? 'h-25px' : '',
                    },
                }}
                classes={{ root: 'autocomplete-focused' }}
                required={required}
            />
        </div>
    );
};

export default CustomTextInput;
