import { useQuery, QueryFunction } from 'react-query';
import { getActiveTools, getAllTools, getToolWithLastLocation, Tool, ToolWithLastLocation } from '../../services/apiSLTP/materials/tools';

type UseToolsQueryOptions = {};

const getTools: QueryFunction<Tool[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseToolsQueryOptions];
    const {} = options;

    const response = await getAllTools();
    return response.data;
};

export const useToolsQuery = (options: UseToolsQueryOptions) => {
    return useQuery(['tools', options], getTools);
};

type UseActiveToolsQueryOptions = {};

const getAllActiveTools: QueryFunction<Tool[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActiveToolsQueryOptions];
    const {} = options;

    const response = await getActiveTools();
    return response.data;
};

export const useActiveToolsQuery = (options: UseActiveToolsQueryOptions) => {
    return useQuery(['activeTools', options], getAllActiveTools);
};

type UseToolsWithLastLocationQueryOptions = {
    date: Date;
};

const getToolsLastLocations: QueryFunction<ToolWithLastLocation[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseToolsWithLastLocationQueryOptions];
    const {date} = options;

    const response = await getToolWithLastLocation(date);
    return response.data;
};

export const useToolsWithLastLocationsQuery = (options: UseToolsWithLastLocationQueryOptions) => {
    return useQuery(['tools locations', options], getToolsLastLocations);
};
