import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { User } from '../../services/apiSLTP/users';
import { crudStatus } from '../../utils/enums';
import { Job } from '../../services/apiSLTP/jobs';
import { Profile } from '../../services/apiSLTP/profiles';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useUpdateUserMutation, useCreateUserMutation } from '../../hooks/mutations/useUserMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    user: User;
    users: User[];
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    jobs: Job[];
    profiles: Profile[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateUserModal: React.FC<ModalProps> = ({
    user,
    setUsers,
    showEditModal,
    setShowEditModal,
    jobs,
    profiles,
    users,
    isEditMode = true,
    refetch,
}) => {
    const { t } = useTranslation();
    const [showColorModal, setShowColorModal] = useState(false);
    const [tmpUser, setTmpUser] = useState(user);
    const [password, setPassword] = useState('');
    const [selectedJob, setSelectedJob] = useState({
        label: '',
        value: '',
    });
    const [selectedProfile, setSelectedProfile] = useState({
        label: '',
        value: '',
    });

    const { user: loggedUser } = useAuth();

    const {
        mutate: mutateUser,
        isSuccess: isSuccessUser,
        isError: isErrorUser,
        isLoading: isLoadingUser,
        error: errorUser,
        data: dataUser,
    } = useUpdateUserMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating user:', error.message);
        },
    });

    const {
        mutate: addUser,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateUserMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error adding user:', error.message);
        },
    });

    const handleFirstNameChange = (text: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.firstName = text;
            return newUser;
        });
    };

    const handleLastNameChange = (text: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.lastName = text;
            return newUser;
        });
    };

    const handlePasswordChange = (text: string) => {
        setPassword(text);
    };

    const handleColorChange = (color: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.color = color;
            return newUser;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.codeProC = text;
            return newUser;
        });
    };

    const handleAddressChange = (text: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.address = text;
            return newUser;
        });
    };

    const handleUsernameChange = (text: string) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.username = text;
            return newUser;
        });
    };

    const handleSpecialChange = (isSpecial: boolean) => {
        setTmpUser((prevUser) => {
            const newUser = { ...prevUser };
            newUser.isSpecial = isSpecial;
            return newUser;
        });
    };

    const verifyFirstName = () => {
        return true;
    };

    const verifyLastName = () => {
        return true;
    };

    const verifyPassword = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            users
                .filter((value) => value._id !== tmpUser._id)
                .map((value) => value.codeProC)
                .includes(tmpUser.codeProC)
        )
            return false;
        return true;
    };

    const verifyAddress = () => {
        return true;
    };

    const verifyUserName = () => {
        if (
            users
                .filter((value) => value._id !== tmpUser._id)
                .map((value) => value.username)
                .includes(tmpUser.username)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpUser((prevUser) => {
                const newUser = { ...prevUser };
                newUser.crudStatus = crudStatus.OK;
                return newUser;
            });
        } else {
            setTmpUser((prevUser) => {
                const newUser = { ...prevUser };
                newUser.crudStatus = crudStatus.INACTIVE;
                return newUser;
            });
        }
    };

    useEffect(() => {
        const job = jobs.find((value) => value?._id === tmpUser.jobId?._id);
        if (job) setSelectedJob({ label: `${job?.designation}`, value: `${job?._id}` });
        const profile = profiles.find((value) => value?._id === tmpUser.profileId?._id);
        if (profile) setSelectedProfile({ label: profile?.type.toUpperCase(), value: profile?._id });
        setPassword('');
    }, []);

    return (
        <ModalBody
            title={t('user')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={
                !tmpUser.codeProC.length ||
                !tmpUser.firstName.length ||
                !tmpUser.lastName.length ||
                !selectedJob.value.length ||
                !selectedProfile.value.length ||
                !tmpUser.username.length ||
                (!tmpUser._id.length && password.length < 3)
            }
            handleOk={() => {
                isEditMode
                    ? mutateUser({
                          userId: user._id,
                          data: {
                              address: tmpUser.address,
                              codeProC: tmpUser.codeProC,
                              color: tmpUser.color,
                              crudStatus: tmpUser.crudStatus,
                              firstName: tmpUser.firstName,
                              jobId: selectedJob.value,
                              lastName: tmpUser.lastName,
                              profileId: selectedProfile.value,
                              username: tmpUser.username,
                              isSpecial: tmpUser.isSpecial,
                          },
                      })
                    : addUser({
                          address: tmpUser.address,
                          codeProC: tmpUser.codeProC,
                          color: tmpUser.color,
                          firstName: tmpUser.firstName,
                          jobId: selectedJob.value,
                          lastName: tmpUser.lastName,
                          profileId: selectedProfile.value,
                          username: tmpUser.username,
                          password,
                          isSpecial: tmpUser.isSpecial,
                          crudStatus: tmpUser.crudStatus,
                      });
            }}
        >
            <CustomTextInput value={tmpUser.firstName} label={t('firstName')} onChange={handleFirstNameChange} errorText={t('error')} required />
            <CustomTextInput value={tmpUser.lastName} label={t('lastName')} onChange={handleLastNameChange} errorText={t('error')} required />
            <CustomTextInput value={tmpUser.codeProC} label={t('codeProC')} onChange={handleCodeProCChange} errorText={t('error')} required />
            <ToggleLine checked={tmpUser.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
            <CustomTextInput value={tmpUser.address} onChange={handleAddressChange} label={t('address')} errorText={t('error')} />
            <CustomTextInput value={tmpUser.username} onChange={handleUsernameChange} label={t('userlogin')} errorText={t('error')} required />
            {!isEditMode && (
                <CustomTextInput
                    value={password}
                    label={t('password')}
                    onChange={handlePasswordChange}
                    errorText={t('error')}
                    type="password"
                    id="new-aaa"
                    name="new-aaa"
                    required
                />
            )}
            <DropDown
                items={jobs.map((job, index) => ({ label: `${job.designation}`, value: job._id }))}
                selectedItem={selectedJob}
                handleChange={(event, value) => setSelectedJob({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('job')}
                required
            />
            <DropDown
                items={profiles
                    .filter(
                        (value) =>
                            !!loggedUser &&
                            (value.permsLevel > loggedUser.profileId.permsLevel ||
                                loggedUser.profileId.permsLevel === profiles.find((profile) => profile._id === selectedProfile.value)?.permsLevel)
                    )
                    .sort((a, b) => {
                        if (a.permsLevel > b.permsLevel) return -1;
                        if (a.permsLevel < b.permsLevel) return 1;
                        return 0;
                    })
                    .map((profile, index) => ({ label: profile.type.toUpperCase(), value: profile._id }))}
                selectedItem={selectedProfile}
                handleChange={(event, value) => setSelectedProfile({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('profile')}
                disabled={!!loggedUser && loggedUser.profileId.permsLevel >= user.profileId.permsLevel}
                required
                sortItems={false}
            />
            <div className="fx-r fx-items-c fx-fg-2">
                <div className="fx-r fx-items-c fx-justify-sb fx-fg-1 m-5px h-30px border-radius-5px border border-lightgray pad-5px">
                    <div className="sFont font-w-500 pad-l-5px">{capitalizeFirstLetter(t('color'))}</div>
                    <CustomButton
                        color={tmpUser.color || '#fff'}
                        onClick={() => setShowColorModal(true)}
                        textColor={getTextColor(tmpUser.color || '#fff')}
                        className="h-20px min-w-10px-imp w-20px pad-0-imp"
                    />
                </div>
                <div />
                <ToggleLine checked={tmpUser.isSpecial || false} onChange={handleSpecialChange} title={t('special')} />
            </div>
            {showColorModal && (
                <ColorModal
                    open={showColorModal}
                    handleClose={() => setShowColorModal(false)}
                    initialColor={tmpUser.color}
                    handleColorChange={handleColorChange}
                />
            )}
        </ModalBody>
    );
};

export default UpdateUserModal;
