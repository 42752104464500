import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Rental } from '../../services/apiSLTP/materials/rentals';
import { crudStatus } from '../../utils/enums';
import { useCreateRentalMutation, useUpdateRentalMutation } from '../../hooks/mutations/useRentalMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    rental: Rental;
    rentals: Rental[];
    isEditMode?: boolean;
    setRentals: React.Dispatch<React.SetStateAction<Rental[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateRentalModal: React.FC<ModalProps> = ({ rental, isEditMode = true, setRentals, showEditModal, setShowEditModal, rentals, refetch }) => {
    const { t } = useTranslation();
    const [tmpRental, setTmpRental] = useState(rental);

    const { user } = useAuth();

    const {
        mutate: mutateRental,
        isSuccess: isSuccessRental,
        isError: isErrorRental,
        isLoading: isLoadingRental,
        error: errorRental,
        data: dataRental,
    } = useUpdateRentalMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating rental:', error.message);
        },
    });

    const {
        mutate: addRental,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateRentalMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating rental:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpRental((prevRental) => {
            const newRental = { ...prevRental };
            newRental.designation = text;
            return newRental;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpRental((prevRental) => {
                const newRental = { ...prevRental };
                newRental.crudStatus = crudStatus.OK;
                return newRental;
            });
        } else {
            setTmpRental((prevRental) => {
                const newRental = { ...prevRental };
                newRental.crudStatus = crudStatus.INACTIVE;
                return newRental;
            });
        }
    };

    return (
        <ModalBody
            title={t('rental')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpRental.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateRental({
                          rentalId: rental._id,
                          data: {
                              designation: tmpRental.designation,
                              crudStatus: tmpRental.crudStatus,
                          },
                      })
                    : addRental({ designation: tmpRental.designation, crudStatus: tmpRental.crudStatus });
            }}
        >
            <CustomTextInput
                value={tmpRental.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                errorText={t('error')}
                required
            />
            <ToggleLine checked={tmpRental.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateRentalModal;
