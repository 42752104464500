import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateMachineBody, MachineResponse, updateMachine, CreateMachineBody, createMachine } from '../../services/apiSLTP/materials/machines';

type UseUpdateMachineMutationOptions = MutationOptions<MachineResponse, Error, { machineId: string; data: UpdateMachineBody }>;

type UseCreateMachineMutationOptions = MutationOptions<MachineResponse, Error, CreateMachineBody>;

export const useUpdateMachineMutation = (options?: UseUpdateMachineMutationOptions) => {
    const machineMutation: MutationFunction<MachineResponse, { machineId: string; data: UpdateMachineBody }> = async ({ machineId, data }) => {
        try {
            const response: MachineResponse = await updateMachine(machineId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during machine');
        }
    };

    return useMutation(machineMutation, options);
};

export const useCreateMachineMutation = (options?: UseCreateMachineMutationOptions) => {
    const machineMutation: MutationFunction<MachineResponse, CreateMachineBody> = async (data) => {
        try {
            const response: MachineResponse = await createMachine(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during machine');
        }
    };

    return useMutation(machineMutation, options);
};
