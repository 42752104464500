import axios, { AxiosResponse } from 'axios';
import { Report } from '../activities';
import { apiClient } from '../endpoints';

interface ReportResponse {
    message: string;
    data: Report;
}

export const updateReportState = async (reportId: string, reportState: number): Promise<ReportResponse> => {
    try {
        const response: AxiosResponse<ReportResponse> = await apiClient.patch('/reports/' + reportId + '/state', {
            reportState,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
