import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiClient, endpoints } from './endpoints';
import { DataItem } from './activities';

interface UserData {
    _id: string;
    lastName: string;
    firstName: string;
    jobId: {
        _id: string;
        designation: string;
        order: number;
    };
}

interface ReportData {
    _id: string;
    userId: UserData;
    date: string;
    reportState: number;
    __v: number;
    fuel?: number;
    km?: number;
}

export interface Site {
    _id: string;
    codeProC: string;
    name: string;
    stateProC: number;
    conducId: UserData;
    address: string;
    crudStatus: number;
    entityId: string;
    isFavorite?: boolean;
}

export interface ActivityTypeData {
    _id: string;
    designation: string;
}

export interface ActivityData {
    _id: string;
    activityTypeId: ActivityTypeData;
    reportId: ReportData;
    siteId: string;
    activityState: number;
    machinesState: number;
    rentalsState: number;
    toolsState: number;
    __v: number;
    startTime: string;
    subContractorsState: number;
    tempWorkersState: number;
    validationTimeState: number;
    validationTime?: string;
    validationTimestamp?: string;
    observationsPlanning: string;
    observationsWorker: string;
    observationsPlanningState: number;
    observationsWorkerState: number;
    dailyMachines: DailyMachine[];
    dailyTools: DailyTool[];
    dailyRentals: DailyRental[];
    dailySubContractors: DailySubContractor[];
    dailyTempWorkers: DailyTempWorker[];
}

export interface ActivityDataWithId extends ActivityData {
    id: number;
    siteIdx: number;
}

export interface SiteActivities {
    site: Site;
    activities: ActivityData[];
}

export interface ActivitiesResponse {
    message: string;
    data: SiteActivities[];
}

export interface DailyMachine {
    _id: string;
    machineId: Machine;
    activityId: string;
    state: number;
}

interface Machine {
    crudStatus: number;
    _id: string;
    codeProC: string;
    designation: string;
    machineTypeId: MachineType;
    userId?: string;
}

interface MachineType {
    designation: string;
    _id: string;
}

export interface DailyTool {
    _id: string;
    toolId: Tool;
    activityId: string;
    state: number;
}

interface Tool {
    crudStatus: number;
    _id: string;
    codeProC: string;
    designation: string;
    toolTypeId: ToolType;
}

interface ToolType {
    designation: string;
    _id: string;
}

export interface DailyRental {
    _id: string;
    designation: string;
    activityId: string;
    rentalTypeId: RentalType;
    state: number;
}

export interface RentalType {
    _id: string;
    designation: string;
}

export interface DailySubContractor {
    _id: string;
    activityId: string;
    subContractorTypeId: SubContractorType;
    durationDay: number;
    state: number;
}

interface SubContractorType {
    _id: string;
    designation: string;
    colorId: string;
}

export interface DailyTempWorker {
    _id: string;
    activityId: string;
    tempWorkerTypeId: TempWorkerType;
    durationDay: number;
    state: number;
}

interface TempWorkerType {
    _id: string;
    designation: string;
    codeProC: string;
    colorId: string;
}

interface UpdatedActivity {
    message: string;
    data: DataItem;
}

export const getActivitiesByDateSortedBySite = async (date: string): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.get('activities/sites/date/' + date);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const updateActivity = async (activityId: string, updateData: any): Promise<UpdatedActivity> => {
    try {
        const response: AxiosResponse<UpdatedActivity> = await apiClient.patch('activities/activity/' + activityId, updateData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateDailyMachineResponse {
    message: string;
    data: DailyMachine;
}

export const createDailyMachine = async (
    activityId: string,
    machineId: string,
    state: number,
    createTransfer?: boolean
): Promise<CreateDailyMachineResponse> => {
    try {
        const response: AxiosResponse<CreateDailyMachineResponse> = await apiClient.post('/daily-machines', {
            activityId,
            machineId,
            state,
            createTransfer,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateDailyToolResponse {
    message: string;
    data: DailyTool;
}

export const createDailyTool = async (
    activityId: string,
    toolId: string,
    state: number,
    createTransfer?: boolean
): Promise<CreateDailyToolResponse> => {
    try {
        const response: AxiosResponse<CreateDailyToolResponse> = await apiClient.post('/daily-tools', {
            activityId,
            toolId,
            state,
            createTransfer,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateDailyRentalResponse {
    message: string;
    data: DailyRental;
}

export const createDailyRental = async (
    activityId: string,
    rentalTypeId: string,
    designation: string,
    state: number,
    createTransfer?: boolean
): Promise<CreateDailyRentalResponse> => {
    try {
        const response: AxiosResponse<CreateDailyRentalResponse> = await apiClient.post('/daily-rentals', {
            activityId,
            designation,
            rentalTypeId,
            state,
            createTransfer,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateDailySubContractorResponse {
    message: string;
    data: DailySubContractor;
}

export const createDailySubContractor = async (activityId: string, subContractorTypeId: string): Promise<CreateDailySubContractorResponse> => {
    try {
        const response: AxiosResponse<CreateDailySubContractorResponse> = await apiClient.post('/daily-subcontractors', {
            activityId,
            durationDay: 1,
            subContractorTypeId,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateDailyTempWorkerResponse {
    message: string;
    data: DailyTempWorker;
}

export const createDailyTempWorker = async (
    activityId: string,
    tempWorkerTypeId: string,
    durationDay: number
): Promise<CreateDailyTempWorkerResponse> => {
    try {
        const response: AxiosResponse<CreateDailyTempWorkerResponse> = await apiClient.post('/daily-tempworkers', {
            activityId,
            durationDay,
            tempWorkerTypeId,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteDailyMachineResponse {
    message: string;
    data: DailyMachine;
}

export const deleteDailyMachine = async (id: string): Promise<DeleteDailyMachineResponse> => {
    try {
        const response: AxiosResponse<DeleteDailyMachineResponse> = await apiClient.delete(`/daily-machines/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteDailyToolResponse {
    message: string;
    data: DailyMachine;
}

export const deleteDailyTool = async (id: string): Promise<DeleteDailyToolResponse> => {
    try {
        const response: AxiosResponse<DeleteDailyToolResponse> = await apiClient.delete(`/daily-tools/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteDailyRentalResponse {
    message: string;
    data: DailyRental;
}

export const deleteDailyRental = async (id: string): Promise<DeleteDailyRentalResponse> => {
    try {
        const response: AxiosResponse<DeleteDailyRentalResponse> = await apiClient.delete(`/daily-rentals/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteDailySubContractorResponse {
    message: string;
    data: DailySubContractor;
}

export const deleteDailySubContractor = async (id: string): Promise<DeleteDailySubContractorResponse> => {
    try {
        const response: AxiosResponse<DeleteDailySubContractorResponse> = await apiClient.delete(`/daily-subcontractors/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteDailyTempWorkersResponse {
    message: string;
    data: DailySubContractor;
}

export const deleteDailyTempWorker = async (id: string): Promise<DeleteDailyTempWorkersResponse> => {
    try {
        const response: AxiosResponse<DeleteDailyTempWorkersResponse> = await apiClient.delete(`/daily-tempworkers/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateActivityBody {
    activityTypeId: string;
    siteId: string;
    userId: string;
    startTime: string;
    state: number;
    planningValidationTime: string;
    stepMode: number;
}

export interface ActivityResponse {
    message: string;
    data: DataItem;
}

export const createActivity = async (activityData: CreateActivityBody): Promise<ActivityResponse> => {
    try {
        const response: AxiosResponse<ActivityResponse> = await apiClient.post('/activities', activityData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface Profile {
    _id: string;
    permsLevel: number;
    type: string;
}

export interface UserItem {
    _id: string;
    lastName: string;
    firstName: string;
    profileId: Profile;
    isSpecial?: boolean;
}

interface UsersResponse {
    data: UserItem[];
    message: string;
}

export const getUsersByPermsLevel = async (permsLevel: number): Promise<UsersResponse> => {
    try {
        const response: AxiosResponse<UsersResponse> = await apiClient.get('/users/perms-level/' + permsLevel);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getUsersBetweenPermsLevel = async (minPermsLevel: number, maxPermsLevel: number): Promise<UsersResponse> => {
    try {
        const response: AxiosResponse<UsersResponse> = await apiClient.get('/users/perms-level/' + minPermsLevel + '/and/' + maxPermsLevel);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface ActivitiesTypeResponse {
    message: string;
    data: ActivityTypeData[];
}

export const getActivitiesTypes = async (): Promise<ActivitiesTypeResponse> => {
    try {
        const response: AxiosResponse<ActivitiesTypeResponse> = await apiClient.get('/activities-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface MachineTypesResponse {
    message: string;
    data: MachineType[];
}

export const getMachineTypes = async (): Promise<MachineTypesResponse> => {
    try {
        const response: AxiosResponse<MachineTypesResponse> = await apiClient.get('/machine-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface ToolTypesResponse {
    message: string;
    data: ToolType[];
}

export const getToolTypes = async (): Promise<ToolTypesResponse> => {
    try {
        const response: AxiosResponse<ToolTypesResponse> = await apiClient.get('/tool-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface RentalTypesResponse {
    message: string;
    data: RentalType[];
}

export const getRentalTypes = async (): Promise<RentalTypesResponse> => {
    try {
        const response: AxiosResponse<RentalTypesResponse> = await apiClient.get('/rental-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface SubContractorTypesResponse {
    message: string;
    data: SubContractorType[];
}

export const getSubContractorTypes = async (): Promise<SubContractorTypesResponse> => {
    try {
        const response: AxiosResponse<SubContractorTypesResponse> = await apiClient.get('/subcontractor-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface TempWorkerTypesResponse {
    message: string;
    data: TempWorkerType[];
}

export const getTempWorkerTypes = async (): Promise<TempWorkerTypesResponse> => {
    try {
        const response: AxiosResponse<TempWorkerTypesResponse> = await apiClient.get('/tempworker-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface MachineResponse {
    message: string;
    data: Machine[];
}

export const getMachinesByMachineTypeId = async (machineTypeId: string): Promise<MachineResponse> => {
    try {
        const response: AxiosResponse<MachineResponse> = await apiClient.get('/machines/type/' + machineTypeId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface ToolResponse {
    message: string;
    data: Tool[];
}

export const getToolsByToolTypeId = async (toolTypeId: string): Promise<ToolResponse> => {
    try {
        const response: AxiosResponse<ToolResponse> = await apiClient.get('/tools/type/' + toolTypeId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface SitesResponse {
    message: string;
    data: Site[];
}

export const getSites = async (): Promise<SitesResponse> => {
    try {
        const response: AxiosResponse<SitesResponse> = await apiClient.get('/sites/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DeleteActivityResponse {
    message: string;
    data: string;
}

export const deleteActivity = async (activityId: string): Promise<DeleteActivityResponse> => {
    try {
        const response: AxiosResponse<DeleteActivityResponse> = await apiClient.delete('/activities/activity/' + activityId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface CreateReportData {
    _id: string;
    userId: string;
    date: string;
    reportState: number;
    __v: number;
}

interface CreateReportResponse {
    message: string;
    data: CreateReportData;
}

export const createReportIfEmpty = async (userId: string, date: string, state: number): Promise<CreateReportResponse> => {
    try {
        const response: AxiosResponse<CreateReportResponse> = await apiClient.post('/reports', {
            userId,
            date,
            state,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
