import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface Rental {
    crudStatus: number;
    _id: string;
    designation: string;
}

interface RentalsResponse {
    message: string;
    data: Rental[];
}

export const getAllRentals = async (): Promise<RentalsResponse> => {
    try {
        const response: AxiosResponse<RentalsResponse> = await apiClient.get('/rental-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateRentalBody {
    designation?: string;
    color?: string;
    crudStatus?: number;
}

export interface RentalResponse {
    message: string;
    data: Rental;
}

export const updateRental = async (rentalId: string, data: UpdateRentalBody): Promise<RentalResponse> => {
    try {
        const response: AxiosResponse<RentalResponse> = await apiClient.patch('/rental-types/' + rentalId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateRentalBody {
    designation: string;
    crudStatus?: number;
}

export const createRental = async (data: CreateRentalBody): Promise<RentalResponse> => {
    try {
        const response: AxiosResponse<RentalResponse> = await apiClient.post('/rental-types', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
