import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import { Site, UserItem } from '../../services/apiSLTP/planning';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import ModalBody from '../modals/ModalBody';
import CreateActivityModal from '../modals/CreateActivityModal';
import { useTranslation } from 'react-i18next';
import { MachineWithLastLocation } from '../../services/apiSLTP/materials/machines';
import { ToolWithLastLocation } from '../../services/apiSLTP/materials/tools';
import RemainingMaterialModal from '../modals/RemainingMaterialModal';

interface SiteCellProps {
    activity: DataItem;
    activityIndex: number;
    users?: UserItem[];
    disabled?: boolean;
    activities: DataItem[];
    refetch?: () => void;
    activitiesTypes?: { label: string; value: string }[];
    sites?: Site[];
    currentDate?: Date;
    displayAll?: boolean;
    remainingMaterial?: boolean;
}

const SiteCell: React.FC<SiteCellProps> = React.memo(
    ({
        activity,
        activityIndex,
        users,
        disabled = false,
        activities,
        refetch = () => {},
        activitiesTypes,
        sites,
        currentDate = new Date(),
        displayAll = false,
        remainingMaterial = false,
    }) => {
        const [showLocationModal, setShowLocationModal] = useState(false);
        const [selectedAddress, setSelectedAddress] = useState<Site | null>(null);
        const [showActivityModal, setShowActivityModal] = useState(false);
        const [selectedSiteId, setSelectedSiteId] = useState('');
        const [showMaterialModal, setShowMaterialModal] = useState(false);

        const { t } = useTranslation();

        const selectedUser = () => {
            return {
                value: activity.reportId.userId._id,
                label: capitalizeFirstLetter(activity.reportId.userId.firstName) + ' ' + activity.reportId.userId.lastName.toUpperCase(),
            };
        };

        const setSpecificHour = () => {
            const date = new Date(currentDate);
            date.setHours(7, 0);
            return date.toISOString();
        };

        const isFirstActivityInSite = () => {
            return !(activityIndex > 0 && activities[activityIndex - 1].siteId._id === activity.siteId._id);
        };

        const openMaps = (address: string | undefined) => {
            if (!address) return;
            const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
            window.open(mapsUrl, '_blank', 'noopener,noreferrer');
        };

        return (
            <div className="person-cell" title={selectedUser().label}>
                <div
                    style={{ display: 'none' }}
                    title={activity.siteId.name + activity.siteId.conducId?.firstName + activity.siteId.conducId?.lastName}
                />

                <div
                    className="font-w-600"
                    style={{ color: isFirstActivityInSite() || displayAll ? undefined : '#666666' }}
                    title={activity.siteId.name}
                >
                    {activity.siteId.name.includes(' - ') ? activity.siteId.name.split(' - ').slice(1).join(' - ') || '' : activity.siteId.name}
                </div>

                {(isFirstActivityInSite() || displayAll) && (
                    <>
                        <div className="fx-items-c fx-r">
                            <Icon
                                name="infoSharp"
                                onClick={() => {
                                    setSelectedAddress(activity.siteId);
                                    setShowLocationModal(true);
                                }}
                                title={capitalizeFirstLetter(t('siteInformations'))}
                            />
                            {!disabled && remainingMaterial && (
                                <Icon
                                    name="precisionManufacturing"
                                    onClick={() => {
                                        setShowMaterialModal(true);
                                    }}
                                    title={capitalizeFirstLetter(t('remainingMaterial'))}
                                />
                            )}
                            {!disabled && sites && users && activitiesTypes && (
                                <Icon
                                    name="addCircle"
                                    onClick={() => {
                                        //setSelectedSiteId(activity.siteId._id);
                                        setShowActivityModal(true);
                                    }}
                                    title={capitalizeFirstLetter(t('add'))}
                                />
                            )}

                            <div className="pad-l-5px one-line">
                                {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)} {activity.siteId?.conducId?.lastName}
                            </div>
                        </div>
                    </>
                )}
                {showLocationModal && (
                    <ModalBody
                        open={showLocationModal}
                        handleClose={() => setShowLocationModal(false)}
                        okText={t('ok')}
                        title={t('site')}
                        subTitle={t('infos')}
                        isOkVisible={false}
                    >
                        <div>{selectedAddress?.name}</div>
                        <div style={{ cursor: 'pointer' }} onClick={() => openMaps(selectedAddress?.address)}>
                            <Icon name="locationOnSharp" />
                            {selectedAddress?.address}
                        </div>
                    </ModalBody>
                )}
                {showActivityModal && (
                    <CreateActivityModal
                        activitiesTypes={activitiesTypes || []}
                        open={showActivityModal}
                        handleClose={() => setShowActivityModal(false)}
                        sites={
                            sites?.map((type) => {
                                return { label: type.name, value: type._id };
                            }) || []
                        }
                        users={users || []}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                        initSiteId={activity.siteId._id}
                    />
                )}
                {showMaterialModal && (
                    <RemainingMaterialModal
                        open={showMaterialModal}
                        handleClose={() => setShowMaterialModal(false)}
                        activities={activities || []}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                        currentDate={currentDate}
                        siteId={activity.siteId._id}
                    />
                )}
            </div>
        );
    }
);

export default SiteCell;
