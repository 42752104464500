import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, permissionLevels } from '../../../utils/enums';
import { getMaterialStateColor } from '../../../utils/colors';
import { countPropertyValues, removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';

interface SitesRidesRowProps {
    dailyTruckRide: DailyTruckRide;
    dailyTrucksRides: DailyTruckRide[];
    activities: DataItem[];
}

const SitesRidesRow: React.FC<SitesRidesRowProps> = ({ dailyTruckRide, activities, dailyTrucksRides }) => {
    const { t } = useTranslation();

    const [isExpandable, setIsExpandable] = useState(false);

    const activitiesMap = new Map(activities.map((activity) => [activity._id, activity]));

    const activity = activities.find((value) => value._id === dailyTruckRide?.activityId);
    const site = activity?.siteId;

    const sortedDailyTrucksRides = () => {
        return dailyTrucksRides.sort((a, b) => {
            const truckA = a.truckId;
            const truckB = b.truckId;

            if (truckA.truckCompanyId.designation === 'SLTP' && truckB.truckCompanyId.designation !== 'SLTP') return -1;
            if (truckA.truckCompanyId.designation !== 'SLTP' && truckB.truckCompanyId.designation === 'SLTP') return 1;

            if (truckA.plateNumber < truckB.plateNumber) return -1;
            if (truckA.plateNumber > truckB.plateNumber) return 1;

            const timeA = new Date(a.time || '');
            const timeB = new Date(b.time || '');

            if (timeA < timeB) return -1;
            if (timeA > timeB) return 1;

            return 0;
        });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <React.Fragment key={dailyTruckRide._id}>
            <tr id="fu-exclude">
                <td colSpan={17} style={{ backgroundColor: 'rgba(0,0,0,1)', height: 2, padding: 0 }} />
            </tr>
            <tr onClick={() => setIsExpandable(!isExpandable)} className="font-w-700" id="fu-exclude">
                <td className="pos-sy left-0 z-idx-2">
                    <Icon name={isExpandable ? 'keyboardArrowDownSharp' : 'keyboardArrowRightSharp'} />
                </td>
                <td scope="row" className="pos-sy left-30px z-idx-2 w-30px">
                    {site?.name.includes(' - ') ? site.name.split(' - ').slice(1).join(' - ') || '' : site?.name}
                    {` | ${dailyTrucksRides.length} ${t('ride(s)')}`}
                </td>
                <td />
                <td>
                    {countPropertyValues(
                        dailyTrucksRides.map((ride) => ride.truckId.truckCompanyId),
                        'designation'
                    ).map((type) => (
                        <div>
                            {type.number} {t(type.name)}
                        </div>
                    ))}
                </td>
                <td />
                <td>
                    {
                        countPropertyValues(
                            dailyTrucksRides.map((ride) => ride.truckId),
                            'plateNumber'
                        ).length
                    }
                </td>
                <td className="">
                    {capitalizeFirstLetter(activities.find((value) => value._id === dailyTruckRide.activityId)?.reportId.userId.firstName) +
                        ' ' +
                        activities.find((value) => value._id === dailyTruckRide.activityId)?.reportId.userId.lastName.toUpperCase()}
                </td>
                <td>
                    {countPropertyValues(
                        dailyTrucksRides.map((ride) => ride.truckId.truckTypeId),
                        'designation'
                    ).map((type) => (
                        <div>
                            {type.number} {capitalizeFirstLetter(t(type.name))}
                        </div>
                    ))}
                </td>
                <td>
                    {countPropertyValues(dailyTrucksRides, 'mode').map((mode) => (
                        <div>
                            {mode.number} {capitalizeFirstLetter(t(getTruckModeInfos(parseInt(mode.name))))}
                        </div>
                    ))}
                </td>
                <td colSpan={8} />
            </tr>
            {isExpandable &&
                sortedDailyTrucksRides().map((truckRide) => (
                    <tr key={truckRide._id} className="row">
                        <td className="pos-sy left-0 z-idx-2"></td>
                        <td scope="row" className="pos-sy left-30px z-idx-2">
                            {site?.name.includes(' - ') ? site.name.split(' - ').slice(1).join(' - ') || '' : site?.name}
                        </td>
                        <td>{truckRide.truckId?.codeProC}</td>
                        <td>{truckRide.truckId?.truckCompanyId?.designation}</td>
                        <td>{truckRide.truckId?.plateNumber}</td>
                        <td>{capitalizeFirstLetter(truckRide.truckId?.userId.firstName) + ' ' + truckRide.truckId?.userId.lastName.toUpperCase()}</td>
                        <td>
                            {capitalizeFirstLetter(activity?.reportId.userId.firstName) +
                                ' ' +
                                activities.find((value) => value._id === truckRide.activityId)?.reportId.userId.lastName.toUpperCase()}
                        </td>
                        <td>{truckRide.truckId?.truckTypeId?.designation}</td>
                        <td>{capitalizeFirstLetter(t(getTruckModeInfos(truckRide?.mode)))}</td>
                        <td>{truckRide.departureLoadNatureId?.designation}</td>
                        <td>{truckRide.departureSiteId?.name || ''}</td>
                        <td>{truckRide.departureQuarryId?.designation || ''}</td>
                        <td>{formatTime(truckRide.time)}</td>
                        <td>{truckRide.arrivalLoadNatureId?.designation}</td>
                        <td>{truckRide.arrivalSiteId?.name || ''}</td>
                        <td>{truckRide.arrivalQuarryId?.designation || ''}</td>
                        <td>{truckRide.observations || ''}</td>
                    </tr>
                ))}
        </React.Fragment>
    );
};

export default SitesRidesRow;
