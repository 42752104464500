import { useQuery, QueryFunction } from 'react-query';
import { getAllLoadNatures, LoadNature } from '../../services/apiSLTP/materials/loadNatures';

type UseLoadNaturesQueryOptions = {};

const getLoadNatures: QueryFunction<LoadNature[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseLoadNaturesQueryOptions];
    const {} = options;

    const response = await getAllLoadNatures();
    return response.data;
};

export const useLoadNaturesQuery = (options: UseLoadNaturesQueryOptions) => {
    return useQuery(['loadNatures', options], getLoadNatures);
};
