import React from 'react';

interface RangeInputProps {
    value: number;
    onChange: (value: number) => void;
    min?: number;
    max?: number;
}

const RangeInput: React.FC<RangeInputProps> = ({ value, onChange, max = 100, min = 1 }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(Number(event.target.value));
    };

    return (
        <div className="fx-r w-300px">
            <input type="range" min={min} max={max} value={value} onChange={handleChange} className="w-100 accent-black"  />
        </div>
    );
};

export default RangeInput;
