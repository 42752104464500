import { useQuery, QueryFunction } from 'react-query';
import { getAllTempWorkers, TempWorker } from '../../services/apiSLTP/materials/tempWorkers';

type UseTempWorkersQueryOptions = {};

const getTempWorkers: QueryFunction<TempWorker[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTempWorkersQueryOptions];
    const {} = options;

    const response = await getAllTempWorkers();
    return response.data;
};

export const useTempWorkersQuery = (options: UseTempWorkersQueryOptions) => {
    return useQuery(['tempWorkers', options], getTempWorkers);
};
