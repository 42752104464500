import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';
import { Quarry } from '../sites/quarries';
import { Site } from '../planning';
import { Machine } from '../materials/machines';
import { Tool } from '../materials/tools';
import { Rental } from '../materials/rentals';

export interface DailyTransfer {
    _id: string;
    dailyTrucksPlannedId: string;
    siteId?: Site;
    quarryId?: Quarry;
    machineId?: Machine;
    toolId?: Tool;
    rentalName?: string;
    rentalCompanyId?: Rental;
    state: number;
}

export interface DailyTransfersResponse {
    message: string;
    data: DailyTransfer[];
}

export interface DailyTransferResponse {
    message: string;
    data: DailyTransfer;
}

// GET All Daily Transfers
export const getAllDailyTransfers = async (): Promise<DailyTransfersResponse> => {
    try {
        const response: AxiosResponse<DailyTransfersResponse> = await apiClient.get('/daily-transfers');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// GET Daily Transfers by Daily Trucks Planned ID
export const getDailyTransfersByTruckPlannedId = async (dailyTrucksPlannedId: string): Promise<DailyTransfersResponse> => {
    try {
        const response: AxiosResponse<DailyTransfersResponse> = await apiClient.get('/daily-transfers/daily-trucksplanned/' + dailyTrucksPlannedId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// CREATE Daily Transfer

export interface CreateDailyTransferBody {
    dailyTrucksPlannedId: string;
    siteId?: string;
    quarryId?: string;
    machineId?: string;
    toolId?: string;
    rentalName?: string;
    rentalCompanyId?: string;
    state: number;
}

export const createDailyTransfer = async (body: CreateDailyTransferBody): Promise<DailyTransferResponse> => {
    try {
        const response: AxiosResponse<DailyTransferResponse> = await apiClient.post('/daily-transfers', body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// UPDATE Daily Transfer
export interface UpdateDailyTransferBody {
    dailyTrucksPlannedId?: string;
    siteId?: string;
    quarryId?: string;
    machineId?: string;
    toolId?: string;
    rentalName?: string;
    rentalCompanyId?: string;
    state?: number;
}

export const updateDailyTransfer = async (id: string, body: UpdateDailyTransferBody): Promise<DailyTransferResponse> => {
    try {
        const response: AxiosResponse<DailyTransferResponse> = await apiClient.patch('/daily-transfers/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// DELETE Daily Transfer

export const deleteDailyTransfer = async (id: string): Promise<DailyTransferResponse> => {
    try {
        const response: AxiosResponse<DailyTransferResponse> = await apiClient.delete('/daily-transfers/' + id);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
