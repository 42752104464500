import { useQuery, QueryFunction } from 'react-query';
import { getActiveSites, getAllSites } from '../../services/apiSLTP/sites';
import { Site } from '../../services/apiSLTP/planning';
import { ConducsResponse, getCurrentConducs } from '../../services/apiSLTP/sites/sites';

type UseSitesQueryOptions = {};

const getSites: QueryFunction<Site[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseSitesQueryOptions];
    const {} = options;

    const response = await getAllSites();
    return response.data;
};

export const useSitesQuery = (options: UseSitesQueryOptions) => {
    return useQuery(['sites', options], getSites);
};

type UseActiveSitesQueryOptions = {};

const getAllActiveSites: QueryFunction<Site[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseSitesQueryOptions];
    const {} = options;

    const response = await getActiveSites();
    return response.data;
};

export const useActiveSitesQuery = (options: UseSitesQueryOptions) => {
    return useQuery(['active sites', options], getAllActiveSites);
};

type UseConducsQueryOptions = {};

const getConducs: QueryFunction<ConducsResponse['data']> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseConducsQueryOptions];
    const {} = options;

    const response = await getCurrentConducs();
    return response.data;
};

export const useConducsQuery = (options: UseConducsQueryOptions) => {
    return useQuery(['conducs', options], getConducs);
};
