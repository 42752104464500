import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown';
import { DataItem } from '../../services/apiSLTP/activities';
import { updateActivity } from '../../services/apiSLTP/planning';

interface ActivityCellProps {
    activity: DataItem;
    activitiesTypes: { label: string; value: string }[];
    disabled?: boolean;
    refetch: () => void;
}

const ActivityCell: React.FC<ActivityCellProps> = React.memo(({ activity, activitiesTypes, disabled = false, refetch }) => {
    const handleActivityChange = async (activityType: { label: string; value: string }) => {
        await updateActivity(activity._id, { activityTypeId: activityType.value });
        refetch();
    };

    const selectedActivityType = () => {
        return { value: activity.activityTypeId._id, label: activity.activityTypeId.designation };
    };

    return (
        <div title={selectedActivityType().label}>
            <DropDown
                selectedItem={selectedActivityType()}
                items={activitiesTypes}
                inputLabel=""
                handleChange={(event, value) => handleActivityChange({ value: value?.value || '', label: value?.label || '' })}
                disabled={disabled}
                selectClassName="h-25px"
                formClassName="pad-0-imp w-200px-imp"
            />
        </div>
    );
});

export default ActivityCell;
