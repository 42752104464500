import { useQuery, QueryFunction } from 'react-query';
import { getAllActiveUsers, getAllUsers, User } from '../../services/apiSLTP/users';

type UseUsersQueryOptions = {};

const getUsers: QueryFunction<User[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseUsersQueryOptions];
    const {} = options;

    const response = await getAllUsers();
    return response.data;
};

export const useUsersQuery = (options: UseUsersQueryOptions) => {
    return useQuery(['users', options], getUsers);
};

type UseActiveUsersQueryOptions = {};

const getActiveUsers: QueryFunction<User[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActiveUsersQueryOptions];
    const {} = options;

    const response = await getAllActiveUsers();
    return response.data;
};

export const useActiveUsersQuery = (options: UseActiveUsersQueryOptions) => {
    return useQuery(['activeUsers', options], getActiveUsers);
};
