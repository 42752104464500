import React, { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { capitalizeFirstLetter } from '../utils/capitalize';
import { useTranslation } from 'react-i18next';

interface OptionType {
    label: string;
    value: string;
    group?: string;
}

interface DropDownProps {
    handleChange: (event: React.SyntheticEvent, value: OptionType | null) => void;
    selectedItem: OptionType;
    items: OptionType[];
    inputLabel: string;
    disabled?: boolean;
    selectClassName?: string;
    formClassName?: string;
    grouped?: boolean;
    loading?: boolean;
    warning?: boolean;
    infos?: boolean;
    sortItems?: boolean;
    disableClearable?: boolean;
    required?: boolean;
}

const DropDown: React.FC<DropDownProps> = React.memo(
    ({
        handleChange,
        selectedItem,
        items,
        inputLabel,
        disabled = false,
        selectClassName = '',
        formClassName = '',
        grouped = false,
        loading = false,
        warning = false,
        sortItems = true,
        infos = false,
        disableClearable = true,
        required = false,
    }) => {
        const { t } = useTranslation();
        const sortedItems = useMemo(() => {
            return sortItems
                ? [...items].sort((a, b) => {
                      const labelA = a.label.toLowerCase();
                      const labelB = b.label.toLowerCase();
                      if (labelA === 'sltp') return -1;
                      if (labelB === 'sltp') return 1;
                      return labelA.localeCompare(labelB);
                  })
                : items;
        }, [items, sortItems]);

        const groupedItems = useMemo(() => {
            const grouped = sortedItems.reduce(
                (acc, item) => {
                    const groupName = item.group || '---';
                    if (!acc[groupName]) {
                        acc[groupName] = [];
                    }
                    acc[groupName].push(item);
                    return acc;
                },
                {} as Record<string, OptionType[]>
            );

            const sortedGroupNames = Object.keys(grouped).sort((a, b) => a.localeCompare(b));

            return sortedGroupNames.flatMap((groupName) => grouped[groupName]);
        }, [sortedItems]);

        const handleAutocompleteChange = (event: React.SyntheticEvent, value: OptionType | null) => {
            if (value && (value.value !== selectedItem.value || value.label !== selectedItem.label)) {
                handleChange(event, value);
            }
        };

        return (
            <div className={`w-300px pad-5px ${formClassName}`}>
                <Autocomplete
                    value={selectedItem}
                    onChange={handleAutocompleteChange}
                    options={grouped ? groupedItems : sortedItems}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={capitalizeFirstLetter(inputLabel)}
                            variant="outlined"
                            fullWidth
                            disabled={disabled}
                            className={`h-25px ${selectClassName}`}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#000' },
                            }}
                            sx={{ backgroundColor: infos ? '#8888DD' : 'white', borderRadius: 1 }}
                            InputProps={{
                                ...params.InputProps,
                                classes: { notchedOutline: warning ? 'autocomplete-warning' : '' },
                                startAdornment: warning ? (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: 13,
                                            height: 13,
                                            top: -5,
                                            left: -5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 10,
                                            color: 'black',
                                            fontSize: 10,
                                            backgroundColor: warning ? 'darkorange' : undefined,
                                            zIndex: 1,
                                        }}
                                    >
                                        !
                                    </div>
                                ) : null,
                            }}
                            required={required}
                        />
                    )}
                    groupBy={grouped ? (option) => option.group || '---' : undefined}
                    disabled={disabled}
                    disableClearable={disableClearable}
                    className={selectClassName}
                    loading={loading}
                    noOptionsText={capitalizeFirstLetter(t('noItem'))}
                    loadingText={capitalizeFirstLetter(t('loading') + '...')}
                    classes={{ inputRoot: `sFont h-25px ${selectClassName}`, focused: 'autocomplete-focused' }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                            handleChange(event, null);
                            return;
                        }
                    }}
                />
            </div>
        );
    }
);

export default DropDown;
