import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateTruckCompanyMutation, useUpdateTruckCompanyMutation } from '../../hooks/mutations/useTruckCompanyMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    truckCompany: TruckCompany;
    trucksCompanies: TruckCompany[];
    isEditMode?: boolean;
    setTrucksCompanies: React.Dispatch<React.SetStateAction<TruckCompany[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTruckCompanyModal: React.FC<ModalProps> = ({
    truckCompany,
    isEditMode = true,
    setTrucksCompanies,
    showEditModal,
    setShowEditModal,
    trucksCompanies,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTruckCompany, setTmpTruckCompany] = useState(truckCompany);

    const { user } = useAuth();

    const {
        mutate: mutateTruckCompany,
        isSuccess: isSuccessTruckCompany,
        isError: isErrorTruckCompany,
        isLoading: isLoadingTruckCompany,
        error: errorTruckCompany,
        data: dataTruckCompany,
    } = useUpdateTruckCompanyMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckCompany:', error.message);
        },
    });

    const {
        mutate: addTruckCompany,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTruckCompanyMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckCompany:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTruckCompany((prevTruckCompany) => {
            const newTruckCompany = { ...prevTruckCompany };
            newTruckCompany.designation = text;
            return newTruckCompany;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTruckCompany((prevTruckCompany) => {
                const newTruckCompany = { ...prevTruckCompany };
                newTruckCompany.crudStatus = crudStatus.OK;
                return newTruckCompany;
            });
        } else {
            setTmpTruckCompany((prevTruckCompany) => {
                const newTruckCompany = { ...prevTruckCompany };
                newTruckCompany.crudStatus = crudStatus.INACTIVE;
                return newTruckCompany;
            });
        }
    };

    return (
        <ModalBody
            title={t('truckCompany')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpTruckCompany.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateTruckCompany({
                          truckCompanyId: truckCompany._id,
                          data: {
                              designation: tmpTruckCompany.designation,
                              crudStatus: tmpTruckCompany.crudStatus,
                          },
                      })
                    : addTruckCompany({ designation: tmpTruckCompany.designation, crudStatus: tmpTruckCompany.crudStatus });
            }}
        >
            <CustomTextInput
                value={tmpTruckCompany.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                required
            />
            <ToggleLine checked={tmpTruckCompany.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateTruckCompanyModal;
