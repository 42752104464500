import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateRentalBody, RentalResponse, updateRental, CreateRentalBody, createRental } from '../../services/apiSLTP/materials/rentals';

type UseUpdateRentalMutationOptions = MutationOptions<RentalResponse, Error, { rentalId: string; data: UpdateRentalBody }>;

type UseCreateRentalMutationOptions = MutationOptions<RentalResponse, Error, CreateRentalBody>;

export const useUpdateRentalMutation = (options?: UseUpdateRentalMutationOptions) => {
    const rentalMutation: MutationFunction<RentalResponse, { rentalId: string; data: UpdateRentalBody }> = async ({ rentalId, data }) => {
        try {
            const response: RentalResponse = await updateRental(rentalId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during rental');
        }
    };

    return useMutation(rentalMutation, options);
};

export const useCreateRentalMutation = (options?: UseCreateRentalMutationOptions) => {
    const rentalMutation: MutationFunction<RentalResponse, CreateRentalBody> = async (data) => {
        try {
            const response: RentalResponse = await createRental(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during rental');
        }
    };

    return useMutation(rentalMutation, options);
};
