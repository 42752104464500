import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Site } from '../../services/apiSLTP/planning';
import { crudStatus } from '../../utils/enums';
import { User } from '../../services/apiSLTP/users';
import { Entity } from '../../services/apiSLTP/sites/entities';
import { useCreateSiteMutation, useUpdateSiteMutation } from '../../hooks/mutations/useSiteMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    site: Site;
    sites: Site[];
    setSites: React.Dispatch<React.SetStateAction<Site[]>>;
    users: User[];
    entities: Entity[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateSiteModal: React.FC<ModalProps> = ({
    site,
    setSites,
    showEditModal,
    setShowEditModal,
    users,
    entities,
    sites,
    isEditMode = true,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpSite, setTmpSite] = useState(site);
    const [selectedUser, setSelectedUser] = useState({
        label: '',
        value: '',
    });
    const [selectedEntity, setSelectedEntity] = useState({
        label: '',
        value: '',
    });

    const { user } = useAuth();

    const {
        mutate: mutateSite,
        isSuccess: isSuccessSite,
        isError: isErrorSite,
        isLoading: isLoadingSite,
        error: errorSite,
        data: dataSite,
    } = useUpdateSiteMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating site:', error.message);
        },
    });

    const {
        mutate: addSite,
        isSuccess: isSuccessAdd,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateSiteMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating site:', error.message);
        },
    });

    const handleNameChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.name = text;
            return newSite;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.codeProC = text;
            return newSite;
        });
    };

    const handleAddressChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.address = text;
            return newSite;
        });
    };

    const verifyName = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            sites
                .filter((value) => value._id !== tmpSite._id)
                .map((value) => value.codeProC)
                .includes(tmpSite.codeProC)
        )
            return false;
        return true;
    };

    const verifyAddress = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpSite((prevSite) => {
                const newSite = { ...prevSite };
                newSite.crudStatus = crudStatus.OK;
                return newSite;
            });
        } else {
            setTmpSite((prevSite) => {
                const newSite = { ...prevSite };
                newSite.crudStatus = crudStatus.INACTIVE;
                return newSite;
            });
        }
    };

    const handleFavoriteChange = (checked: boolean) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.isFavorite = checked;
            return newSite;
        });
    };

    useEffect(() => {
        const user = users.find((value) => value?._id === tmpSite.conducId?._id);
        if (user) setSelectedUser({ label: `${user?.firstName} ${user?.lastName}`, value: `${user?._id || ''}` });
        const entity = entities.find((value) => value?._id === tmpSite?.entityId);
        if (entity) setSelectedEntity({ label: `${entity?.designation}`, value: `${entity?._id}` });
    }, []);

    return (
        <ModalBody
            title={t('site')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={
                !tmpSite.address.length ||
                !tmpSite.codeProC.length ||
                !tmpSite.name.length ||
                !selectedEntity.value.length ||
                !selectedUser.value.length
            }
            handleOk={() => {
                isEditMode
                    ? mutateSite({
                          siteId: site._id,
                          data: {
                              address: tmpSite.address,
                              codeProC: tmpSite.codeProC,
                              name: tmpSite.name,
                              crudStatus: tmpSite.crudStatus,
                              conducId: selectedUser.value || '',
                              entityId: selectedEntity.value || '',
                          },
                      })
                    : addSite({
                          address: tmpSite.address,
                          codeProC: tmpSite.codeProC,
                          name: tmpSite.name,
                          conducId: selectedUser.value,
                          entityId: selectedEntity.value,
                          startTime: new Date(new Date().setHours(7)).toISOString(),
                      });
            }}
        >
            <CustomTextInput value={tmpSite.name} label={t('name')} onChange={handleNameChange} errorText={t('error')} required />
            <CustomTextInput value={tmpSite.codeProC} label={t('codeProC')} onChange={handleCodeProCChange} errorText={t('error')} required />
            {isEditMode && <ToggleLine checked={tmpSite.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />}
            <CustomTextInput value={tmpSite.address || ''} onChange={handleAddressChange} label={t('address')} errorText={t('error')} required />
            <DropDown
                items={users.map((user, index) => ({ label: `${user.firstName} ${user?.lastName}`, value: user._id }))}
                selectedItem={selectedUser}
                handleChange={(event, value) => setSelectedUser({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('conduc')}
                required
            />
            <DropDown
                items={entities.map((entity, index) => ({ label: `${entity.designation}`, value: entity._id }))}
                selectedItem={selectedEntity}
                handleChange={(event, value) => setSelectedEntity({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('entity')}
                required
            />
            <ToggleLine checked={tmpSite.isFavorite ?? true} onChange={(checked) => handleFavoriteChange(checked)} title={t('favorite')} />
        </ModalBody>
    );
};

export default UpdateSiteModal;
