import React from 'react';
import semieImage from '../assets/images/semie.png';
import amplirollImage from '../assets/images/ampliroll.png';
import x4Image from '../assets/images/8x4.png';
import porteCharImage from '../assets/images/porte-char.png';
import unknownTruckImage from '../assets/images/unknowm-truck.png';
import semieWhiteImage from '../assets/images/semie-white.png';
import amplirollWhiteImage from '../assets/images/ampliroll-3-white.png';
import x4WhiteImage from '../assets/images/8x4-white.png';
import porteCharWhiteImage from '../assets/images/porte-char-white.png';
import unknownTruckWhiteImage from '../assets/images/unknowm-truck-white.png';

interface TruckIconProps {
    type: 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | '';
    style?: React.CSSProperties;
    dark?: boolean;
}

const images = {
    Semie: { light: semieImage, dark: semieWhiteImage },
    Ampliroll: { light: amplirollImage, dark: amplirollWhiteImage },
    '8x4': { light: x4Image, dark: x4WhiteImage },
    'Porte-char': { light: porteCharImage, dark: porteCharWhiteImage },
    '': { light: unknownTruckImage, dark: unknownTruckWhiteImage },
};

const TruckIcon: React.FC<TruckIconProps> = ({ type, style, dark = false }) => {
    const imageSrc = images[type] || images[''];
    return <img height={20} title={type} src={dark ? imageSrc.dark : imageSrc.light} alt={`Truck icon ${type}`} style={{ padding: 5, ...style }} />;
};

export default TruckIcon;
