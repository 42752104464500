import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';
import { getMaterialStateColor } from '../../utils/colors';
import StateCell from '../cells/StateCell';

interface MachinesTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const MachinesTable: React.FC<MachinesTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByMachine = () => {
        return activities
            .map((activity) => activity.dailyMachines)
            .flat(1)
            .sort((a, b) => {
                if (a.machineId.designation > b.machineId.designation) return 1;
                if (a.machineId.designation < b.machineId.designation) return -1;
                if (a.state < b.state) return 1;
                if (a.state > b.state) return -1;
                return 0;
            });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px  pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>

                        <th scope="col" className="w-150px">
                            <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('state'))}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByMachine().map((dailyMachine) => (
                        <tr key={dailyMachine._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{dailyMachine.machineId.designation}</td>
                            <td className="">{dailyMachine.machineId.codeProC}</td>
                            <td className="">
                                <StateCell
                                    stateColor={getMaterialStateColor(dailyMachine.state)}
                                    text={capitalizeFirstLetter(t(getMaterialStateInfos(dailyMachine.state)))}
                                />
                            </td>
                            <td className="">
                                {
                                    activities.find((activity) => activity.dailyMachines.map((machine) => machine._id).includes(dailyMachine._id))
                                        ?.siteId.name
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MachinesTable;
