import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface MachineType {
    designation: string;
    _id: string;
}

interface MachinesTypesResponse {
    message: string;
    data: MachineType[];
}

export const getAllMachinesTypes = async (): Promise<MachinesTypesResponse> => {
    try {
        const response: AxiosResponse<MachinesTypesResponse> = await apiClient.get('/machine-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
