import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface SubContractor {
    crudStatus: number;
    _id: string;
    designation: string;
    codeProC: string;
}

interface SubContractorsResponse {
    message: string;
    data: SubContractor[];
}

export const getAllSubContractors = async (): Promise<SubContractorsResponse> => {
    try {
        const response: AxiosResponse<SubContractorsResponse> = await apiClient.get('/subcontractor-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateSubContractorBody {
    designation?: string;
    codeProC?: string;
    color?: string;
    crudStatus?: number;
}

export interface SubContractorResponse {
    message: string;
    data: SubContractor;
}

export const updateSubContractor = async (subContractorId: string, data: UpdateSubContractorBody): Promise<SubContractorResponse> => {
    try {
        const response: AxiosResponse<SubContractorResponse> = await apiClient.patch('/subcontractor-types/' + subContractorId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateSubContractorBody {
    designation: string;
    codeProC: string;
    crudStatus?: number;
}

export const createSubContractor = async (data: CreateSubContractorBody): Promise<SubContractorResponse> => {
    try {
        const response: AxiosResponse<SubContractorResponse> = await apiClient.post('/subcontractor-types', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
