import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateSiteBody, SiteResponse, updateSite, CreateSiteBody, createSite } from '../../services/apiSLTP/sites';

type UseUpdateSiteMutationOptions = MutationOptions<SiteResponse, Error, { siteId: string; data: UpdateSiteBody }>;

type UseCreateSiteMutationOptions = MutationOptions<SiteResponse, Error, CreateSiteBody>;

export const useUpdateSiteMutation = (options?: UseUpdateSiteMutationOptions) => {
    const siteMutation: MutationFunction<SiteResponse, { siteId: string; data: UpdateSiteBody }> = async ({ siteId, data }) => {
        try {
            const response: SiteResponse = await updateSite(siteId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during site');
        }
    };

    return useMutation(siteMutation, options);
};

export const useCreateSiteMutation = (options?: UseCreateSiteMutationOptions) => {
    const siteMutation: MutationFunction<SiteResponse, CreateSiteBody> = async (data) => {
        try {
            const response: SiteResponse = await createSite(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during site');
        }
    };

    return useMutation(siteMutation, options);
};
