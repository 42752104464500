import React, { useState } from 'react';
import { Modal, Button } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    startDate: Date;
    endDate: Date;
    setStartDate: (newDate: string) => void;
    setEndDate: (newDate: string) => void;
    open: boolean;
    handleClose: () => void;
}

const CalendarRangeModal: React.FC<ModalProps> = ({ startDate, endDate, setStartDate, setEndDate, open, handleClose }) => {
    const [selectedStartDate, setSelectedStartDate] = useState(startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(endDate);
    const { t } = useTranslation();

    const handleStartDateChange = (value: Date | null) => {
        if (value) {
            setSelectedStartDate(value);
            if (selectedEndDate && value > selectedEndDate) {
                // If the start date is after the end date, reset the end date
                setSelectedEndDate(value);
                setEndDate(value.toISOString());
            }
        }
    };

    const handleEndDateChange = (value: Date | null) => {
        if (value) {
            setSelectedEndDate(value);
            if (selectedStartDate && value < selectedStartDate) {
                // If the end date is before the start date, reset the start date
                setSelectedStartDate(value);
                setStartDate(value.toISOString());
            }
        }
    };

    const disableDatesAfter = (date: Dayjs) => {
        return date.isAfter(dayjs(selectedEndDate), 'day');
    };

    const disableDatesBefore = (date: Dayjs) => {
        return date.isBefore(dayjs(selectedStartDate), 'day');
    };

    return (
        <ModalBody
            title={t('calendar')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                setStartDate(selectedStartDate.toISOString());
                setEndDate(selectedEndDate.toISOString());
            }}
        >
            <div className="fx-r">
                <div>
                    <h3 className="m-0">{capitalizeFirstLetter(t('startDate'))}</h3>
                    <DateCalendar
                        onChange={handleStartDateChange}
                        value={dayjs(selectedStartDate)}
                        displayWeekNumber
                        shouldDisableDate={disableDatesAfter}
                    />
                </div>
                <div style={{ width: 1, backgroundColor: 'lightgray', marginLeft: 5, marginRight: 5 }} />
                <div>
                    <h3 className="m-0">{capitalizeFirstLetter(t('endDate'))}</h3>
                    <DateCalendar
                        onChange={handleEndDateChange}
                        value={dayjs(selectedEndDate)}
                        displayWeekNumber
                        shouldDisableDate={disableDatesBefore}
                    />
                </div>
            </div>
        </ModalBody>
    );
};

export default CalendarRangeModal;
