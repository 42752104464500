import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateLoadNatureBody,
    LoadNatureResponse,
    updateLoadNature,
    CreateLoadNatureBody,
    createLoadNature,
} from '../../services/apiSLTP/materials/loadNatures';

type UseUpdateLoadNatureMutationOptions = MutationOptions<LoadNatureResponse, Error, { loadNatureId: string; data: UpdateLoadNatureBody }>;
type UseCreateLoadNatureMutationOptions = MutationOptions<LoadNatureResponse, Error, CreateLoadNatureBody>;

export const useUpdateLoadNatureMutation = (options?: UseUpdateLoadNatureMutationOptions) => {
    const loadNatureMutation: MutationFunction<LoadNatureResponse, { loadNatureId: string; data: UpdateLoadNatureBody }> = async ({
        loadNatureId,
        data,
    }) => {
        try {
            const response: LoadNatureResponse = await updateLoadNature(loadNatureId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during loadNature');
        }
    };

    return useMutation(loadNatureMutation, options);
};

export const useCreateLoadNatureMutation = (options?: UseCreateLoadNatureMutationOptions) => {
    const loadNatureMutation: MutationFunction<LoadNatureResponse, CreateLoadNatureBody> = async (data) => {
        try {
            const response: LoadNatureResponse = await createLoadNature(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during loadNature');
        }
    };

    return useMutation(loadNatureMutation, options);
};
