import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import WarningIcon from '@mui/icons-material/Warning';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import OutputIcon from '@mui/icons-material/Output';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PaletteIcon from '@mui/icons-material/Palette';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import LoginIcon from '@mui/icons-material/Login';

const iconMap = {
    addCircle: AddCircleIcon,
    deleteForever: DeleteForeverIcon,
    calendarMonth: CalendarMonthIcon,
    settingsBackupRestore: SettingsBackupRestoreIcon,
    arrowForward: ArrowForwardIcon,
    arrowBack: ArrowBackIcon,
    sendToMobile: SendToMobileIcon,
    warning: WarningIcon,
    eventRepeat: EventRepeatIcon,
    accessTime: AccessTimeIcon,
    edit: EditIcon,
    delete: DeleteIcon,
    menu: MenuIcon,
    visibility: VisibilityIcon,
    visibilityOff: VisibilityOffIcon,
    fileDownload: FileDownloadIcon,
    logout: LogoutIcon,
    lock: LockIcon,
    close: CloseIcon,
    output: OutputIcon,
    peopleAlt: PeopleAltIcon,
    precisionManufacturing: PrecisionManufacturingIcon,
    phonelinkErase: PhonelinkEraseIcon,
    resetTv: ResetTvIcon,
    addToHomeScreen: AddToHomeScreenIcon,
    verticalAlignTop: VerticalAlignTopIcon,
    verticalAlignBottom: VerticalAlignBottomIcon,
    palette: PaletteIcon,
    starSharp: StarSharpIcon,
    arrowLeftSharp: ArrowLeftSharpIcon,
    arrowRightSharp: ArrowRightSharpIcon,
    locationOnSharp: LocationOnSharpIcon,
    infoSharp: InfoSharpIcon,
    keyboardArrowDownSharp: KeyboardArrowDownSharpIcon,
    keyboardArrowRightSharp: KeyboardArrowRightSharpIcon,
    help: HelpIcon,
    login: LoginIcon,
};

interface IconProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    classes?: { iconButton?: string; icon?: string };
    title?: string;
    name: keyof typeof iconMap;
    color?: string;
    disabled?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
    dark?: boolean;
    type?: 'button' | 'reset' | 'submit';
}

const Icon: React.FC<IconProps> = ({
    onClick = () => {},
    classes = {},
    title = '',
    name,
    color = '',
    disabled = false,
    onKeyDown = () => {},
    dark = false,
    type = 'button',
}) => {
    const IconComponent = iconMap[name];

    const iconColor = disabled ? (dark ? 'gray' : 'lightgray') : color || (dark ? 'white' : 'black');
    const hoverColor = disabled ? (dark ? 'gray' : 'lightgray') : dark ? '#b3b3b3' : '#333';

    return (
        <IconButton
            onClick={onClick}
            className={'pad-0-imp ' + classes?.iconButton}
            title={title}
            disabled={disabled}
            onKeyDown={onKeyDown}
            style={{ color: iconColor }}
            type={type}
        >
            <IconComponent
                fontSize="large"
                classes={{ root: 'h-25px-imp w-25px-imp ' + classes.icon }}
                style={{
                    transition: 'color 0.3s',
                }}
                onMouseEnter={(e) => {
                    if (!disabled) e.currentTarget.style.color = hoverColor;
                }}
                onMouseLeave={(e) => {
                    if (!disabled) e.currentTarget.style.color = iconColor;
                }}
            />
        </IconButton>
    );
};

export default Icon;
