import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateEntityBody,
    EntityResponse,
    updateEntity,
    CreateEntityBody,
    createEntity,
} from '../../services/apiSLTP/sites/entities';

type UseUpdateEntityMutationOptions = MutationOptions<EntityResponse, Error, { entityId: string; data: UpdateEntityBody }>;
type UseCreateEntityMutationOptions = MutationOptions<EntityResponse, Error, CreateEntityBody>;

export const useUpdateEntityMutation = (options?: UseUpdateEntityMutationOptions) => {
    const entityMutation: MutationFunction<EntityResponse, { entityId: string; data: UpdateEntityBody }> = async ({
        entityId,
        data,
    }) => {
        try {
            const response: EntityResponse = await updateEntity(entityId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during entity');
        }
    };

    return useMutation(entityMutation, options);
};

export const useCreateEntityMutation = (options?: UseCreateEntityMutationOptions) => {
    const entityMutation: MutationFunction<EntityResponse, CreateEntityBody> = async (data) => {
        try {
            const response: EntityResponse = await createEntity(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during entity');
        }
    };

    return useMutation(entityMutation, options);
};
