import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Tool } from '../../services/apiSLTP/materials/tools';
import { crudStatus } from '../../utils/enums';
import { useCreateToolMutation, useUpdateToolMutation } from '../../hooks/mutations/useToolMutation';
import { useAuth } from '../../hooks/useAuth';
import { ToolType } from '../../services/apiSLTP/materials/toolsTypes';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    tool: Tool;
    tools: Tool[];
    setTools: React.Dispatch<React.SetStateAction<Tool[]>>;
    toolsTypes: ToolType[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateToolModal: React.FC<ModalProps> = ({
    tool,
    setTools,
    showEditModal,
    setShowEditModal,
    toolsTypes,
    tools,
    isEditMode = true,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTool, setTmpTool] = useState(tool);
    const [selectedToolType, setSelectedToolType] = useState({
        label: '',
        value: '',
    });

    const { user } = useAuth();

    const {
        mutate: mutateTool,
        isSuccess: isSuccessTool,
        isError: isErrorTool,
        isLoading: isLoadingTool,
        error: errorTool,
        data: dataTool,
    } = useUpdateToolMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tool:', error.message);
        },
    });

    const {
        mutate: addTool,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateToolMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tool:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTool((prevTool) => {
            const newTool = { ...prevTool };
            newTool.designation = text;
            return newTool;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpTool((prevTool) => {
            const newTool = { ...prevTool };
            newTool.codeProC = text;
            return newTool;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            tools
                .filter((value) => value._id !== tmpTool._id)
                .map((value) => value.codeProC)
                .includes(tmpTool.codeProC)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTool((prevTool) => {
                const newTool = { ...prevTool };
                newTool.crudStatus = crudStatus.OK;
                return newTool;
            });
        } else {
            setTmpTool((prevTool) => {
                const newTool = { ...prevTool };
                newTool.crudStatus = crudStatus.INACTIVE;
                return newTool;
            });
        }
    };

    useEffect(() => {
        const toolType = toolsTypes.find((value) => value?._id === tmpTool.toolTypeId?._id);
        if (toolType) setSelectedToolType({ label: `${toolType?.designation}`, value: `${toolType?._id}` });
    }, []);

    return (
        <ModalBody
            title={t('tool')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpTool.codeProC.length || !tmpTool.designation.length || !selectedToolType.value.length}
            handleOk={() => {
                isEditMode
                    ? mutateTool({
                          toolId: tool._id,
                          data: {
                              designation: tmpTool.designation,
                              codeProC: tmpTool.codeProC,
                              crudStatus: tmpTool.crudStatus,
                              toolTypeId: selectedToolType.value,
                          },
                      })
                    : addTool({
                          codeProC: tmpTool.codeProC,
                          designation: tmpTool.designation,
                          toolTypeId: selectedToolType.value,
                          crudStatus: tmpTool.crudStatus,
                      });
            }}
        >
            <CustomTextInput
                value={tmpTool.designation}
                onChange={handleDesignationChange}
                label={t('name')}
                //error={!verifyDesignation()}
                errorText={t('error')}
                required
            />
            <CustomTextInput
                value={tmpTool.codeProC}
                onChange={handleCodeProCChange}
                label={t('codeProC')}
                //error={!verifyCodeProC()}
                errorText={t('error')}
                required
            />
            <ToggleLine checked={tmpTool.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
            <DropDown
                items={toolsTypes.map((toolType) => ({ label: toolType.designation, value: toolType._id }))}
                selectedItem={selectedToolType}
                handleChange={(event, value) => setSelectedToolType({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('type')}
                required
            />
        </ModalBody>
    );
};

export default UpdateToolModal;
