import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.my.sltp.eu/api/v1' : 'http://localhost:8080/api/v1';

export const apiClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const endpoints = {
    login: '/login',
    logout: '/logout',
    resfreshAccessToken: '/refresh-token',
    protected: '/protected',
};
