import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { login, LoginResponse } from '../../services/apiSLTP/auth';

type UseLoginMutationOptions = MutationOptions<LoginResponse, Error, { username: string; password: string }>;

export const useLoginMutation = (options?: UseLoginMutationOptions) => {
    const loginMutation: MutationFunction<LoginResponse, { username: string; password: string }> = async ({ username, password }) => {
        try {
            const response: LoginResponse = await login(username, password);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during login');
        }
    };

    return useMutation(loginMutation, options);
};
