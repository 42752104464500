import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateDailyTruckPlannedBody, DailyTruckPlannedResponse, updateDailyTruckPlanned, CreateDailyTruckPlannedBody, createDailyTruckPlanned } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';

type UseUpdateDailyTruckPlannedMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, { DailyTruckPlannedId: string; data: UpdateDailyTruckPlannedBody }>;

export const useUpdateDailyTruckPlannedMutation = (options?: UseUpdateDailyTruckPlannedMutationOptions) => {
    const DailyTruckPlannedMutation: MutationFunction<DailyTruckPlannedResponse, { DailyTruckPlannedId: string; data: UpdateDailyTruckPlannedBody }> = async ({ DailyTruckPlannedId, data }) => {
        try {
            const response: DailyTruckPlannedResponse = await updateDailyTruckPlanned(DailyTruckPlannedId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedMutation, options);
};

type UseCreateDailyTruckPlannedMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, CreateDailyTruckPlannedBody>;

export const useCreateDailyTruckPlannedMutation = (options?: UseCreateDailyTruckPlannedMutationOptions) => {
    const DailyTruckPlannedMutation: MutationFunction<DailyTruckPlannedResponse, CreateDailyTruckPlannedBody> = async (data) => {
        try {
            const response: DailyTruckPlannedResponse = await createDailyTruckPlanned(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedMutation, options);
};
