import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../services/apiSLTP/jobs';
import DeleteModal from '../modals/DeleteModal';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useUpdateJobMutation } from '../../hooks/mutations/useJobMutation';
import UpdateJobModal from '../modals/UpdateJobModal';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import StateCell from '../cells/StateCell';

interface JobsSettingsRow {
    jobs: Job[];
    setJobs: React.Dispatch<React.SetStateAction<Job[]>>;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    refetch: () => void;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const JobsSettingsRow: React.FC<JobsSettingsRow> = ({ jobs, setJobs, isError, isLoading, isSuccess, refetch, universalFilter, setAddFtn }) => {
    const emptyJob: Job = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
        order: 0,
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState<Job>(emptyJob);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();
    const {
        mutate: mutateJob,
        isSuccess: isSuccessJob,
        isError: isErrorJob,
        isLoading: isLoadingJob,
        error: errorJob,
        data: dataJob,
    } = useUpdateJobMutation({
        onSuccess: (data, variables) => {
            {
                /*setJobs((prevJobs) => {
                const newJobs = [...prevJobs];
                const tmpIndex = newJobs.findIndex((value) => value._id === variables.jobId);
                newJobs[tmpIndex].crudStatus = crudStatus.DELETED;
                return newJobs;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating job:', error.message);
        },
    });

    const filterJobs = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (job: Job) => {
            return searchTerm.length === 0 || matchesSearchTerm(job?.designation);
        };
        return jobs.filter(matchesFilter);
    };

    const sortedJobs = () => {
        return jobs.sort((a, b) => {
            const orderA = a?.order;
            const orderB = b?.order;
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            const designationA = a?.designation;
            const designationB = b?.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedJob(emptyJob);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                            <th scope="col" className="w-50px">
                                {capitalizeFirstLetter(t('order'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedJobs().map((job, index, array) => (
                            <tr key={job._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {job.designation}
                                </td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedJob(job);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={job.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedJob(job);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={job.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(job?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(job?.crudStatus)))}
                                    />
                                </td>
                                <td>{job.order}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateJobModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    job={selectedJob}
                    jobs={jobs}
                    setJobs={setJobs}
                    refetch={refetch}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateJob({ jobId: selectedJob._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedJob.designation}
                    title={t('job')}
                />
            )}
        </div>
    );
};

export default JobsSettingsRow;
