import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTempWorker } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface TempWorkersTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const TempWorkersTable: React.FC<TempWorkersTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByTempWorker = () => {
        return activities
            .map((activity) => activity.dailyTempWorkers)
            .flat(1)
            .sort((a, b) => {
                if (a.tempWorkerTypeId.designation > b.tempWorkerTypeId.designation) return 1;
                if (a.tempWorkerTypeId.designation < b.tempWorkerTypeId.designation) return -1;
                if (a.durationDay < b.durationDay) return 1;
                if (a.durationDay > b.durationDay) return -1;
                return 0;
            });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('duration'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByTempWorker().map((dailyTempWorker) => (
                        <tr key={dailyTempWorker._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{dailyTempWorker.tempWorkerTypeId.designation}</td>
                            <td>{dailyTempWorker.durationDay}</td>
                            <td className="">
                                {
                                    activities.find((activity) =>
                                        activity.dailyTempWorkers.map((tempWorker) => tempWorker._id).includes(dailyTempWorker._id)
                                    )?.siteId.name
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TempWorkersTable;
