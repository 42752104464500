import axios, { AxiosResponse } from 'axios';
import { apiClient } from './endpoints';

export interface Profile {
    _id: string;
    type: string;
    permsLevel: number;
}

interface ProfileResponse {
    message: string;
    data: Profile[];
}

export const getAllProfiles = async (): Promise<ProfileResponse> => {
    try {
        const response: AxiosResponse<ProfileResponse> = await apiClient.get('/profiles');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
