import React from 'react';

interface CardProps {
    title: string;
    children?: React.ReactNode;
    heightSize?: 'xsmall' |'small' | 'medium' | 'large';
    widthSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

const Card: React.FC<CardProps> = ({ title, children, heightSize = 'small', widthSize = 'small' }) => {
    return (
        <div className={`card W${widthSize} H${heightSize}`}>
            <div className="card-header">
                <div className='m-0' >{title}</div>
            </div>
            
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

export default Card;
