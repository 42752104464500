import React from 'react';

interface StateCellProps {
    text: string;
    stateColor: string;
    textColor?: string;
    style?: React.CSSProperties;
}

const StateCell: React.FC<StateCellProps> = React.memo(({ text, stateColor, textColor = 'black', style }) => {
    return (
        <div className="fx-r fx-justify-c">
            <div
                style={{
                    backgroundColor: stateColor,
                    borderRadius: 15,
                    width: 120,
                    padding: 1,
                    textAlign: 'center',
                    color: textColor,
                    fontWeight: 'bolder',
                    ...style,
                }}
            >
                {text.toUpperCase()}
            </div>
        </div>
    );
});

export default StateCell;
