import { useQuery, QueryFunction } from 'react-query';
import { getAllTrucksTypes, TruckType } from '../../services/apiSLTP/materials/trucksTypes';

type UseTrucksTypesQueryOptions = {};

const getTrucksTypes: QueryFunction<TruckType[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTrucksTypesQueryOptions];
    const {} = options;

    const response = await getAllTrucksTypes();
    return response.data;
};

export const useTrucksTypesQuery = (options: UseTrucksTypesQueryOptions) => {
    return useQuery(['trucksTypes', options], getTrucksTypes);
};
