import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import {
    UpdateQuarryBody,
    QuarryResponse,
    updateQuarry,
    CreateQuarryBody,
    createQuarry,
} from '../../services/apiSLTP/sites/quarries';

type UseUpdateQuarryMutationOptions = MutationOptions<QuarryResponse, Error, { quarryId: string; data: UpdateQuarryBody }>;
type UseCreateQuarryMutationOptions = MutationOptions<QuarryResponse, Error, CreateQuarryBody>;

export const useUpdateQuarryMutation = (options?: UseUpdateQuarryMutationOptions) => {
    const quarryMutation: MutationFunction<QuarryResponse, { quarryId: string; data: UpdateQuarryBody }> = async ({
        quarryId,
        data,
    }) => {
        try {
            const response: QuarryResponse = await updateQuarry(quarryId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during quarry');
        }
    };

    return useMutation(quarryMutation, options);
};

export const useCreateQuarryMutation = (options?: UseCreateQuarryMutationOptions) => {
    const quarryMutation: MutationFunction<QuarryResponse, CreateQuarryBody> = async (data) => {
        try {
            const response: QuarryResponse = await createQuarry(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during quarry');
        }
    };

    return useMutation(quarryMutation, options);
};
