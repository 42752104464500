import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrucksQuery } from '../../hooks/queries/useTrucksQuery';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateTruckMutation } from '../../hooks/mutations/useTruckMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateTruckModal from '../modals/UpdateTruckModal';
import { getStatusColor } from '../../utils/colors';
import { User } from '../../services/apiSLTP/users';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import StateCell from '../cells/StateCell';

interface TrucksSettingsRowProps {
    users: User[];
    usersIsSuccess?: boolean;
    trucksTypes: TruckType[];
    trucksTypesIsSuccess: boolean;
    trucksCompanies: TruckCompany[];
    trucksCompaniesIsSuccess: boolean;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const TrucksSettingsRow: React.FC<TrucksSettingsRowProps> = ({
    users,
    usersIsSuccess,
    trucksCompanies,
    trucksCompaniesIsSuccess,
    trucksTypes,
    trucksTypesIsSuccess,
    universalFilter,
    setAddFtn,
}) => {
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState<Truck | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useTrucksQuery({});
    const {
        mutate: mutateTruck,
        isSuccess: isSuccessTruck,
        isError: isErrorTruck,
        isLoading: isLoadingTruck,
        error: errorTruck,
        data: dataTruck,
    } = useUpdateTruckMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck:', error.message);
        },
    });

    const sortedTrucks = () => {
        return trucks.sort((a, b) => {
            const companyA = a?.truckCompanyId.designation;
            const companyB = b?.truckCompanyId.designation;
            if (companyA === 'SLTP' && companyB !== 'SLTP') return -1;
            if (companyB === 'SLTP' && companyA !== 'SLTP') return 1;
            const typeA = a?.designation;
            const typeB = b?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setTrucks(data);
        }
    }, [data]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedTruck(null);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-400px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-150px">
                                <div className="fx-r fx-justify-c">{capitalizeFirstLetter(t('status'))}</div>
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('plateNumber'))}
                            </th>
                            <th scope="col" className="w-200px">
                                {capitalizeFirstLetter(t('driver'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('company'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('type'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedTrucks().map((truck, index, array) => (
                            <tr key={truck._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {truck.designation}
                                </td>
                                <td>{truck.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedTruck(truck);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={truck.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedTruck(truck);
                                                setIsEditMode(false);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={truck.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <StateCell
                                        stateColor={getStatusColor(truck?.crudStatus)}
                                        text={capitalizeFirstLetter(t(getCrudStatusInfos(truck?.crudStatus)))}
                                    />
                                </td>
                                <td>{truck.plateNumber}</td>
                                <td>
                                    {truck.userId?.firstName} {truck.userId?.lastName}
                                </td>
                                <td>{trucksCompanies.find((value) => value?._id === truck.truckCompanyId?._id)?.designation}</td>
                                <td>{trucksTypes.find((value) => value?._id === truck.truckTypeId?._id)?.designation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateTruckModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    truck={selectedTruck}
                    isEditMode={isEditMode}
                    trucks={trucks}
                    setTrucks={setTrucks}
                    trucksTypes={trucksTypes}
                    trucksCompanies={trucksCompanies}
                    refetch={() => refetch()}
                    users={users}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        if (selectedTruck) {
                            mutateTruck({ truckId: selectedTruck._id, data: { crudStatus: crudStatus.DELETED } });
                        }
                    }}
                    verificationWord={selectedTruck?.designation ?? ''}
                    title={t('truck')}
                />
            )}
        </div>
    );
};

export default TrucksSettingsRow;
