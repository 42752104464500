import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getMachineTypes, getMachinesByMachineTypeId, createDailyMachine, DailyMachine } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyMachine } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { useActiveMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import RangeInput from '../RangeInput';
import { Machine } from '../../services/apiSLTP/materials/machines';
import { removeDuplicates } from '../../utils/array';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activityId: string;
    canChooseActivity?: boolean;
    refetch: () => void;
    dailyMachine: DailyMachine | null;
    machine: Machine | null;
    activities: DataItem[];
    site?: { name: string; id: string };
}

const CreateDailyMachineModal: React.FC<ModalProps> = ({
    open,
    handleClose,
    refetch,
    activityId,
    dailyMachine,
    canChooseActivity = false,
    activities,
    machine,
    site,
}) => {
    const { t } = useTranslation();
    const [currentDailyMachineId, setCurrentDailyMachineId] = useState(dailyMachine?._id || '');
    const [selectedMachine, setSelectedMachine] = useState({ label: '', value: '' });
    const [selectedSite, setSelectedSite] = useState({ label: '', value: '' });
    const [selectedActivity, setSelectedActivity] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.PRESENT))), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const { data: dataMachines } = useActiveMachinesQuery({});

    const postDailyMachine = async () => {
        const response = await createDailyMachine(activityId || selectedActivity.value, selectedMachine.value, parseInt(selectedState.value), true);
        setCurrentDailyMachineId(response.data._id);
        refetch();
    };

    const patchDailyMachine = async () => {
        const response = await updateDailyMachine(currentDailyMachineId, {
            machineId: selectedMachine.value,
            state: parseInt(selectedState.value),
            createTransfer: true,
        });
        refetch();
    };

    useEffect(() => {
        if (dailyMachine) {
            setSelectedMachine({ label: dailyMachine.machineId?.designation || '', value: dailyMachine.machineId?._id || '' });
            setSelectedState({
                label: capitalizeFirstLetter(t(getMaterialStateInfos(dailyMachine.state))) || '',
                value: dailyMachine.state.toString() || '',
            });
        } else if (machine) {
            setSelectedMachine({ label: machine?.designation || '', value: machine?._id || '' });
        }
        if (canChooseActivity && site) {
            console.log(site);
            setSelectedSite({ label: site?.name, value: site?.id });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={t('machine')}
            subTitle={!!currentDailyMachineId.length ? t('update') : t('add')}
            okText={t('validate')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentDailyMachineId.length ? postDailyMachine() : patchDailyMachine();
            }}
            disabledOk={!selectedMachine.value.length || (canChooseActivity && !selectedActivity.value.length)}
        >
            <div className="fx-c">
                {canChooseActivity && (
                    <>
                        <DropDown
                            selectedItem={selectedSite}
                            items={
                                removeDuplicates(
                                    activities.map((activity) => activity.siteId),
                                    ['_id']
                                ).map((site) => ({
                                    label: site.name,
                                    value: site._id,
                                })) || []
                            }
                            handleChange={(event, value) => {
                                setSelectedActivity({ label: '', value: '' });
                                setSelectedSite({ value: value?.value || '', label: value?.label || '' });
                            }}
                            inputLabel={t('site')}
                        />
                        {!!selectedSite.value.length && (
                            <DropDown
                                selectedItem={selectedActivity}
                                items={
                                    activities
                                        .filter((activity) => activity.siteId._id === selectedSite.value)
                                        .map((activity) => ({
                                            label: activity.reportId.userId.firstName + ' ' + activity.reportId.userId.lastName,
                                            value: activity._id,
                                        })) || []
                                }
                                handleChange={(event, value) => setSelectedActivity({ value: value?.value || '', label: value?.label || '' })}
                                inputLabel={t('person')}
                            />
                        )}
                    </>
                )}
                <DropDown
                    selectedItem={selectedMachine}
                    items={
                        dataMachines?.map((machine) => ({
                            label: machine.designation,
                            value: machine._id,
                            group: machine.machineTypeId.designation,
                        })) || []
                    }
                    handleChange={(event, value) => setSelectedMachine({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('machine')}
                    grouped
                    selectClassName="h-25px"
                />
                {!!selectedMachine.value.length && (
                    <DropDown
                        selectedItem={selectedState}
                        items={states}
                        handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('state')}
                    />
                )}
            </div>
        </ModalBody>
    );
};

export default CreateDailyMachineModal;
