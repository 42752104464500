export const capitalizeFirstLetter = (string: string | undefined): string => {
    if (string) return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    return '';
};

export const capitalizeEveryFirstWordLetter = (string: string | undefined): string => {
    if (string) {
        return string.toLowerCase().replace(/(\p{L})(\p{L}*)/gu, (match, firstLetter, rest) => firstLetter.toUpperCase() + rest);
    }
    return '';
};
