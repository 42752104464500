import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown';
import { createDailyTransfer, DailyTransfer, updateDailyTransfer } from '../../services/apiSLTP/dailyReports/dailyTransfers';
import { DataItem } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, getStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import RangeInput from '../RangeInput';
import { useTrucksCompaniesQuery } from '../../hooks/queries/useTrucksCompaniesQuery';
import { useTrucksTypesQuery } from '../../hooks/queries/useTrucksTypesQuery';
import { useLoadNaturesQuery } from '../../hooks/queries/useLoadNaturesQuery';
import { useMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import { useActiveQuarriesQuery, useQuarriesQuery } from '../../hooks/queries/useQuarriesQuery';
import { useToolsQuery } from '../../hooks/queries/useToolsQuery';
import { useActiveSitesQuery } from '../../hooks/queries/useSitesQuery';
import { useTrucksQuery } from '../../hooks/queries/useTrucksQuery';
import { useCreateDailyTransferMutation, useUpdateDailyTransferMutation } from '../../hooks/mutations/useDailyTransfersMutation';
import Icon from '../Icon';
import { useRentalsQuery } from '../../hooks/queries/useRentalsQuery';
import CustomTextInput from '../CustomTextInput';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    refetch: () => void;
    transfer: DailyTransfer | null;
    truckPlannedId: string;
}

const ManageDailyTransferModal: React.FC<ModalProps> = ({ open, handleClose, activity, refetch, transfer, truckPlannedId }) => {
    const { t } = useTranslation();
    const [currentTransferId, setCurrentTransferId] = useState(transfer?._id || '');

    const [selectedDestination, setSelectedDestination] = useState({ label: '', value: '', group: '' });
    const [selectedMachine, setSelectedMachine] = useState({ label: '', value: '' });
    const [selectedTool, setSelectedTool] = useState({ label: '', value: '' });
    const [selectedRental, setSelectedRental] = useState({ label: '', value: '' });
    const [rentalName, setRentalName] = useState('');

    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: '', value: '' });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const {
        data: dataSites,
        isLoading: isLoadingSites,
        isError: isErrorSites,
        isSuccess: isSuccessSites,
        refetch: refetchSites,
    } = useActiveSitesQuery({});

    const {
        data: dataMachines,
        isLoading: isLoadingMachines,
        isError: isErrorMachines,
        isSuccess: isSuccessMachines,
        refetch: refetchMachines,
    } = useMachinesQuery({});

    const {
        data: dataQuarries,
        isLoading: isLoadinQuarries,
        isError: isErrorQuarries,
        isSuccess: isSuccessQuarries,
        refetch: refetchQuarries,
    } = useActiveQuarriesQuery({});

    const { data: dataTools, isLoading: isLoadingTools, isError: isErrorTools, isSuccess: isSuccessTools, refetch: refetchTools } = useToolsQuery({});

    const {
        data: dataRentals,
        isLoading: isLoadingRentals,
        isError: isErrorRentals,
        isSuccess: isSuccessRentals,
        refetch: refetchRentals,
    } = useRentalsQuery({});

    const {
        mutate: createTransfer,
        isSuccess: isCreateTransferSuccess,
        isError: isCreateTransferError,
        isLoading: isCreateTransferLoading,
        error: createTransferError,
    } = useCreateDailyTransferMutation({
        onSuccess: (data, variables) => {
            setCurrentTransferId(data.data._id);
            refetch();
        },
        onError: (error) => {
            console.error('Error creating truck planned:', error.message);
        },
    });

    const {
        mutate: updateTransfer,
        isSuccess: isUpdateTransferSuccess,
        isError: isUpdateTransferError,
        isLoading: isUpdateTransferLoading,
        error: updateTransferError,
    } = useUpdateDailyTransferMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck planned:', error.message);
        },
    });

    const postDailyTransfer = async () => {
        createTransfer({
            dailyTrucksPlannedId: truckPlannedId,
            state: parseInt(selectedState.value),
            machineId: selectedMachine.value,
            toolId: selectedTool.value,
            rentalCompanyId: selectedRental.value,
            rentalName: rentalName,
            quarryId: selectedDestination.group === capitalizeFirstLetter(t('quarries')) ? selectedDestination.value : '',
            siteId: selectedDestination.group === capitalizeFirstLetter(t('sites')) ? selectedDestination.value : '',
        });
        refetch();
    };

    const patchDailyTransfer = async () => {
        updateTransfer({
            DailyTransferId: currentTransferId,
            data: {
                state: parseInt(selectedState.value),
                machineId: selectedMachine.value,
                toolId: selectedTool.value,
                rentalCompanyId: selectedRental.value,
                rentalName: rentalName,
                quarryId: selectedDestination.group === capitalizeFirstLetter(t('quarries')) ? selectedDestination.value : '',
                siteId: selectedDestination.group === capitalizeFirstLetter(t('sites')) ? selectedDestination.value : '',
            },
        });
        refetch();
    };

    useEffect(() => {
        if (transfer?.siteId?._id)
            setSelectedDestination({ label: transfer?.siteId?.name, value: transfer?.siteId?._id, group: capitalizeFirstLetter(t('sites')) });
        else if (transfer?.quarryId?._id)
            setSelectedDestination({
                label: transfer?.quarryId?.designation,
                value: transfer?.quarryId?._id,
                group: capitalizeFirstLetter(t('quarries')),
            });
        if (transfer?.machineId?._id) setSelectedMachine({ label: transfer?.machineId?.designation, value: transfer?.machineId?._id });
        else if (transfer?.toolId?._id) setSelectedTool({ label: transfer?.toolId?.designation, value: transfer?.toolId?._id });
        else if (transfer?.rentalCompanyId?._id || transfer?.rentalName) {
            setSelectedRental({ label: transfer?.rentalCompanyId?.designation || '', value: transfer?.rentalCompanyId?._id || '' });
            setRentalName(transfer?.rentalName || '');
        }
        if (transfer)
            setSelectedState({ label: capitalizeFirstLetter(t(getMaterialStateInfos(transfer?.state))), value: transfer?.state.toString() });
    }, []);

    return (
        <ModalBody
            title={t('transfer')}
            subTitle={!!currentTransferId.length ? t('update') : t('add')}
            okText={t('valider')}
            disabledOk={!selectedState.value.length}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !!currentTransferId.length ? patchDailyTransfer() : postDailyTransfer();
            }}
        >
            <div className="flex-container">
                <DropDown
                    selectedItem={selectedState}
                    items={states}
                    handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('state')}
                />
                <DropDown
                    selectedItem={selectedDestination}
                    items={
                        dataSites
                            ?.map((site) => ({ label: site.name, value: site._id, group: capitalizeFirstLetter(t('sites')) }))
                            .concat(
                                dataQuarries?.map((quarry) => ({
                                    label: quarry.designation,
                                    value: quarry._id,
                                    group: capitalizeFirstLetter(t('quarries')),
                                })) || []
                            ) || []
                    }
                    handleChange={(event, value) =>
                        setSelectedDestination({ value: value?.value || '', label: value?.label || '', group: value?.group || '' })
                    }
                    inputLabel={parseInt(selectedState.value) === materialState.NEW ? t('from') : t('to')}
                    grouped
                />

                <div className="w-100 pad-15px">
                    <div className="h-1px bg-lightgray w-100" />
                </div>
                <DropDown
                    selectedItem={selectedMachine}
                    items={dataMachines?.map((machine) => ({ label: machine.designation, value: machine._id })) || []}
                    handleChange={(event, value) => {
                        setSelectedMachine({ value: value?.value || '', label: value?.label || '' });
                        setSelectedTool({ value: '', label: '' });
                        setSelectedRental({ value: '', label: '' });
                        setRentalName('');
                    }}
                    inputLabel={t('machine')}
                    disableClearable={false}
                />
                <DropDown
                    selectedItem={selectedTool}
                    items={dataTools?.map((tool) => ({ label: tool.designation, value: tool._id })) || []}
                    handleChange={(event, value) => {
                        setSelectedTool({ value: value?.value || '', label: value?.label || '' });
                        setSelectedMachine({ value: '', label: value?.label || '' });
                        setSelectedRental({ value: '', label: '' });
                        setRentalName('');
                    }}
                    inputLabel={t('tool')}
                    disableClearable={false}
                />
                <DropDown
                    selectedItem={selectedRental}
                    items={dataRentals?.map((rental) => ({ label: rental.designation, value: rental._id })) || []}
                    handleChange={(event, value) => {
                        setSelectedRental({ value: value?.value || '', label: value?.label || '' });
                        setSelectedMachine({ value: '', label: '' });
                        setSelectedTool({ value: '', label: '' });
                    }}
                    inputLabel={t('rental')}
                    disableClearable={false}
                />
                <CustomTextInput value={rentalName} onChange={(text) => setRentalName(text)} label={t('name')} />
            </div>
        </ModalBody>
    );
};

export default ManageDailyTransferModal;
