import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';


export interface ConducsResponse {
    message: string;
    data: {_id: string; firstName: string; lastName: string}[];
}

export const getCurrentConducs = async (): Promise<ConducsResponse> => {
    try {
        const response: AxiosResponse<ConducsResponse> = await apiClient.get('/sites/conducs');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
