import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    deleteAction: () => void;
    verificationWord: string;
    showDeleteModal: boolean;
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
}

const DeleteModal: React.FC<ModalProps> = ({ deleteAction, verificationWord, showDeleteModal, setShowDeleteModal, title }) => {
    const { t } = useTranslation();
    const [verificationText, setVerificationText] = useState('');

    return (
        <ModalBody
            closeText={t('cancel')}
            title={title}
            subTitle={t('delete')}
            okText={t('valider')}
            open={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            handleOk={deleteAction}
            disabledOk={verificationText !== verificationWord}
        >
            <div>{t('verificationDeleteText', { word: verificationWord })}</div>
            <CustomTextInput value={verificationText} onChange={(text) => setVerificationText(text)} />
        </ModalBody>
    );
};

export default DeleteModal;
