import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import { crudStatus } from '../../utils/enums';
import { useCreateTruckMutation, useUpdateTruckMutation } from '../../hooks/mutations/useTruckMutation';
import { useAuth } from '../../hooks/useAuth';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { User } from '../../services/apiSLTP/users';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    truck: Truck | null;
    trucks: Truck[];
    setTrucks: React.Dispatch<React.SetStateAction<Truck[]>>;
    trucksTypes: TruckType[];
    users: User[];
    trucksCompanies: TruckCompany[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTruckModal: React.FC<ModalProps> = ({
    truck,
    setTrucks,
    showEditModal,
    setShowEditModal,
    trucksTypes,
    users,
    trucks,
    isEditMode = true,
    trucksCompanies,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTruck, setTmpTruck] = useState(truck ?? ({} as Truck));
    const [selectedTruckType, setSelectedTruckType] = useState({
        label: '',
        value: '',
    });
    const [selectedTruckCompany, setSelectedTruckCompany] = useState({
        label: '',
        value: '',
    });
    const [selectedUser, setSelectedUser] = useState({
        label: '',
        value: '',
    });
    const [selectedDesignation, setSelectedDesignation] = useState(truck?.designation || '');
    const [selectedCodeProC, setSelectedCodeProC] = useState(truck?.codeProC || '');
    const [selectedPlateNumber, setSelectedPlateNumber] = useState(truck?.plateNumber || '');
    const [selectedCrudStatus, setSelectedCrudStatus] = useState(truck?.crudStatus || crudStatus.OK);

    const { user } = useAuth();

    const {
        mutate: mutateTruck,
        isSuccess: isSuccessTruck,
        isError: isErrorTruck,
        isLoading: isLoadingTruck,
        error: errorTruck,
        data: dataTruck,
    } = useUpdateTruckMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck:', error.message);
        },
    });

    const {
        mutate: addTruck,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTruckMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setSelectedDesignation(text);
    };

    const handleCodeProCChange = (text: string) => {
        setSelectedCodeProC(text);
    };

    const handlePlateNumberChange = (text: string) => {
        setSelectedPlateNumber(text);
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            trucks
                .filter((value) => value._id !== tmpTruck._id)
                .map((value) => value.codeProC)
                .includes(tmpTruck.codeProC)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setSelectedCrudStatus(crudStatus.OK);
        } else {
            setSelectedCrudStatus(crudStatus.INACTIVE);
        }
    };

    useEffect(() => {
        const truckType = trucksTypes.find((value) => value?._id === tmpTruck.truckTypeId?._id);
        if (truckType) setSelectedTruckType({ label: `${truckType?.designation}`, value: `${truckType?._id}` });
        const truckCompany = trucksCompanies.find((value) => value?._id === tmpTruck.truckCompanyId?._id);
        if (truckCompany) setSelectedTruckCompany({ label: `${truckCompany?.designation}`, value: `${truckCompany?._id}` });
        const user = users.find((value) => value?._id === tmpTruck.userId?._id);
        if (user) setSelectedUser({ label: user.firstName + ' ' + user.lastName, value: user._id });
    }, [tmpTruck.truckTypeId, trucksTypes, trucksCompanies]);

    return (
        <ModalBody
            title={t('truck')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={
                !selectedDesignation.length ||
                !selectedCodeProC.length ||
                !selectedPlateNumber.length ||
                !selectedTruckCompany.value.length ||
                !selectedTruckType.value.length
            }
            handleOk={() => {
                isEditMode
                    ? mutateTruck({
                          truckId: truck?._id || '',
                          data: {
                              designation: selectedDesignation,
                              codeProC: selectedCodeProC,
                              crudStatus: selectedCrudStatus,
                              truckTypeId: selectedTruckType.value,
                              plateNumber: selectedPlateNumber,
                              truckCompanyId: selectedTruckCompany.value,
                              userId: selectedUser.value || undefined,
                          },
                      })
                    : addTruck({
                          codeProC: selectedCodeProC,
                          designation: selectedDesignation,
                          truckTypeId: selectedTruckType.value,
                          plateNumber: selectedPlateNumber,
                          truckCompanyId: selectedTruckCompany.value,
                          userId: selectedUser.value || undefined,
                          crudStatus: selectedCrudStatus,
                      });
            }}
        >
            <CustomTextInput value={selectedDesignation} onChange={handleDesignationChange} label={t('name')} errorText={t('error')} required />
            <CustomTextInput value={selectedCodeProC} onChange={handleCodeProCChange} label={t('codeProC')} errorText={t('error')} required />
            <ToggleLine checked={selectedCrudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
            <CustomTextInput
                value={selectedPlateNumber}
                onChange={handlePlateNumberChange}
                label={t('plateNumber')}
                errorText={t('error')}
                required
            />
            <DropDown
                items={users.map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user._id }))}
                selectedItem={selectedUser}
                handleChange={(event, value) => setSelectedUser({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('driver')}
                disableClearable={false}
            />
            <DropDown
                items={trucksCompanies.map((truckCompany) => ({ label: truckCompany.designation, value: truckCompany._id }))}
                selectedItem={selectedTruckCompany}
                handleChange={(event, value) => setSelectedTruckCompany({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('company')}
                required
            />
            <DropDown
                items={trucksTypes.map((truckType) => ({ label: truckType.designation, value: truckType._id }))}
                selectedItem={selectedTruckType}
                handleChange={(event, value) => setSelectedTruckType({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('type')}
                required
            />
        </ModalBody>
    );
};

export default UpdateTruckModal;
