import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DropDown from '../DropDown';
import dayjs from 'dayjs';
import { DataItem } from '../../services/apiSLTP/activities';
import { UserItem } from '../../services/apiSLTP/planning';
import { permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { SelectChangeEvent } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useCreateActivityMutation } from '../../hooks/mutations/useActivityMutation';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    sites: { label: string; value: string }[];
    users: UserItem[];
    activitiesTypes: { label: string; value: string }[];
    initDate: string;
    refetch?: () => void;
    initUserId?: string;
    initSiteId?: string;
    initActivityTypeId?: string;
}

const CreateActivityModal: React.FC<ModalProps> = ({
    open,
    handleClose,
    users,
    activitiesTypes,
    sites,
    initDate,
    refetch = () => {},
    initUserId = '',
    initSiteId = '',
    initActivityTypeId = '',
}) => {
    const [selectedUser, setSelectedUser] = useState({ label: '', value: '' });
    const [selectedActivityType, setSelectedActivityType] = useState({ label: '', value: '' });
    const [selectedSite, setSelectedSite] = useState({ label: '', value: '' });
    const [selectedDate, setSelectedDate] = useState(initDate);
    const { t } = useTranslation();
    const { mutate: createActivity } = useCreateActivityMutation({
        onSuccess: (data) => {
            refetch();
            setSelectedUser({ label: '', value: '' });
            setSelectedActivityType({ label: '', value: '' });
            handleClose();
        },
        onSettled: () => {
            console.log('settled');
        },
    });

    const handleCreateActivity = () => {
        createActivity({
            activityTypeId: selectedActivityType.value,
            siteId: selectedSite.value,
            startTime: selectedDate,
            userId: selectedUser.value,
            state: reportState.PLANNING,
            planningValidationTime: new Date().toISOString(),
            stepMode: stepModes.PLANNING,
        });
    };

    const handleChangePerson = (label: string, value: string) => {
        setSelectedUser({ value: value, label: label });
        const tmpUser = users.find((user) => user._id === value);
        if (tmpUser) {
            if (tmpUser.profileId.permsLevel === permissionLevels.CHAUFFEUR)
                setSelectedActivityType({ label: 'Tours de camion', value: truckRideActivityTypeId });
            else setSelectedActivityType({ label: '', value: '' });
        }
    };

    useEffect(() => {
        if (initUserId.length) {
            const tmpUser = users.find((user) => user._id === initUserId);
            setSelectedUser({
                label: tmpUser ? capitalizeFirstLetter(tmpUser.firstName) + ' ' + tmpUser?.lastName.toUpperCase() : '',
                value: initUserId,
            });
        }
        if (initSiteId.length) {
            const tmpSite = sites.find((site) => site.value === initSiteId);
            setSelectedSite({
                label: tmpSite ? tmpSite.label : '',
                value: initSiteId,
            });
        }
        if (initActivityTypeId.length) {
            const tmpActivityType = activitiesTypes.find((activityType) => activityType.value === initActivityTypeId);
            setSelectedActivityType({
                label: tmpActivityType ? tmpActivityType.label : '',
                value: initActivityTypeId,
            });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            okText={t('validate')}
            title={t('activity')}
            subTitle={t('add')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleCreateActivity();
            }}
            disabledOk={!selectedUser.value.length || !selectedSite.value.length || !selectedActivityType.value.length}
        >
            <div className="fx-c">
                <DropDown
                    selectedItem={selectedSite}
                    items={sites}
                    handleChange={(event, value) => setSelectedSite({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('site')}
                />
                <DropDown
                    selectedItem={selectedUser}
                    items={users.map((user) => ({
                        label: capitalizeFirstLetter(user.firstName) + ' ' + user?.lastName.toUpperCase(),
                        value: user?._id,
                    }))}
                    handleChange={(event, value) => handleChangePerson(value?.label || '', value?.value || '')}
                    inputLabel={t('person')}
                />
                <DropDown
                    selectedItem={selectedActivityType}
                    items={activitiesTypes}
                    handleChange={(event, value) => setSelectedActivityType({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('activity')}
                />
                <div style={{ width: 300, padding: 5 }}>
                    <TimePicker
                        ampm={false}
                        value={dayjs(selectedDate)}
                        label={capitalizeFirstLetter(t('startTime'))}
                        onChange={(value) => {
                            if (value) setSelectedDate(value.toISOString());
                        }}
                        className="w-100"
                        slotProps={{
                            field: {
                                readOnly: true,
                            },
                            textField: {
                                variant: 'outlined',
                                InputProps: {
                                    className: 'h-25px',
                                },
                                InputLabelProps: {
                                    sx: {
                                        color: '#000',
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </ModalBody>
    );
};

export default CreateActivityModal;
