import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import { ChromePicker, ColorResult } from 'react-color';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    initialColor?: string;
    handleColorChange: (color: string) => void;
    open: boolean;
    handleClose: () => void;
}

const ColorModal: React.FC<ModalProps> = ({ initialColor = '#ffffff', handleColorChange = () => {}, open = false, handleClose = () => {} }) => {
    const [color, setColor] = useState(initialColor);
    const { t } = useTranslation();

    return (
        <ModalBody
        title={t('color')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleColorChange(color);
            }}
        >
            <ChromePicker color={color} onChange={(color) => setColor(color.hex)} />
        </ModalBody>
    );
};

export default ColorModal;
