import { useQuery, QueryFunction } from 'react-query';
import { getAllRentals, Rental } from '../../services/apiSLTP/materials/rentals';

type UseRentalsQueryOptions = {};

const getRentals: QueryFunction<Rental[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseRentalsQueryOptions];
    const {} = options;

    const response = await getAllRentals();
    return response.data;
};

export const useRentalsQuery = (options: UseRentalsQueryOptions) => {
    return useQuery(['rentals', options], getRentals);
};
