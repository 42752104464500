import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';
import { LoadNature } from '../materials/loadNatures';
import { TruckType } from '../materials/trucksTypes';
import { TruckCompany } from '../materials/trucksCompanies';
import { Quarry } from '../sites/quarries';
import { DailyTransfer } from './dailyTransfers';
import { Site } from '../planning';
import { Truck } from '../materials/trucks';
import { Dump } from '../materials/dumps';

export interface DailyTruckPlanned {
    _id: string;
    activityId: string;
    truckTypeId: TruckType;
    trucksAmount: number;
    ridesAmount?: number;
    truckCompanyId?: TruckCompany;
    areTrucksRented?: boolean;
    mode?: number;
    transfers: DailyTransfer[];
    departureLoadNatureId?: LoadNature;
    arrivalLoadNatureId?: LoadNature;
    departureSiteId?: Site;
    arrivalSiteId?: Site;
    departureQuarryId?: Quarry;
    arrivalQuarryId?: Quarry;
    departureDumpId?: Dump;
    arrivalDumpId?: Dump;
    completedAmount: number;
    truckId?: Truck;
    observations?: string;
}

export interface DailyTrucksPlannedResponse {
    message: string;
    data: DailyTruckPlanned[];
}

// GET Daily Trucks Planned by Activity ID
export const getDailyTrucksPlannedByActivityId = async (activityId: string): Promise<DailyTrucksPlannedResponse> => {
    try {
        const response: AxiosResponse<DailyTrucksPlannedResponse> = await apiClient.get('/daily-trucksplanned/activity/' + activityId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// CREATE Daily Truck Planned
export interface CreateDailyTruckPlannedBody {
    activityId: string;
    truckTypeId: string;
    trucksAmount: number;
    ridesAmount?: number;
    truckCompanyId?: string;
    areTrucksRented?: boolean;
    mode?: number;
    departureLoadNatureId?: string;
    arrivalLoadNatureId?: string;
    departureSiteId?: string;
    arrivalSiteId?: string;
    departureQuarryId?: string;
    arrivalQuarryId?: string;
    departureDumpId?: string;
    arrivalDumpId?: string;
    truckId?: string;
    completedAmount?: number;
    observations?: string;
}

export interface DailyTruckPlannedResponse {
    message: string;
    data: DailyTruckPlanned;
}

export const createDailyTruckPlanned = async (body: CreateDailyTruckPlannedBody): Promise<DailyTruckPlannedResponse> => {
    try {
        const response: AxiosResponse<DailyTruckPlannedResponse> = await apiClient.post('/daily-trucksplanned', body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// UPDATE Daily Truck Planned
export interface UpdateDailyTruckPlannedBody {
    activityId?: string;
    truckTypeId?: string;
    trucksAmount?: number;
    ridesAmount?: number;
    truckCompanyId?: string;
    areTrucksRented?: boolean;
    mode?: number;
    departureLoadNatureId?: string;
    arrivalLoadNatureId?: string;
    departureSiteId?: string;
    arrivalSiteId?: string;
    departureQuarryId?: string;
    arrivalQuarryId?: string;
    departureDumpId?: string;
    arrivalDumpId?: string;
    truckId?: string;
    completedAmount?: number;
    observations?: string;
}

export const updateDailyTruckPlanned = async (id: string, body: UpdateDailyTruckPlannedBody): Promise<DailyTruckPlannedResponse> => {
    try {
        const response: AxiosResponse<DailyTruckPlannedResponse> = await apiClient.patch('/daily-trucksplanned/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// DELETE Daily Truck Planned
export interface DeleteDailyTruckPlannedResponse {
    message: string;
    data: DailyTruckPlanned;
}

export const deleteDailyTruckPlanned = async (id: string): Promise<DeleteDailyTruckPlannedResponse> => {
    try {
        const response: AxiosResponse<DeleteDailyTruckPlannedResponse> = await apiClient.delete(`/daily-trucksplanned/${id}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
