import { useQuery, QueryFunction } from 'react-query';
import { getAllEntities, Entity } from '../../services/apiSLTP/sites/entities';

type UseEntitiesQueryOptions = {};

const getEntities: QueryFunction<Entity[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseEntitiesQueryOptions];
    const {} = options;

    const response = await getAllEntities();
    return response.data;
};

export const useEntitiesQuery = (options: UseEntitiesQueryOptions) => {
    return useQuery(['entities', options], getEntities);
};
