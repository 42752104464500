import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getToolTypes, getToolsByToolTypeId, createDailyTool, DailyTool } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyTool } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { useActiveToolsQuery } from '../../hooks/queries/useToolsQuery';
import RangeInput from '../RangeInput';
import { Tool } from '../../services/apiSLTP/materials/tools';
import { removeDuplicates } from '../../utils/array';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activityId: string;
    canChooseActivity?: boolean;
    refetch: () => void;
    dailyTool: DailyTool | null;
    tool: Tool | null;
    activities: DataItem[];
    site?: { name: string; id: string };
}

const CreateDailyToolModal: React.FC<ModalProps> = ({
    open,
    handleClose,
    refetch,
    activityId,
    dailyTool,
    canChooseActivity = false,
    activities,
    tool,
    site,
}) => {
    const { t } = useTranslation();
    const [currentDailyToolId, setCurrentDailyToolId] = useState(dailyTool?._id || '');
    const [selectedTool, setSelectedTool] = useState({ label: '', value: '' });
    const [selectedSite, setSelectedSite] = useState({ label: '', value: '' });
    const [selectedActivity, setSelectedActivity] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.PRESENT))), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const { data: dataTools } = useActiveToolsQuery({});

    const postDailyTool = async () => {
        const response = await createDailyTool(activityId || selectedActivity.value, selectedTool.value, parseInt(selectedState.value), true);
        setCurrentDailyToolId(response.data._id);
        refetch();
    };

    const patchDailyTool = async () => {
        const response = await updateDailyTool(currentDailyToolId, {
            toolId: selectedTool.value,
            state: parseInt(selectedState.value),
            createTransfer: true,
        });
        refetch();
    };

    useEffect(() => {
        if (dailyTool) {
            setSelectedTool({ label: dailyTool.toolId?.designation || '', value: dailyTool.toolId?._id || '' });
            setSelectedState({
                label: capitalizeFirstLetter(t(getMaterialStateInfos(dailyTool.state))) || '',
                value: dailyTool.state.toString() || '',
            });
        } else if (tool) {
            setSelectedTool({ label: tool?.designation || '', value: tool?._id || '' });
        }
        if (canChooseActivity && site) {
            console.log(site)
            setSelectedSite({ label: site?.name, value: site?.id });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={t('tool')}
            subTitle={!!currentDailyToolId.length ? t('update') : t('add')}
            okText={t('validate')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentDailyToolId.length ? postDailyTool() : patchDailyTool();
            }}
            disabledOk={!selectedTool.value.length || (canChooseActivity && !selectedActivity.value.length)}
        >
            <div className="fx-c">
                {canChooseActivity && (
                    <>
                        <DropDown
                            selectedItem={selectedSite}
                            items={
                                removeDuplicates(
                                    activities.map((activity) => activity.siteId),
                                    ['_id']
                                ).map((site) => ({
                                    label: site.name,
                                    value: site._id,
                                })) || []
                            }
                            handleChange={(event, value) => {
                                setSelectedActivity({ label: '', value: '' });
                                setSelectedSite({ value: value?.value || '', label: value?.label || '' });
                            }}
                            inputLabel={t('site')}
                        />
                        {!!selectedSite.value.length && (
                            <DropDown
                                selectedItem={selectedActivity}
                                items={
                                    activities
                                        .filter((activity) => activity.siteId._id === selectedSite.value)
                                        .map((activity) => ({
                                            label: activity.reportId.userId.firstName + ' ' + activity.reportId.userId.lastName,
                                            value: activity._id,
                                        })) || []
                                }
                                handleChange={(event, value) => setSelectedActivity({ value: value?.value || '', label: value?.label || '' })}
                                inputLabel={t('person')}
                            />
                        )}
                    </>
                )}
                <DropDown
                    selectedItem={selectedTool}
                    items={
                        dataTools?.map((tool) => ({
                            label: tool.designation,
                            value: tool._id,
                            group: tool.toolTypeId.designation,
                        })) || []
                    }
                    handleChange={(event, value) => setSelectedTool({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('tool')}
                    grouped
                    selectClassName="h-25px"
                />
                {!!selectedTool.value.length && (
                    <DropDown
                        selectedItem={selectedState}
                        items={states}
                        handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('state')}
                    />
                )}
            </div>
        </ModalBody>
    );
};

export default CreateDailyToolModal;
