import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Quarry } from '../../services/apiSLTP/sites/quarries';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateQuarryMutation, useUpdateQuarryMutation } from '../../hooks/mutations/useQuarryMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    quarry: Quarry;
    isEditMode?: boolean;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateQuarryModal: React.FC<ModalProps> = ({ quarry, isEditMode = true, showEditModal, setShowEditModal, refetch }) => {
    const { t } = useTranslation();
    const [tmpQuarry, setTmpQuarry] = useState(quarry);
    const [selectedAddress, setSelectedAddress] = useState(tmpQuarry.address || '');

    const { user } = useAuth();

    const {
        mutate: mutateQuarry,
        isSuccess: isSuccessQuarry,
        isError: isErrorQuarry,
        isLoading: isLoadingQuarry,
        error: errorQuarry,
        data: dataQuarry,
    } = useUpdateQuarryMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating quarry:', error.message);
        },
    });

    const {
        mutate: addQuarry,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateQuarryMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating quarry:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpQuarry((prevQuarry) => {
            const newQuarry = { ...prevQuarry };
            newQuarry.designation = text;
            return newQuarry;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpQuarry((prevQuarry) => {
                const newQuarry = { ...prevQuarry };
                newQuarry.crudStatus = crudStatus.OK;
                return newQuarry;
            });
        } else {
            setTmpQuarry((prevQuarry) => {
                const newQuarry = { ...prevQuarry };
                newQuarry.crudStatus = crudStatus.INACTIVE;
                return newQuarry;
            });
        }
    };

    return (
        <ModalBody
            title={t('quarry')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpQuarry.designation.length}
            handleOk={() => {
                isEditMode
                    ? mutateQuarry({
                          quarryId: quarry._id,
                          data: {
                              designation: tmpQuarry.designation,
                              crudStatus: tmpQuarry.crudStatus,
                              address: selectedAddress,
                          },
                      })
                    : addQuarry({ designation: tmpQuarry.designation, address: selectedAddress, crudStatus: tmpQuarry.crudStatus });
            }}
        >
            <CustomTextInput value={tmpQuarry.designation} label={t('name')} onChange={handleDesignationChange} errorText={t('error')} required />
            <CustomTextInput value={selectedAddress} label={t('address')} onChange={(text) => setSelectedAddress(text)} errorText={t('error')} />
            <ToggleLine checked={tmpQuarry.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
        </ModalBody>
    );
};

export default UpdateQuarryModal;
