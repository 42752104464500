export const removeDuplicates = <T>(array: T[], keys: (keyof T)[]): T[] => {
    return array.filter((obj1, i, arr) => arr.findIndex((obj2) => keys.every((key) => obj2[key] === obj1[key])) === i);
};

interface CountResult {
    name: string;
    number: number;
}

export const countPropertyValues = <T>(objectsArray: T[], property: keyof T): CountResult[] => {
    const countMap: Record<string, number> = objectsArray.reduce(
        (accumulator, currentObject) => {
            const value = currentObject[property];
            if (value !== undefined) {
                accumulator[value as unknown as string] = (accumulator[value as unknown as string] ?? 0) + 1;
            }

            return accumulator;
        },
        {} as Record<string, number>
    );
    return Object.entries(countMap).map(([name, number]) => ({ name, number }));
};
