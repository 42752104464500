import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateJobBody, JobResponse, updateJob, CreateJobBody, createJob } from '../../services/apiSLTP/jobs';

type UseUpdateJobMutationOptions = MutationOptions<JobResponse, Error, { jobId: string; data: UpdateJobBody }>;

type UseCreateJobMutationOptions = MutationOptions<JobResponse, Error, CreateJobBody>;

export const useUpdateJobMutation = (options?: UseUpdateJobMutationOptions) => {
    const jobMutation: MutationFunction<JobResponse, { jobId: string; data: UpdateJobBody }> = async ({ jobId, data }) => {
        try {
            const response: JobResponse = await updateJob(jobId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during job');
        }
    };

    return useMutation(jobMutation, options);
};

export const useCreateJobMutation = (options?: UseCreateJobMutationOptions) => {
    const jobMutation: MutationFunction<JobResponse, CreateJobBody> = async (data) => {
        try {
            const response: JobResponse = await createJob(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during job');
        }
    };

    return useMutation(jobMutation, options);
};
